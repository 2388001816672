import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import SignUpJourneyCard from "../../Components/SignUpJourneyCard/SignUpJourneyCard";
import axios from "axios";
import "./Registration1.css";
import "../Login/Login.css";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../../BASE_URL";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import countries from '../../CountryStateCity.json';


const RegisterPersonalDetail = () => {

  const [selectedCountry, setSelectedCountry] = useState(localStorage.getItem("myCountry"));
  const [selectedState, setSelectedState] = useState(localStorage.getItem("myState"));
  const [selectedCity, setSelectedCity] = useState(localStorage.getItem("myCity"));

  const handleCountryChange = (e) => {
    setSelectedCountry(e.target.value);
    setSelectedState('');
    setSelectedCity('');
  };

  const handleStateChange = (e) => {
    setSelectedState(e.target.value);
    setSelectedCity('');
  };

  const handleCityChange = (e) => {
    setSelectedCity(e.target.value);
  };

  // Helper function to find a country by name
  const findCountry = (countryName) => countries.find((country) => country.name === countryName);

  // Helper function to find a state by name within a country
  const findState = (country, stateName) => country.states.find((state) => state.name === stateName);


  const Height = [
    "4’0”",
    "4’1”",
    "4’2”",
    "4’3”",
    "4’4”",
    "4’5”",
    "4’6”",
    "4’7”",
    "4’8”",
    "4’9”",
    "4’10”",
    "4’11”",
    "5’0”",
    "5’1”",
    "5’2”",
    "5’3”",
    "5’4”",
    "5’5”",
    "5’6”",
    "5’7”",
    "5’8”",
    "5’9”",
    "5’10”",
    "5’11”",
    "6’0”",
    "6’1”",
    "6’2”",
    "6’3”",
    "6’4”",
    "6’5”",
    "6’6”",
    "6’7”",
    "6’8”",
    "6’9”",
    "6’10”",
    "6’11”",
    "7’0”",
    "7’1”",
    "7’2”",
    "7’3”",
    "7’4”",
    "7’5”",
    "7’6”",
    "7’7”",
    "7’8”",
    "7’9”",
    "7’10”",
    "7’11”",
  ];

  let navigate = useNavigate();
  const [age, setAge] = useState(localStorage.getItem("myAge"));
  const [dob, setDOB] = useState("2000-10-12");

  const calculatedAge = () => {
    const dob = document.querySelector(".dob").value;
    setDOB(dob);
    const today = new Date();
    const birthDate = new Date(dob);
    let calculatedAge = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthDate.getDate())
    ) {
      calculatedAge--;
    }
    setAge(calculatedAge.toString()); 
  };


  const [genderValue, setGenderValue] = useState(localStorage.getItem("myGender"))
  const [heightValue, setHeightValue] = useState(localStorage.getItem("myHeight"))
  const [weightValue, setWeightValue] = useState(localStorage.getItem("myWeight"))
  const [communityByReligion, setCommunityByReligion] = useState(localStorage.getItem("myReligion"))
  const [communityValue, setCommunityValue] = useState(localStorage.getItem("myCommunity"))
  const [motherToung, setMotherToung] = useState(localStorage.getItem("myMotherToungue"))
  const [maritalValue, setMaritalValue] = useState(localStorage.getItem("myMarital"))
  const [foodValue, setFoodValue] = useState(localStorage.getItem("myFood"))
  const [smokeValue, setSmokeValue] = useState(localStorage.getItem("mySmoke"))
  const [drinkValue, setDrinkValue] = useState(localStorage.getItem("myDrink"))
  const [bio, setBio] = useState("");


  const handleGender = (e) => {
    setGenderValue(e.target.value)
  }
  const handleHeightChange = (e) => {
    setHeightValue(e.target.value)
  }

  const handleWeightChange = (e) => {
    // Restrict input to numbers and maximum length of 3
    const newValue = e.target.value.replace(/[^0-9]/g, '').substring(0, 3);
    setWeightValue(newValue); // Update state with the new value
  };

  const handleCummunity = (e) => {
    setCommunityByReligion(e.target.value)
  }

  const handleCommu = (e) => {
    setCommunityValue(e.target.value)
  }

  const handleMotherTounge = (e) => {
    setMotherToung(e.target.value)
  }

  const handleMaritalStatus = (e) => {
    setMaritalValue(e.target.value)
  }

  const handleFoodChange = (e) => {
    setFoodValue(e.target.value)
  }

  const handleSmoke = (e) => {
    setSmokeValue(e.target.value)
  }

  const handleDrink = (e) => {
    setDrinkValue(e.target.value)
  }

  useEffect(() => {
    setBio(`Hey I am ${localStorage.getItem('usernameForBio')}, here are few lines that describe me.I'm currently living in ${selectedCity}. Work and family are equally important to me and try to maintain a healthy balance between my personal and professional life. The most important quality I seek in my partner is that he should respect and love my family as her own.`);
  }, [selectedCity]);

  const handleBioChange = (e) => {
    const inputValue = e.target.value;
    setBio(inputValue);
  };

  const handleNext = () => {

    const data = {
      gender: document.querySelector(".gender").value,
      dob: dob,
      age: age,
      country: selectedCountry,
      state: selectedState,
      home_town: selectedCity,
      height: document.querySelector(".height").value,
      weight: document.querySelector(".weight").value,
      religion: document.querySelector(".religion").value,
      community: document.querySelector(".community").value,
      mother_tongue: document.querySelector(".mother-tongue").value,
      marital_status: document.querySelector(".marital-status").value,
      food_preference: document.querySelector(".food-preference").value,
      smoke: document.querySelector(".smoke").value,
      drink: document.querySelector(".drink").value,
      bio: document.querySelector("#bio").value,
    };

    if (!document.querySelector(".gender").value &&
      !dob &&
      !age &&
      !selectedCountry &&
      !selectedState &&
      !selectedCity &&
      !document.querySelector(".height").value &&
      !document.querySelector(".weight").value &&
      !document.querySelector(".religion").value &&
      !document.querySelector(".community").value &&
      !document.querySelector(".mother-tongue").value &&
      !document.querySelector(".marital-status").value &&
      !document.querySelector(".food-preference").value &&
      !document.querySelector(".smoke").value &&
      !document.querySelector(".drink").value) {
      for (const key in data) {
        if (data[key] === "") {
          toast.error(`Please Fill All Field.`, {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 1000,
          });
          return;
        }
      }
    }

    if (!document.querySelector(".gender").value) {
      toast.error(`Please Fill Gender Field.`, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      });
      return;
    }

    if (!dob) {
      toast.error(`Please Fill Date Of Birth Field.`, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      });
      return;
    }
    if (!age) {
      toast.error(`Please Fill Age Field.`, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      });
      return;
    }
    if (!document.querySelector(".height").value) {
      toast.error(`Please Fill Height Field.`, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      });
      return;
    }
    if (!document.querySelector(".weight").value) {
      toast.error(`Please Fill Weight Field.`, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      });
      return;
    }
    if (document.querySelector(".weight").value < 20) {
      toast.error(`Please Enter Weight Above 20kg.`, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      });
      return;
    }
    if (!selectedCountry) {
      toast.error(`Please Fill Country Field.`, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      });
      return;
    }
    if (!selectedState) {
      toast.error(`Please Fill State Field.`, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      });
      return;
    }
    if (!selectedCity) {
      toast.error(`Please Fill City Field.`, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      });
      return;
    }
    if (!document.querySelector(".religion").value) {
      toast.error(`Please Fill Religion Field.`, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      });
      return;
    }
    if (!document.querySelector(".community").value) {
      toast.error(`Please Fill Community Field.`, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      });
      return;
    }
    if (!document.querySelector(".mother-tongue").value) {
      toast.error(`Please Fill Mother Tongue Field.`, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      });
      return;
    }
    if (!document.querySelector(".marital-status").value) {
      toast.error(`Please Fill Marital Status Field.`, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      });
      return;
    }
    if (!document.querySelector(".food-preference").value) {
      toast.error(`Please Fill Food Preference Field.`, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      });
      return;
    }
    if (!document.querySelector(".smoke").value) {
      toast.error(`Please Fill Smoke Preference Field.`, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      });
      return;
    }
    if (!document.querySelector(".drink").value) {
      toast.error(`Please Fill Drink Preference Field.`, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      });
      return;
    }
    if (bio.length == 0) {
      toast.error("Please Fill Bio Field.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      });
      return;
    }
    if (bio.length < 40) {
      toast.error("Bio Must Be At Least 40 Characters Long.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      });
      return;
    }


    let path = `/education-Career-details`;
    navigate(path, { state: data });
    localStorage.removeItem('fillup');
    localStorage.setItem("myGender", genderValue);
    localStorage.setItem("myAge", age);
    localStorage.setItem("myHeight", heightValue);
    localStorage.setItem("myWeight", weightValue);
    localStorage.setItem("myCountry", selectedCountry);
    localStorage.setItem("myState", selectedState);
    localStorage.setItem("myCity", selectedCity);
    localStorage.setItem("myReligion", communityByReligion);
    localStorage.setItem("myCommunity", communityValue);
    localStorage.setItem("myMotherToungue", motherToung);
    localStorage.setItem("myMarital", maritalValue);
    localStorage.setItem("myFood", foodValue);
    localStorage.setItem("mySmoke", smokeValue);
    localStorage.setItem("myDrink", drinkValue);
    localStorage.setItem("myBiodata", bio);
  };

  const [motherTongues, setMotherTongues] = useState([]);
  const [maritalStatus, setMaritalStatus] = useState([]);
  const [foodPreference, setFoodPreference] = useState([]);
  const [smokePreference, setSmokePreference] = useState([]);
  const [drinkPreference, setDrinkPreference] = useState([]);
  const [religion, setReligion] = useState([]);
  const [communities, setCommunities] = useState([]);

  const [settleDown, setSettleDown] = useState([]);
  useEffect(() => {
    const fetchMotherTongues = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}/api/mother_tongue`
        );

        if (Array.isArray(response.data.data)) {
          setMotherTongues(response.data.data);
        } else {
          setMotherTongues([response.data.data]);
        }
      } catch (error) {
        console.error("Error fetching mother tongues:", error);
      }
    };

    const fetchMaritalStatus = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}/api/marital_status`
        );

        if (Array.isArray(response.data.data)) {
          setMaritalStatus(response.data.data);
        } else {
          setMaritalStatus([response.data.data]);
        }
      } catch (error) {
        console.error("Error fetching Marital Status:", error);
      }
    };

    const fetchFoodPreference = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}/api/food_preference`
        );

        if (Array.isArray(response.data.data)) {
          setFoodPreference(response.data.data);
        } else {
          setFoodPreference([response.data.data]);
        }
      } catch (error) {
        console.error("Error fetching Food Preference:", error);
      }
    };

    const fetchSmokePreference = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}/api/smoke_status`
        );

        if (Array.isArray(response.data.data)) {
          setSmokePreference(response.data.data);
        } else {
          setSmokePreference([response.data.data]);
        }
      } catch (error) {
        console.error("Error fetching Smoke Status:", error);
      }
    };

    const fetchDrinkPreference = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}/api/drink_status`
        );

        if (Array.isArray(response.data.data)) {
          setDrinkPreference(response.data.data);
        } else {
          setDrinkPreference([response.data.data]);
        }
      } catch (error) {
        console.error("Error fetching Drink Status:", error);
      }
    };

    const fetchReligionPreference = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}/api/religion`
        );
        if (Array.isArray(response.data.data)) {
          setReligion(response.data.data);
        } else {
          setReligion([response.data.data]);
        }
      } catch (error) {
        console.error("Error fetching Religion Status:", error);
      }
    };

    const fetchSettleDown = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}/api/settle_down_value`
        );

        if (Array.isArray(response.data.data)) {
          setSettleDown(response.data.data);
        } else {
          setSettleDown([response.data.data]);
        }
      } catch (error) {
        console.error("Error fetching Settle Down Status:", error);
      }
    };

    fetchMotherTongues();
    fetchMaritalStatus();
    fetchFoodPreference();
    fetchSmokePreference();
    fetchDrinkPreference();
    fetchReligionPreference();
    fetchSettleDown();
  }, []);
  function getMaxDate() {
    const today = new Date();
    const maxDate = new Date(
      today.getFullYear() - 18,
      today.getMonth(),
      today.getDate()
    );
    return maxDate.toISOString().split("T")[0]; // Convert to YYYY-MM-DD format
  }






  useEffect(() => {
    const fetchCommunities = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}/api/community?religious_name=${communityByReligion}`
        );


        if (Array.isArray(response.data.data)) {
          setCommunities(response.data.data);
        } else {
          setCommunities([response.data.data]);
        }
      } catch (error) {
        console.error("Error fetching Communities:", error);
      }
    };

    fetchCommunities();

  }, [communityByReligion]);






  return (
    <div>
      <div className="r_login__wrapper1">
        <div className="r_login">
          <h2 className="personal_details">Personal Details</h2>
          <div className="gender_state">
            <p className="mb-1" style={{ fontWeight: "500" }}>Gender</p>
            <select className="placeholder-css gender rounded px-3 py-1" value={genderValue} onChange={handleGender} required style={{ border: "1px solid #f97096" }}>
              <option value="" selected disabled>Gender</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
            </select>
          </div>
          <div className="gender_state">
            <p className="mb-1" style={{ fontWeight: "500" }}>Enter Your DOB</p>
            <input
              placeholder="DOB"
              type="Date"
              className="placeholder-css dob rounded px-3 py-1 w-100"
              id="dob"
              value={dob}
              max={getMaxDate()}
              onChange={calculatedAge}
              style={{ border: "1px solid #f97096" }}
            />
          </div>
          <div className="gender_state">
            <p className="mb-1" style={{ fontWeight: "500" }}>Enter Your Age</p>
            <input
              type="text"
              name="age"
              id="age"
              placeholder="Age"
              className="placeholder-css city rounded px-3 py-1 w-100"
              style={{
                border: "1px solid #f97096", fontWeight: "400",
                fontSize: "14.3414px"
              }}
              value={age}
            />
          </div>
          <div className="gender_state">
            <p className="mb-1" style={{ fontWeight: "500" }}>Enter Your Height</p>
            <select onChange={handleHeightChange} value={heightValue} className="placeholder-css height rounded px-3 py-1" required style={{ border: "1px solid #f97096" }}>
              <option value="" selected disabled>Height</option>
              {Height.map((height, index) => (
                <option key={index} value={height}>
                  {height}
                </option>
              ))}
            </select>
          </div>
          <div className="gender_state">
            <p className="mb-1" style={{ fontWeight: "500" }}>Enter Your Weight</p>
            <input
              placeholder="Weight"
              type="text"
              className="placeholder-css weight rounded px-3 py-1 w-100"
              style={{
                border: "1px solid #f97096",
                backgroundColor: "transparent",
              }}
              maxLength="3" // Set maximum length to 3 characters
              value={weightValue}
              onChange={handleWeightChange} // Restrict input to numbers and maximum length of 3
            />
          </div>
          <div className="gender_state">
            <p className="mb-1" style={{ fontWeight: "500" }}>Select Your Country</p>
            <select
              placeholder="Home town"
              className="placeholder-css rounded px-3 py-1"
              name="country"
              value={selectedCountry}
              id="country"
              style={{ border: "1px solid #f97096" }}
              onChange={handleCountryChange}
            >
              <option value="" selected disabled>Select your country:</option>
              {countries.map((country) => (
                <option key={country.name} value={country.name}>
                  {country.name}
                </option>
              ))}
            </select>
          </div>
          <div className="gender_state">
            <p className="mb-1" style={{ fontWeight: "500" }}>Enter Your State</p>
            <select
              placeholder="Home town"
              name="country"
              className="placeholder-css rounded px-3 py-1"
              id=""
              style={{ border: "1px solid #f97096" }}
              value={selectedState}
              onChange={handleStateChange}
              disabled={!selectedCountry}
            >
              <option value="" selected disabled>Select your State:</option>
              {selectedCountry &&
                findCountry(selectedCountry).states.map((state) => (
                  <option key={state.name} value={state.name}>
                    {state.name}
                  </option>
                ))}
            </select>
          </div>
          <div className="gender_state">
            <p className="mb-1" style={{ fontWeight: "500" }}>Enter Your Home Town</p>
            <select
              placeholder="Home town"
              name="country"
              className="placeholder-css rounded px-3 py-1"
              id=""
              style={{ border: "1px solid #f97096" }}
              value={selectedCity}
              onChange={handleCityChange}
              disabled={!selectedState}
            >
              <option value="" selected disabled>Select your Home Town:</option>
              {selectedState &&
                findState(findCountry(selectedCountry), selectedState)?.cities.map((city) => (
                  <option key={city} value={city.name}>
                    {city.name}
                  </option>
                ))}
            </select>
          </div>
          <div className="gender_state">
            <p className="mb-1" style={{ fontWeight: "500" }}>Enter Your Religion</p>
            <select value={communityByReligion} className="placeholder-css religion  rounded px-3 py-1" onChange={handleCummunity} required style={{ border: "1px solid #f97096" }}>
              <option value="" selected disabled>Religion</option>
              {religion &&
                religion.map((religion, index) => (
                  <option key={index} value={religion.religious_name}>
                    {religion.religious_name}
                  </option>
                ))}
            </select>
          </div>
          <div className="gender_state">
            <p className="mb-1" style={{ fontWeight: "500" }}>Enter Your Community</p>
            <select value={communityValue} className="placeholder-css community  rounded px-3 py-1" onChange={handleCommu} required style={{ border: "1px solid #f97096" }}>
              <option value="" selected disabled>Community</option>
              {communities &&
                communities.map((communities, index) => (
                  <option key={index} value={communities.religious_name}>
                    {communities.community_name}
                  </option>
                ))}
            </select>
          </div>
          <div className="gender_state">
            <p className="mb-1" style={{ fontWeight: "500" }}>Enter Your Mother Tongue</p>
            <select onChange={handleMotherTounge} value={motherToung} className="placeholder-css mother-tongue  rounded px-3 py-1" required style={{ border: "1px solid #f97096" }}>
              <option value="" selected disabled>Mothers Tongue</option>
              {motherTongues &&
                motherTongues.map((tongue, index) => (
                  <option key={index} value={tongue.mt_name}>
                    {tongue.mt_name}
                  </option>
                ))}
            </select>
          </div>
          <div className="gender_state">
            <p className="mb-1" style={{ fontWeight: "500" }}>Enter Your Marital Status</p>
            <select onChange={handleMaritalStatus} value={maritalValue} className="placeholder-css marital-status  rounded px-3 py-1" required style={{ border: "1px solid #f97096" }}>
              <option value="" selected disabled>Marital Status</option>
              {maritalStatus &&
                maritalStatus.map((marital, index) => (
                  <option key={index} value={marital.status}>
                    {marital.status}
                  </option>
                ))}
            </select>
          </div>
          <div className="gender_state">
            <p className="mb-1" style={{ fontWeight: "500" }}>Enter Your Food Preference</p>
            <select onChange={handleFoodChange} value={foodValue} className="placeholder-css food-preference rounded px-3 py-1" required style={{ border: "1px solid #f97096" }}>
              <option value="" selected disabled>Food Preference</option>
              {foodPreference &&
                foodPreference.map((foodPreference, index) => (
                  <option key={index} value={foodPreference.fp_name}>
                    {foodPreference.fp_name}
                  </option>
                ))}
            </select>
          </div>
          <div className="gender_state">
            <p className="mb-1" style={{ fontWeight: "500" }}>Enter Your Smoke Preference</p>
            <select onChange={handleSmoke} value={smokeValue} className="placeholder-css smoke rounded px-3 py-1" required style={{ border: "1px solid #f97096" }}>
              <option value="" selected disabled>Smoke</option>
              {smokePreference &&
                smokePreference.map((smokePreference, index) => (
                  <option key={index} value={smokePreference.smoke_value}>
                    {smokePreference.smoke_value}
                  </option>
                ))}
            </select>
          </div>
          <div className="gender_state">
            <p className="mb-1" style={{ fontWeight: "500" }}>Enter Your Drink Preference</p>
            <select onChange={handleDrink} value={drinkValue} className="placeholder-css drink rounded px-3 py-1" required style={{ border: "1px solid #f97096" }}>
              <option value="" selected disabled>Drink</option>
              {drinkPreference &&
                drinkPreference.map((drinkPreference, index) => (
                  <option key={index} value={drinkPreference.drink_value}>
                    {drinkPreference.drink_value}
                  </option>
                ))}
            </select>
          </div>
          <div className="gender_state">
            <p className="mb-1" style={{ fontWeight: "500" }}>Enter Your Bio</p>
            <textarea
              name="bio"
              id="bio"
              cols="30"
              rows="5"
              className="placeholder-css bio rounded px-3 py-1 w-100"
              placeholder="Enter BIO"
              style={{ border: "1px solid #f97096" }}
              value={bio}
              onChange={handleBioChange}
              required
            ></textarea>
          </div>



          <div id="recaptcha"></div>
          <button className="btn__next" onClick={handleNext}>
            Next
          </button>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default RegisterPersonalDetail;