import React, { useState, useEffect } from "react";
import AcceptedReqeustsCard from "../../../Components/Profile2/AcceptedRequests/AcceptedRequests";
import "./AcceptedRequests.css";
import { BASE_URL } from "../../../BASE_URL";

const AcceptedRequests = ({ horizontal, setValue }) => {
  var ReceivedRequestsClasses =
    horizontal === true
      ? "profile2_received_request accepted_request_horizontal"
      : "profile2_received_request accepted_request_vertical";

  const [error, setError] = useState(null);
  const [receivedData, setReceivedData] = useState(null);


  const fetchData = async () => {
    try {
      const response = await fetch(`${BASE_URL}/api/request/confirm`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("saptvidhiUserToken"),
        },
      });

      if (!response.ok) {
        throw new Error("Request failed");
      }

      const responseData = await response.json();
      setReceivedData(responseData.data);
    } catch (error) {
      setError(error.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);


  const reload = () => {
    fetchData();
  }

  return (
    <div className="profile_accepted_requests_wrapper">
      <div className="profile_accepted_requests_wrapper_top">
        <h3 className="profile_requests_title">
          Accepted Request <span>( {receivedData?.length || 0} )</span>
        </h3>
        <p
          onClick={() => {
            setValue("3");
          }}
        >
          View All
        </p>
      </div>
      {receivedData?.length === 0 ? (
        <div className="nothing-see">
          <p>No request accepted</p>
        </div>
      ) : (
        <div className={ReceivedRequestsClasses} id="card">
          {receivedData?.map((request) => (
            <AcceptedReqeustsCard key={request._id} data={request} rel={reload} />
          ))}
        </div>
      )}
    </div>
  );
};

export default AcceptedRequests;
