import React from 'react';
import "./bridalmakeup.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';

const BridalMakeUp = () => {
    return (
        <div className='my-5'>
            <div className="container">
                <div className="bridal-makeup-intro-grid">
                    <div className='bridal-makeup-intro'>
                        <h3 className='pb-2'>Bridal Makeup</h3>
                        <p className='mt-4'>Your wedding look is incomplete without bridal makeup. Browse through our blogs for tons of ideas and inspirations for the perfect makeup look for your special day.</p>
                        <div className='bridal-makeup-intro-list'>
                            <ul>
                                <li>Bridal Beauty Checklists and To-dos</li>
                                <li>Bridal Beauty Checklists and To-dos</li>
                                <li>Best Engagement Makeup Looks</li>
                                <li>100+ Bridal makeup images</li>
                            </ul>
                            <ul>
                                <li>South Indian Bridal Makeup Looks</li>
                                <li>South Indian Bridal Makeup Looks</li>
                                <li>Things to ask your makeup Artist</li>
                                <li>Find Bridal Makeup Artists</li>
                            </ul>
                        </div>
                    </div>
                    <div>
                        <img src="./bridal-makeup-header.png" alt="" />
                    </div>
                </div>
            </div>
            <div className='makeup-article'>
                <div className="container">
                    <div className='makeup-article-filter'>
                        <div className='makeup-article-search'>
                            <input type="text" placeholder='Search Articles' />
                            <FontAwesomeIcon icon={faMagnifyingGlass} className='makeup-article-search-icon' />
                        </div>
                        <select name="" id="">
                            <option value="">Wedding Vendors</option>
                        </select>
                        <select name="" id="">
                            <option value="">Select City</option>
                        </select>
                    </div>
                    <div className='makeup-articles'>
                        <div className='makeup-article-card'>
                            <div>
                                <img src="./makeup-article.png" alt="" className='img-fluid mb-3' />
                                <h6 className='mb-3'>By Sakshi | 10 May | 6 min read </h6>
                                <h4 className='mb-2'>What To Buy For Your Makeup Trousseau - 2023 Edition</h4>
                                <p>Lehengas and suits aside, there is one part of your bridal trousseau that no bride should ever miss out on. Makeup! Whether you are a minimal gal or a bride who loves her makeup, everyone needs a basi...</p>
                            </div>
                        </div>
                        <div className='makeup-article-card'>
                            <div>
                                <img src="./makeup-article.png" alt="" className='img-fluid mb-3' />
                                <h6 className='mb-3'>By Sakshi | 10 May | 6 min read </h6>
                                <h4 className='mb-2'>What To Buy For Your Makeup Trousseau - 2023 Edition</h4>
                                <p>Lehengas and suits aside, there is one part of your bridal trousseau that no bride should ever miss out on. Makeup! Whether you are a minimal gal or a bride who loves her makeup, everyone needs a basi...</p>
                            </div>
                        </div>
                        <div className='makeup-article-card'>
                            <div>
                                <img src="./makeup-article.png" alt="" className='img-fluid mb-3' />
                                <h6 className='mb-3'>By Sakshi | 10 May | 6 min read </h6>
                                <h4 className='mb-2'>What To Buy For Your Makeup Trousseau - 2023 Edition</h4>
                                <p>Lehengas and suits aside, there is one part of your bridal trousseau that no bride should ever miss out on. Makeup! Whether you are a minimal gal or a bride who loves her makeup, everyone needs a basi...</p>
                            </div>
                        </div>
                        <div className='makeup-article-card'>
                            <div>
                                <img src="./makeup-article.png" alt="" className='img-fluid mb-3' />
                                <h6 className='mb-3'>By Sakshi | 10 May | 6 min read </h6>
                                <h4 className='mb-2'>What To Buy For Your Makeup Trousseau - 2023 Edition</h4>
                                <p>Lehengas and suits aside, there is one part of your bridal trousseau that no bride should ever miss out on. Makeup! Whether you are a minimal gal or a bride who loves her makeup, everyone needs a basi...</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className='makeup-guides'>
                    <div className='makeup-guides-header'>
                        <h3>Browse Our Guides</h3>
                        <p>View All</p>
                    </div>
                    <div className='makeup-guides-photo-grid'>
                        <img src="./brose-guide.png" alt="" />
                        <img src="./brose-guide.png" alt="" />
                        <img src="./brose-guide.png" alt="" />
                        <img src="./brose-guide.png" alt="" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BridalMakeUp
