import React from 'react';
import "./banquethallreviewcard.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot, faCircle, faCalendar } from '@fortawesome/free-solid-svg-icons';

const BanquetHallReviewCard = () => {
    return (
        <>
            <div className='banquet-review-card-border'>
                <div className='d-flex gap-3'>
                    <div>
                        <img src="./banquet-review.png" alt="" />
                    </div>
                    <div className='d-flex flex-column justify-content-center me-4'>
                        <p className=''><FontAwesomeIcon icon={faCalendar} className='me-1' /> 02/02/2024</p>
                        <h4 className='mb-2'>Hotel Raj Bagh Palace</h4>
                        <p className='mb-4'>It's very good experience such a luxury property near by nature very good rooms well maintained nice banquet sportive staff</p>
                        <div className='banquet-review-by'>
                            <img src="./review-profile.png" alt="" />
                            <p className='ms-2 mb-0'>Jhone Doe</p>
                            <p className='mb-0 ms-auto'>Read More</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BanquetHallReviewCard
