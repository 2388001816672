import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BASE_URL } from "../../BASE_URL";
import "./social.css";
import Select from "react-select";
import makeAnimated from 'react-select/animated';

const Socialmedia = () => {
    const navigate = useNavigate();

    const [insta, setInsta] = useState("")
    const [face, setFace] = useState("")
    const [twit, setTwit] = useState("")
    const [snap, setSnap] = useState("")


    const handleInstaChange = (e) => {
        setInsta(e.target.value);
    };

    const handleFaceChange = (e) => {
        setFace(e.target.value);
    };

    const handleTwitChange = (e) => {
        setTwit(e.target.value);
    };

    const handleSnapChange = (e) => {
        setSnap(e.target.value);
    };

    const validateUrl = (url) => {
        // Regular expression for a valid URL
        const urlRegex = /^(https?:\/\/)?([\w.]+)\.([a-z]{2,6}\.?)(\/[\w.&%?#=-]*)?$/;
        return urlRegex.test(url);
    }

    const handleSubmit = async () => {
        try {
            const token = localStorage.getItem('token');

            // Build the request payload dynamically
            const requestBody = {};
            if (insta.trim() !== "") {
                requestBody.insta_link = insta;
            }
            if (face.trim() !== "") {
                requestBody.fb_link = face;
            }
            if (twit.trim() !== "") {
                requestBody.twitter_link = twit;
            }
            if (snap.trim() !== "") {
                requestBody.snap_chat_link = snap;
            }



            if (!insta && !face && !twit && !snap) {
                toast.error('Please Fill All Fields!', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 1000,
                });
                return;
            }

            if (!insta) {
                toast.error('Please Fill Instagram Fields!', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 1000,
                });
                return;
            }

            if (!validateUrl(insta)) {
                toast.error('Invalid Instagram Link!', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 1000,
                });
                return;
            }

            if (!face) {
                toast.error('Please Fill Facebook Fields!', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 1000,
                });
                return;
            }

            if (!validateUrl(face)) {
                toast.error('Invalid Facebook Link!', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 1000,
                });
                return;
            }

            if (!twit) {
                toast.error('Please Fill Twitter Fields!', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 1000,
                });
                return;
            }

            if (!validateUrl(twit)) {
                toast.error('Invalid Twitter Link!', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 1000,
                });
                return;
            }

            if (!snap) {
                toast.error('Please Fill Snapchat Fields!', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 1000,
                });
                return;
            }


            if (!validateUrl(snap)) {
                toast.error('Invalid Snapchat Link!', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 1000,
                });
                return;
            }

            // Make the API call with the dynamically built payload
            const res1 = await fetch(`${BASE_URL}/api/user_other_info`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: token,
                },
                body: JSON.stringify(requestBody),
            });

            if (!res1.ok) {
                toast.error('Failed to submit links!', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 1000,
                });
                return;
            }

            if (res1.ok) {
                toast.success('Link Uploaded successfully!', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 1000,
                });
                setTimeout(() => {
                    navigate("/profile")
                }, 2000);
            } else {
                toast.error('Failed to upload Links!', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 1000,
                });
            }
        } catch (error) {
            toast.error('An unexpected error occurred!', {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 1000,
            });
        }
    };





    return (
        <>
            <div className="social_box mt-5 mb-5">
                <div className="social_in_box">
                    <div className="social_background">
                        <div className="class_for_label mb-4">
                            <h2 className="mb-5 text-center">Social Media</h2>
                            <div className="row">
                                <div className="col-12 mb-3">
                                    <div>
                                        <div className="social_media_links">
                                            <h6 className="mb-0">Instargram</h6>
                                            <input
                                                type="text"
                                                name="insta_link"
                                                id="instagram"
                                                placeholder="Enter Your Instagram Account Link"
                                                onChange={handleInstaChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 mb-3">
                                    <div>
                                        <div className="social_media_links">
                                            <h6 className="mb-0">Facebook </h6>
                                            <input
                                                style={{ marginLeft: "13px" }}
                                                type="text"
                                                name="fb_link"
                                                id="facebook"
                                                placeholder="Enter Your Facebook Account Link"
                                                onChange={handleFaceChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 mb-3">
                                    <div>
                                        <div className="social_media_links">
                                            <h6 className="mb-0">Twitter </h6>
                                            <input
                                                style={{ marginLeft: "38px" }}
                                                type="text"
                                                name="twitter"
                                                id="twitter"
                                                placeholder="Enter Your Twitter Account Link"
                                                onChange={handleTwitChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 mb-3">
                                    <div>
                                        <div className="social_media_links">
                                            <h6 className="mb-0">Snapchat </h6>
                                            <input
                                                style={{ marginLeft: "13px" }}
                                                type="text"
                                                name="snap_chat_link"
                                                id="snapchat"
                                                placeholder="Enter Your Snapchat Account Link"
                                                onChange={handleSnapChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div id="recaptcha"><ToastContainer /></div>
                        <div className="d-flex">
                            <button className="text-light health_submit" onClick={handleSubmit}>
                                Submit
                            </button>
                        </div>
                        <div className="d-flex justify-content-center mt-3">
                            <NavLink to="/profile" className="text-center" style={{ color: "grey" }}>skip</NavLink>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Socialmedia;
