import React from 'react';
import Modal from "react-bootstrap/Modal";
import { useState, useEffect } from 'react';
import { BASE_URL } from '../../BASE_URL';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./edithealthdetails.css"
import { useNavigate } from 'react-router-dom';

function EditHealthDetails() {

    const navigate = useNavigate();

    const [show3, setShow3] = useState(false);
    const handleClose3 = () => setShow3(false);
    const handleShow = () => setShow3(true);



    const [selectedThalassemia, setSelectedThalassemia] = useState("");
    const [selectedHiv, setSelectedHiv] = useState("");
    const [selectedDisability, setSelectedDisability] = useState("");
    const [selectedBloodGroup, setSelectedBloodGroup] = useState("");
    const [disable, setDisable] = useState([]);
    const [blood, setBlood] = useState([]);
    const [otherInfo, setOtherInfo] = useState("")


    const contactNoFromLocalStorage = localStorage.getItem("contact_no");
    const formattedContactnum = localStorage.getItem("contactnumber");


    let contactNo;

    if (contactNoFromLocalStorage) {
        contactNo = contactNoFromLocalStorage;
    } else {
        contactNo = "+91" + formattedContactnum;
    }



    const userDetails = async () => {
        const token = localStorage.getItem("saptvidhiUserToken");
        const res = await fetch(
            `${BASE_URL}/api/profile/checkProfile`,
            {
                method: "POST",
                headers: {
                    Authorization: token,
                    "Content-Type": "application/json",
                },
            }
        );
        const data = await res.json();
        setOtherInfo(data.data.user[0].user_other_infos)
        setSelectedThalassemia(data.data.user[0].user_other_infos?.thalassemia || null);
        setSelectedHiv(data.data.user[0].user_other_infos?.hiv || null);
        setSelectedDisability(data.data.user[0].user_other_infos?.any_disability || null);
        setSelectedBloodGroup(data.data.user[0].user_other_infos?.blood_group || null)
    };

    const Call4 = async () => {
        const res = await fetch(`${BASE_URL}/api/any_disability`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        });
        const data = await res.json();
        setDisable(data.data);
    };

    const Call3 = async () => {
        const res = await fetch(`${BASE_URL}/api/blood_group`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        });
        const data = await res.json();
        setBlood(data.data);
    };

    useEffect(() => {
        userDetails();
        Call4();
        Call3();
    }, []);



    const handleDisabilityChange = (event) => {
        setSelectedDisability(event.target.value);
    };


    const handleBloodGroupChange = (event) => {
        setSelectedBloodGroup(event.target.value);
    };



    const handleSubmit3 = async () => {
        try {
            const token = localStorage.getItem('token');

            // Compare edited values with original values
            const changedFields = {};
            if (selectedThalassemia !== otherInfo.thalassemia) {
                changedFields.thalassemia = selectedThalassemia;
            }

            if (selectedHiv !== otherInfo.hiv) {
                changedFields.hiv = selectedHiv;
            }

            if (selectedDisability !== otherInfo.any_disability) {
                changedFields.any_disability = selectedDisability;
            }

            if (selectedBloodGroup !== otherInfo.blood_group) {
                changedFields.blood_group = selectedBloodGroup;
            }

            const res1 = await fetch(`${BASE_URL}/api/user_other_info`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: token,
                },
                body: JSON.stringify(changedFields),
            });

            if (!res1.ok) {
                // toast.error("Failed to submit family information");
                toast.error('Failed To Submit Health Details.', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 1000,
                });
                return;
            }

            // toast.success("Family information submitted successfully");
            userDetails();
            toast.success('Health Details Update Successfully.', {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 1000,
            });
            handleClose3();

        } catch (error) {
            toast.error("An unexpected error occurred");
        }
    };


    const handleEdit = () => {
        navigate("/edit-my-information" , {state : "health"}) 
    }


    return (
        <>

            <Modal show={show3} onHide={handleClose3} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Health Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="class_for_label mb-4">
                        <div className="class_for_label d-sm-flex d-block mb-4 align-items-center">
                            <h6 className="mb-sm-0 mb-1 health-sub-titles" >Thalassemia :</h6>
                            <div className="d-flex align-items-center ms-sm-3 ms-0">
                                <div className="d-flex align-items-center me-sm-4 me-3">
                                    <input
                                        type="radio"
                                        name="thalassemia"
                                        id="no"
                                        value="No"
                                        checked={selectedThalassemia === "No"}
                                        onChange={() => setSelectedThalassemia("No")}
                                    />
                                    <label style={{ fontSize: "12px" }} className="mb-0 ms-2" htmlFor="no">
                                        No
                                    </label>
                                </div>
                                <div className="d-flex align-items-center me-sm-4 me-3">
                                    <input
                                        type="radio"
                                        name="thalassemia"
                                        id="major"
                                        value="Major"
                                        checked={selectedThalassemia === "Major"}
                                        onChange={() => setSelectedThalassemia("Major")}
                                    />
                                    <label style={{ fontSize: "12px" }} className="mb-0 ms-2" htmlFor="major">
                                        Major
                                    </label>
                                </div>
                                <div className="d-flex align-items-center me-sm-4 me-3">
                                    <input
                                        type="radio"
                                        name="thalassemia"
                                        id="minor"
                                        value="Minor"
                                        checked={selectedThalassemia === "Minor"}
                                        onChange={() => setSelectedThalassemia("Minor")}
                                    />
                                    <label style={{ fontSize: "12px" }} className="mb-0 ms-2" htmlFor="minor">
                                        Minor
                                    </label>
                                </div>
                                <div className="d-flex align-items-center me-sm-4 me-3">
                                    <input
                                        type="radio"
                                        name="thalassemia"
                                        id="dont-know"
                                        value="Don't Know"
                                        checked={selectedThalassemia === "Don't Know"}
                                        onChange={() => setSelectedThalassemia("Don't Know")}
                                    />
                                    <label style={{ fontSize: "12px" }} className="mb-0 ms-2" htmlFor="dont-know">
                                        Don't Know
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="class_for_label d-sm-flex d-block mb-4 align-items-center">
                            <h6 className="mb-sm-0 mb-2 health-sub-titles-2" >HIV+ :</h6>
                            <div className="d-flex align-items-center ms-sm-3 ms-0">
                                <div className="d-flex align-items-center me-4">
                                    <input
                                        type="radio"
                                        name="hiv"
                                        id="no"
                                        value="No"
                                        checked={selectedHiv === "No"}
                                        onChange={() => setSelectedHiv("No")}
                                    />
                                    <label style={{ fontSize: "12px" }} className="mb-0 ms-2" htmlFor="no">
                                        No
                                    </label>
                                </div>
                                <div className="d-flex align-items-center me-4">
                                    <input
                                        type="radio"
                                        name="hiv"
                                        id="yes"
                                        value="yes"
                                        checked={selectedHiv === "yes"}
                                        onChange={() => setSelectedHiv("yes")}
                                    />
                                    <label style={{ fontSize: "12px" }} className="mb-0 ms-2" htmlFor="Yes">
                                        yes
                                    </label>
                                </div>
                                <div className="d-flex align-items-center me-4">
                                    <input
                                        type="radio"
                                        name="hiv"
                                        id="dont-know"
                                        value="Don't Know"
                                        checked={selectedHiv === "Don't Know"}
                                        onChange={() => setSelectedHiv("Don't Know")}
                                    />
                                    <label style={{ fontSize: "12px" }} className="mb-0 ms-2" htmlFor="dont-know">
                                        Don't Know
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="class_for_label d-sm-flex d-block mb-4 align-items-center">
                            <h6 className='mb-sm-0 mb-1 edit-health-select'>Any Disability :</h6>
                            <select
                                id=""
                                className='ms-sm-4 ms-0 '
                                name="any_disability"
                                value={selectedDisability}
                                onChange={handleDisabilityChange}
                            >
                                <option value="" selected disabled>Select</option>
                                {disable && disable.map((e) => {
                                    return (
                                        <option value={e.any_disability}>{e.any_disability}</option>
                                    )
                                })}
                            </select>
                        </div>
                        <div className="class_for_label d-sm-flex d-block align-items-center mb-4">
                            <h6 className='mb-sm-0 mb-1 edit-health-select-2'>Select Your Blood Group :</h6>
                            <select
                                id=""
                                name="blood_group"
                                value={selectedBloodGroup}
                                onChange={handleBloodGroupChange}
                                
                            >
                                <option value="" selected disabled>Select</option>
                                {blood && blood.map((e) => {
                                    return (
                                        <option value={e.blood_group}>{e.blood_group}</option>
                                    )
                                })}
                            </select>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className="footer-btn-modal">
                    <button onClick={handleClose3}>Close</button>
                    <button onClick={handleSubmit3}>Submit</button>
                </Modal.Footer>
            </Modal>
            <div className="userprofile_family_details pb-4 ps-3 pt-3">
                <div className="userprofile_section_header">
                    <h3>Health Details</h3>
                    <button className="edit_btn_profile" onClick={handleEdit} style={{ cursor: 'pointer' }} >
                        Edit
                    </button>
                </div>
                <div className="userprofile_family_details_content_wrapper ps-3">
                    <div className="userprofile_family_details_content">
                        <div>
                            <span>Blood Group</span>
                            <p className="">{otherInfo.blood_group}</p>
                        </div>
                    </div>
                    <div className="userprofile_family_details_content">
                        <div>
                            <span>Any Disability</span>
                            <p className="">{otherInfo?.any_disability}</p>
                        </div>
                    </div>
                    <div className="userprofile_family_details_content">
                        <div>
                            <span>Hiv</span>
                            <p className="">{otherInfo?.hiv}</p>
                        </div>
                    </div>
                    <div className="userprofile_family_details_content">
                        <div>
                            <span>Thalassemia</span>
                            <p className="">{otherInfo.thalassemia}</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EditHealthDetails;