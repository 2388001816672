import React from "react";
import "./LatestProfileCard.css";

const LatestProfileCard = ({ image, message, name, city, state, country, height }) => {
  return (
    <div className="latestProfileCard">
      <img src={image} alt="" />
      <span>{name}</span>
      <p className="mb-0">{city},{state},</p>
      <p>{country}</p>
    </div>
  );
};

export default LatestProfileCard;
