import React, { useState, useEffect } from "react";
import "./PartnerCommunity.css";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import axios from "axios";
import { BASE_URL } from "../../BASE_URL";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const PartnerCommunity = () => {
  const [country, setCountry] = useState("");
  const [home_town, setHome_town] = useState("Amd");
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [state, setState] = useState([]);
  const [cities, setCities] = useState([]);
  const [authToken, setAuthToken] = useState("");
  const [isLoading, setIsLoading] = useState(true);



  const [registration, setRegistration] = useState({
    full_name: "",
    company_name: "",
    address: "",
    city: "",
    state: "",
    Country: "",
    contact_no: "",
    emailid: "",
  });


  console.log(registration.city);
  console.log(registration.state);

  const [validation, setValidation] = useState({
    full_name: true,
    company_name: true,
    address: true,
    city: true,
    state: true,
    Country: true,
    contact_no: true,
    emailid: true,
  });

  const handleRegist = (e) => {
    const { name, value } = e.target;
    setRegistration({ ...registration, [name]: value });
    setValidation({ ...validation, [name]: true }); // Reset validation for the field when the user starts typing again

    if (name === "emailid") {
      // Email validation using a regular expression
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const isValidEmail = value === "" || emailPattern.test(value);
      setValidation({ ...validation, emailid: isValidEmail });
    }
  };


  const handleStatechange = (e) => {
    const { name, value } = e.target;

    // Update the registration state
    setRegistration({ ...registration, [name]: value, city: "" });
    setValidation({ ...validation, [name]: true });

    // Then load the cities
    loadCities(value);
  };


  const handleSubmit = async () => {

    const { full_name, company_name, address, city, state, Country, contact_no, emailid } = registration;

    if (!contact_no && !full_name && !company_name && !address && !city && !state && !emailid) {
      toast.error('Please Fill All Field!', {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      });
      return;
    }

    const newValidation = {};
    for (const key in registration) {
      if (registration[key].trim() === "") {
        newValidation[key] = false;
      } else {
        newValidation[key] = true;
      }
    }
    setValidation(newValidation);

    if (Object.values(newValidation).some((isValid) => !isValid)) {
      return;
    }

    if (registration.contact_no.length < 10) {
      toast.error('Please Enter Valid Number!', {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      });
      return;
    }

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmail = registration.emailid === "" || emailPattern.test(registration.emailid);

    if (!isValidEmail) {
      toast.error('Please Enter A Valid Email Address!', {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      });
      return;
    }

    // Submit the form if all fields are valid
    try {
      const response = await fetch(`${BASE_URL}/api/vendor`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(registration),
      });
      const data = await response.json();


      if (data.code == 200) {
        toast.success('Congratulations you registered Succefully!', {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 1000,
        });
        setRegistration({
          full_name: "",
          company_name: "",
          address: "",
          city: "",
          state: "",
          Country: "",
          contact_no: "",
          emailid: "",
        });
      }
    } catch (error) {

    }
  };


  const handleKeyDown = (e) => {
    // Allow: backspace, delete, tab, escape, enter
    if ([46, 8, 9, 27, 13].indexOf(e.keyCode) !== -1 ||
      // Allow: Ctrl+A/Ctrl+C/Ctrl+V
      (e.keyCode === 65 && e.ctrlKey === true) ||
      (e.keyCode === 67 && e.ctrlKey === true) ||
      (e.keyCode === 86 && e.ctrlKey === true) ||
      // Allow: home, end, left, right
      (e.keyCode >= 35 && e.keyCode <= 39)) {
      // Let it happen, don't do anything
      return;
    }
    // Ensure that it is a number and stop the keypress
    if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
      e.preventDefault();
    }
  };


  useEffect(() => {
    loadCountries();
  }, []);

  useEffect(() => {
    loadCountries2();
  }, [isLoading]);

  const loadCountries = async () => {
    try {
      const response = await axios.get("https://www.universal-tutorial.com/api/getaccesstoken", {
        headers: {
          Accept: "application/json",
          "api-token": "ftkic-0z5KhVZqsBsoI-tH_7A-1NGcKkOEpGojs1DIKRhL2mHTF1Pdba-oSZMQjDr7E",
          "user-email": "webearlitsolution@gmail.com",
        },
      });
      setAuthToken(response.data.auth_token);
      setIsLoading(false);
    } catch (error) {

    }
  };

  const loadCountries2 = async () => {
    try {
      const response = await axios.get("https://www.universal-tutorial.com/api/countries", {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      });
      setCountries(response.data);
    } catch (error) {

    }
  };

  const loadStates = async (country) => {
    try {
      const response = await axios.get(`https://www.universal-tutorial.com/api/states/${country}`, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${authToken}`,
          "user-email": "sarjilp2903@gmail.com",
        },
      });
      setStates(response.data);
    } catch (error) {

    }
  };

  const loadCities = async (state) => {
    try {
      const response = await axios.get(`https://www.universal-tutorial.com/api/cities/${state}`, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${authToken}`,
          "user-email": "sarjilp2903@gmail.com",
        },
      });
      setCities(response.data);

    } catch (error) {

    }
  };



  return (
    <div className="container__wrapper partner__community">
      <span>Home &gt; Partner community</span>
      <h2 className="open__positions">Sign up as partner</h2>
      <h3>Your Personal Details</h3>
      <div className="partner__personal__details">
        <div>
          <div className="mb-4 placeholder-black">
            <input
              type="text"
              className="partner__personal__details__input"
              placeholder="Your Full Name"
              name="full_name"
              value={registration.full_name}
              onChange={handleRegist}
            />
            {!validation.full_name && <p className="error-message mb-0" style={{ color: "red" }}>Please Fill Your Full Name</p>}
          </div>

          <div className="mb-4 placeholder-black">
            <input
              maxLength={10}
              type="tel"
              onInput={(e) => (e.target.value) = e.target.value.replace(/[^0-9]/g, '')}
              className="partner__personal__details__input"
              placeholder="Contact Number"
              name="contact_no"
              value={registration.contact_no}
              onChange={handleRegist}
              onKeyDown={handleKeyDown}
            />
            {!validation.contact_no && <p className="error-message mb-0" style={{ color: "red" }}>Please Fill Your Contact Number</p>}
          </div>

          <div className="mb-4 placeholder-black">
            <input
              type="text"
              className="partner__personal__details__input"
              placeholder="Email Address"
              name="emailid"
              onChange={handleRegist}
              value={registration.emailid}
            />
            {!validation.emailid && (
              <p className="error-message mb-0" style={{ color: "red" }}>
                {registration.emailid === "" ? "Please Enter Your Email Address" : "Please Enter A Valid Email Address"}
              </p>
            )}
          </div>

          <div className="mb-4 placeholder-black">
            <input
              type="text"
              className="partner__personal__details__input"
              placeholder="Your Company Name"
              name="company_name"
              onChange={handleRegist}
              value={registration.company_name}
            />
            {!validation.company_name && <p className="error-message mb-0" style={{ color: "red" }}>Please Fill Your Company Name</p>}
          </div>

        </div>


        <div>
          <ToastContainer />

          <div className="mb-4 placeholder-black">
            <input
              type="text"
              className="partner__personal__details__input"
              placeholder="Address"
              name="address"
              onChange={handleRegist}
              value={registration.address}
            />
            {!validation.address && <p className="error-message mb-0" style={{ color: "red" }}>Please Fill Your Address</p>}
          </div>

          {/* country  */}
          <div className="mb-4 placeholder-black">
            <select
              placeholder="Home town"
              name="Country"
              id=""
              onChange={(e) => {
                setCountry(e.target.value);
                loadStates(e.target.value);
                handleRegist(e);
                setCities([])
              }}
              value={registration.Country}
            >
              <option value="">Select Your Country:</option>
              {countries.map((c) => {
                return <option value={c.country_name}>{c.country_name}</option>;
              })}
            </select>
            {!validation.Country && <p className="error-message mb-0" style={{ color: "red" }}>Please Select Your Country</p>}
          </div>

          <div className="mb-4">
            <select
              placeholder="Home town"
              name="state"
              id=""
              onChange={handleStatechange}
              value={registration.state}
            >
              <option value="" >Select Your State:</option>
              {states.map((c) => {
                return <option value={c.state_name} >{c.state_name}</option>;
              })}
            </select>
            {!validation.state && <p className="error-message mb-0" style={{ color: "red" }}>Please Select Your State</p>}
          </div>

          {/* city */}
          <div className="">
            <select
              placeholder="Home town"
              name="city"
              id=""
              onChange={(e) => {
                setHome_town(e.target.value);
                handleRegist(e);
              }}
              value={registration.city}
            >
              <option value="">Select Your City:</option>
              {cities.map((c) => {
                return <option value={c.city_name}>{c.city_name}</option>;
              })}
            </select>
            {!validation.city && <p className="error-message mb-0" style={{ color: "red" }}>Please Select Your City</p>}
          </div>
        </div>
      </div>
      <button onClick={handleSubmit} style={{ color: "white", fontSize: "16px", fontWeight: "500" }} className="mt-3">Submit</button>
    </div>
  );
};

export default PartnerCommunity;
