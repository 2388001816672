import React, { useRef } from "react";
import "./ImgCarousel.css";

import leftbtn from "../../../Assets/left.png";
import rightbtn from "../../../Assets/right.png";
import slideImg1 from "../../../Assets/nea-parmar.jpg";
import slideImg2 from "../../../Assets/viranchi-kumar.jpg";

// Import Swiper and SwiperSlide components
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-cards';
import { EffectCards } from 'swiper/modules'; // Import the necessary Swiper modules

const LatestProfilesCarousel = () => {
    // Create refs for both Swiper instances
    const cardSwiperRef = useRef(null);
    const textSwiperRef = useRef(null);

    // Function to handle left swipe
    const handleSwipeLeft = () => {
        if (cardSwiperRef.current && textSwiperRef.current) {
            cardSwiperRef.current.swiper.slidePrev();
            textSwiperRef.current.swiper.slidePrev();
        }
    };

    // Function to handle right swipe
    const handleSwipeRight = () => {
        if (cardSwiperRef.current && textSwiperRef.current) {
            cardSwiperRef.current.swiper.slideNext();
            textSwiperRef.current.swiper.slideNext();
        }
    };

    // Function to synchronize swipers
    const synchronizeSwipers = (sourceSwiper, targetSwiper) => {
        sourceSwiper.on('slideChange', () => {
            const activeIndex = sourceSwiper.activeIndex;
            if (targetSwiper) {
                targetSwiper.slideTo(activeIndex);
            }
        });
    };

    return (
        <div className="container">
            <div className="yuvraj-carousel my-5">
                <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Swiper
                        ref={cardSwiperRef}
                        effect="cards"
                        grabCursor
                        modules={[EffectCards]}
                        className="mySwiper"
                        style={{ width: '300px', height: '400px' }}
                        onSwiper={(swiper) => synchronizeSwipers(swiper, textSwiperRef.current?.swiper)}
                    >
                        {/* Add SwiperSlide components with your content */}
                        <SwiperSlide>
                            <img src={slideImg1} alt="Slide 1" className="live-profile-image" />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={slideImg2} alt="Slide 2" className="live-profile-image" />
                        </SwiperSlide>
                    </Swiper>
                </div>
                <Swiper
                    ref={textSwiperRef}
                    className="mySwiper"
                    onSwiper={(swiper) => synchronizeSwipers(swiper, cardSwiperRef.current?.swiper)}
                >
                    <SwiperSlide>
                        <div className="custemers-thinking">
                            <h3>Dr. Neha P, <span>33 years</span></h3>
                            <p>Hey I am Dr.Neha parmar, here are few lines that describe me. I have completed my BPT and I'm currently living in Dahod. Work and family are equally important to me and try to maintain a healthy balance between my personal and professional life.</p>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="custemers-thinking">
                            <h3>VIRANCHIKUMAR S, <span>30 years</span></h3>
                            <p>Hey I am VIRANCHIKUMAR SAPARA. I have completed my Bachelor of Engineering and I'm currently living in Vadodara.</p>
                        </div>
                    </SwiperSlide>
                </Swiper>
                <div className="swipers-button">
                    <img
                        src={leftbtn}
                        alt="Swipe Left"
                        onClick={handleSwipeLeft}
                        style={{ cursor: 'pointer', marginRight: '10px' }} // Added margin for spacing
                    />
                    <img
                        src={rightbtn}
                        alt="Swipe Right"
                        onClick={handleSwipeRight}
                        style={{ cursor: 'pointer' }}
                    />
                </div>
            </div>
        </div>
    );
};

export default LatestProfilesCarousel;
