import React from "react";
import "./ReceivedRequestCard.css";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import LanguageIcon from "@mui/icons-material/Language";
import PlaceIcon from "@mui/icons-material/Place";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import PersonIcon from "@mui/icons-material/Person";
import TempleHinduIcon from "@mui/icons-material/TempleHindu";
import { useNavigate } from "react-router-dom";
import crown from "../crown.png"
import { BASE_URL } from "../../../BASE_URL";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ChatIcon from "@mui/icons-material/Chat";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import HowToRegIcon from '@mui/icons-material/HowToReg';
import { faCrown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ReceivedRequestCard = ({ data, updateData }) => {
  const member_type = localStorage.getItem("memberType");

  const acceptRequest = async () => {
    try {
      const response = await fetch(
        `${BASE_URL}/api/request?_id=${data._id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("saptvidhiUserToken"),
          },
          body: JSON.stringify({
            request_status: "confirm",
            request_type: "request",
          }),
        }
      );

      if (response.status == 200) {
        updateData();
        toast.success(`Request Accept of ${data?.profile[0].user_name}!`, {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 1000,
        });

      }
    } catch (err) {
      console.log(err);
    }
  };
  const navigate = useNavigate();
  const declineRequest = async () => {
    try {
      const response = await fetch(
        `${BASE_URL}/api/request?_id=${data._id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("saptvidhiUserToken"),
          },
          body: JSON.stringify({
            request_status: "decline",
            request_type: "request",
          }),
        }
      );
      if (response.status == 200) {
        updateData();
        toast.success(`Request Decline Of ${data?.profile[0].user_name}!`, {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 1000,
        });
      }
    } catch (err) {
      alert(err);
    }
  };


  return (

    <div className="received_request_card">
      <div className="received_request_card_left" style={{ position: "relative" }}>
        {data?.profile?.[0]?.member_type === "paid" && (
          <img
            src={crown}
            style={{
              width: "15px",
              height: "15px",
              position: "absolute",
              top: "53px",
              right: "10px",
            }}
            alt=""
          />
        )}
        <div
          className="left_intro_group"
          style={{ cursor: "pointer" }}
          onClick={() =>
            navigate(`/userprofile/${data.profile?.[0]?._id}`, {
              state: { status: "invite", id: data._id },
            })
          }
        >
          <img src={data && data?.profile?.[0]?.profile_photo} alt="" />
          <div className="received_request_card_name">
            <PersonIcon sx={{ height: "15px" }} />
            {data && data?.profile?.[0]?.user_name}
          </div>
        </div>
      </div>
      <div className="received_request_card_right">
        <div
          className="card_right_top"
          style={{ cursor: "pointer" }}
          onClick={() =>
            navigate(`/userprofile/${data.profile[0]._id}`, {
              state: { status: "invite", id: data._id },
            })
          }
        >
          <p>
            <TrendingUpIcon sx={{ height: "15px" }} />
            {data && data?.profile?.[0]?.age} yrs,{" "}
            {data && data?.profile?.[0]?.height}
          </p>
          <p>
            <LanguageIcon sx={{ height: "15px" }} />
            {data && data?.profile?.[0]?.mother_tongue}
          </p>
          <p>
            <PlaceIcon sx={{ height: "15px" }} />
            {data && data?.profile?.[0]?.home_town},{" "}
            {data && data?.profile?.[0]?.state}
          </p>
          <p>
            <TempleHinduIcon sx={{ height: "15px" }} />
            {data && data?.profile?.[0]?.religion} , {data && data?.profile?.[0]?.community}
          </p>
          <p>
            <WorkOutlineIcon sx={{ height: "15px" }} />
            {data && data?.profile?.[0]?.highest_qualification}
          </p>
        </div>
        <p
          className=""
          style={{ cursor: "pointer" }}
          onClick={() =>
            navigate(`/userprofile/${data?.profile?.[0]?._id}`, {
              state: { status: "invite" },
            })
          }
        >
          {data && data?.profile?.[0]?.bio}

        </p>
        <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
          <div style={{ display: "flex", }}>

            <div style={{ marginLeft: "20px", textAlign: "center" }}>
              <HowToRegIcon
                sx={{ height: "25px", cursor: "pointer" }}
                onClick={acceptRequest}
              />
              <p style={{ fontWeight: "400", fontSize: "10px" }} onClick={acceptRequest}>Accept</p>
            </div>
            <div style={{ marginLeft: "20px", textAlign: "center" }}>
              <CancelOutlinedIcon
                sx={{ height: "25px", cursor: "pointer" }}
                onClick={declineRequest}
              />
              <p style={{ fontWeight: "400", fontSize: "10px" }} onClick={declineRequest}>Decline</p>
            </div>

            {member_type === "paid" ? (
              <>

              </>
            ) : (
              <>
                <div style={{ marginLeft: "15px", textAlign: "center" }}>
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => navigate("/SelectPlan")}
                  >
                    {" "}
                    <FontAwesomeIcon icon={faCrown} style={{ fontSize: "20px" }} />
                    <p style={{ fontWeight: "400", fontSize: "10px" }} >Upgrade Now</p>
                  </span>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default ReceivedRequestCard;
