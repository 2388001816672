import {
  ArrowUpward,
  BarChart,
  CalendarMonth,
  CastForEducationOutlined,
  FemaleOutlined,
  GirlOutlined,
  Group,
  GroupOutlined,
  Language,
  LocationCityOutlined,
  Lock,
  LockOutlined,
  Mail,
  MailOutline,
  Person,
  Person2Outlined,
  Person3Outlined,
  PersonOutline,
  Phone,
  PhoneOutlined,
  SchoolOutlined,
} from "@mui/icons-material";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import MilitaryTechIcon from "@mui/icons-material/MilitaryTech";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./EditCareerAndEducation.css";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import { BASE_URL } from "../../BASE_URL";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Select from "react-select";
import makeAnimated from 'react-select/animated';

function EditCareerAndEducation({ onProfileUpdate }) {

  const contactNo = localStorage.getItem("contact_no");

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  let navigate = useNavigate();
  const token = localStorage.getItem("saptvidhiUserToken");
  const [higherQualification, setHigherQualification] = useState([]);
  const [salaryRange, setSalaryRange] = useState([]);
  const [employ, setEmploye] = useState([]);
  const [selectedEmploy, setSelectedEmploy] = useState("");
  const [otherInfo, setOtherInfo] = useState("")
  const [formData, setFormData] = useState({
    highest_qualification: "",
    college: "",
    job_title: "",
    company_name: "",
    salary: "",
  });


  const [noChanges, setNochanges] = useState({
    highest_qualification: "",
    college: "",
    job_title: "",
    company_name: "",
    salary: "",
  })


  const userDetails = async () => {
    const res = await fetch(`${BASE_URL}/api/profile/userdetails`, {
      method: "GET",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
    });
    const data = await res.json();
    const userDetails = data?.data?.UserDetails;
    if (userDetails) {
      setFormData({
        highest_qualification: userDetails.highest_qualification,
        college: userDetails.college,
        job_title: userDetails.job_title,
        company_name: userDetails.company_name,
        salary: userDetails.salary,
        designation: userDetails.designation,
      });
    }
    if (userDetails) {
      setNochanges({
        highest_qualification: userDetails.highest_qualification,
        college: userDetails.college,
        job_title: userDetails.job_title,
        company_name: userDetails.company_name,
        salary: userDetails.salary,
        designation: userDetails.designation,
      });
    }
  };


  useEffect(() => {
    userDetails();
    fetchHighestQualification();
    fetchSalaryRange();
  }, []);

  const fetchHighestQualification = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/api/highest_qualification`
      );

      if (Array.isArray(response.data.data)) {
        setHigherQualification(response.data.data);
      } else {
        setHigherQualification([response.data.data]);
      }
    } catch (error) {
      console.error("Error fetching Higher Qualification status:", error);
    }
  };

  const fetchSalaryRange = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/api/salary`
      );

      if (Array.isArray(response.data.data)) {
        setSalaryRange(response.data.data);
      } else {
        setSalaryRange([response.data.data]);
      }
    } catch (error) {
      console.error("Error fetching Salary Range:", error);
    }
  };

  const handleSubmit = async () => {
    try {
      if (!formData.highest_qualification) {
        toast.error(`Please Select Your Highest Qualification!`, {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 1000,
        });
        return;
      }
      if (!formData.college) {
        toast.error(`Please Enter Your Collage Name!`, {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 1000,
        });
        return;
      }
      if (!formData.job_title) {
        toast.error(`Please Select Your Job Type!`, {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 1000,
        });
        return;
      }
      if (!formData.company_name) {
        toast.error(`Please Enter Your Company Name!`, {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 1000,
        });
        return;
      }
      if (!formData.salary) {
        toast.error(`Please Select Your Salry Range!`, {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 1000,
        });
        return;
      }

      const combinedFormData = new FormData();

      Object.entries(formData).forEach(([key, value]) => {
        combinedFormData.append(key, value);
      });

      const response = await axios.put(
        `${BASE_URL}/api/profile`,
        combinedFormData,
        {
          headers: {
            Authorization: token,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      handleSubmit3();
      onProfileUpdate();
      if (response.status == 200) {
        handleClose();
        toast.success("Education & Career updated Successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 1000,
        });

      }
    } catch (error) {
      console.error("Error storing or updating data:", error);
    }
  };

  const handleChange = (e) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [e.target.name]: e.target.value,
    }));
  };

  const [formdata9, setFormdata9] = useState({
    highest_qualification: "",
    college: "",
    company_name: "",
    job_title: "",
    salary: "",
  })

  const Call9 = async () => {
    const res = await fetch(
      `${BASE_URL}/api/profile/checkProfile`,
      {
        method: "POST",
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    );
    const data = await res.json();
    setFormdata9(data?.data?.user?.[0]);
  };

  const Call6 = async () => {
    const res = await fetch(`${BASE_URL}/api/employed`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await res.json();
    setEmploye(data.data);
  };


  const checkProfile = async () => {
    const token = localStorage.getItem("saptvidhiUserToken");
    const res = await fetch(
      `${BASE_URL}/api/profile/checkProfile`,
      {
        method: "POST",
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    );
    const data = await res.json();
    if (data.data.user[0].user_other_infos) {
      setOtherInfo(data.data.user[0].user_other_infos)
      setSelectedEmploy(data.data.user[0].user_other_infos.employed_type[0])
    }
  };

  const handleSubmit3 = async () => {
    try {
      const token = localStorage.getItem('token');

      // Compare edited values with original values
      const changedFields = {};

      if (selectedEmploy !== otherInfo?.employed_type[0]) {
        changedFields.employed_type = selectedEmploy;
      }

      const res1 = await fetch(`${BASE_URL}/api/user_other_info`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(changedFields),
      });

      if (!res1.ok) {
        toast.error("Failed to submit family information", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 1000,
        });
        return;
      }

    } catch (error) {
      // toast.error("An unexpected error occurred");
    }
  };

  useEffect(() => {
    checkProfile();
    Call9();
    Call6();
  }, []);

  const handleEmployChange = (selectedOption) => {
    setSelectedEmploy(selectedOption.value);
  };

  const animatedComponents = makeAnimated();

  const employe = employ.map((emp) => ({
    value: emp.employed_type,
    label: emp.employed_type,
  }));


  const [listJobs, setListJobs] = useState([]);

  const jobsList = async () => {
    const res = await fetch(`${BASE_URL}/api/job_title`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await res.json();
    setListJobs(data.data);
  };

  useEffect(() => {
    jobsList();
  }, []);

  const handleEditEducation = () => {
    navigate("/edit-my-information", { state: "education" })
  }

  return (
    <div>
      <div>
        <div onClick={handleShow}>
          <button className="edit_btn_profile" 
          onClick={handleEditEducation}
          // onClick={userDetails} 
          style={{ cursor: 'pointer' }} >
            Edit
          </button>
        </div>
        <ToastContainer />
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <h2>Education/Career Details</h2>
        </Modal.Header>
        <div>
          <div className="">
            <div className="education-edit-container" >
              <div className="gender_state w-100">
                <label htmlFor="" style={{ fontWeight: "500" }}>Select Your Highest Qualification</label>
                <select
                  name="highest_qualification"
                  className="gender rounded px-3 py-1 mb-3"
                  style={{ border: "1px solid #f97096" }}
                  value={formData.highest_qualification}
                  onChange={handleChange}
                  required
                >
                  <option value="" disabled>Highest Qualification</option>
                  {higherQualification &&
                    higherQualification.map((item, index) => (
                      <option key={index} value={item.hq_name}>
                        {item.hq_name}
                      </option>
                    ))}
                </select>
              </div>
              <div>
                <label htmlFor="" style={{ fontWeight: "500" }}>Enter Your College Name</label>
                <input
                  placeholder="College Name"
                  type="text"
                  className="rounded px-3 py-1 mb-3"
                  style={{ border: "1px solid #f97096" }}
                  name="college"
                  value={formData.college}
                  onChange={handleChange}
                  required
                />
              </div>
              <div>
                <label htmlFor="" style={{ fontWeight: "500" }}>Enter Your Job</label>
                {/* <input
                  placeholder="Job Title"
                  type="text"
                  name="job_title"
                  className="rounded px-3 py-1 mb-3"
                  style={{ border: "1px solid #f97096" }}
                  value={formData.job_title}
                  onChange={handleChange}
                  required
                /> */}
                <select
                  name="job_title"
                  className="gender rounded px-3 py-1 mb-3"
                  style={{ border: "1px solid #f97096" }}
                  value={formData.job_title}
                  onChange={handleChange}
                  required
                >
                  <option value=""  disabled>Highest Qualification</option>
                  {listJobs &&
                    listJobs.map((item, index) => (
                      <option key={index} value={item.jt_name}>
                        {item.jt_name}
                      </option>
                    ))}
                </select>
              </div>
              <div>
                <label htmlFor="" style={{ fontWeight: "500" }}>Enter Your Company Name</label>
                <input
                  placeholder="Company Name"
                  type="text"
                  name="company_name"
                  className="rounded px-3 py-1 mb-3"
                  style={{ border: "1px solid #f97096" }}
                  value={formData.company_name}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="gender_state w-100">
                <label htmlFor="" style={{ fontWeight: "500" }}>Select Your Salary Range</label>
                <select
                  className="gender rounded px-3 py-1 mb-3"
                  style={{ border: "1px solid #f97096" }}
                  name="salary"
                  value={formData.salary}
                  onChange={handleChange}
                  required
                >
                  <option value="" disabled>Salary Range</option>
                  {salaryRange &&
                    salaryRange.map((item, index) => (
                      <option key={index} value={item.salary_value}>
                        {item.salary_value}
                      </option>
                    ))}
                </select>
              </div>
              {otherInfo?.employed_type?.[0] ? (
                <div className="gender_state w-100">
                  <label style={{ fontWeight: "500" }}> Employed In :</label>
                  <Select
                    name="employed_type"
                    value={selectedEmploy}
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                    onChange={handleEmployChange}
                    options={employe}
                    placeholder={selectedEmploy}
                    className="w-100 border_pink"
                  />
                </div>
              ) : ""}

              <input
                type="file"
                name="profile_photo"
                id="profile_photo"
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
        <Modal.Footer>

          <div className="footer-btn-modal d-flex justify-content-end mb-4" style={{ paddingRight: "45px" }}>
            <button onClick={handleClose} className="me-2">
              Close
            </button>
            <button onClick={handleSubmit}>Save</button>
          </div>
        </Modal.Footer>
      </Modal>

    </div>
  );
}

export default EditCareerAndEducation;
