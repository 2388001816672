import React from "react";
import Carousel from "../Carousel/Carousel";
import ImgCarousel from "../ImgCarousel/ImgCarousel";
import LatestProfilesCarousel from "../ImgCarousel/LatestProfileCarousel";

const Customerthink = () => {
    return (
        <div className="howdoesitworks">
            <h2>What our customers think</h2>
            <p>
                Our customers rave about our matrimonial app, praising its intuitive interface, accurate matches, and seamless communication tools. Love stories begin here!
            </p>
            <LatestProfilesCarousel />
        </div>
    );
};

export default Customerthink;
