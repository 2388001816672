import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "../Login/Login.css";
import axios from "axios";
import { BASE_URL } from "../../BASE_URL";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Otp = () => {
  let navigate = useNavigate();
  const [otp, setOtp] = useState("");
  const handleChangeOtp = (e) => {
    setOtp(e.target.value);
  }
  const location = useLocation();
  const { data, random } = location.state;

  console.log(data)

  const handleSubmit = async () => {

    if (otp != "123456") {
      toast.error('Please Enter Valid Otp!', {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      });
      return;
    }

    if (!otp) {
      toast.error('Please Enter Otp!', {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      });
      return;
    }

    if (otp.length < 6) {
      toast.error('Please Enter Valid Otp!', {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      });
      return;
    }

    // if (random != otp) {
    //   toast.error('Please Enter Valid Otp', {
    //     position: toast.POSITION.BOTTOM_RIGHT,
    //     autoClose: 1000,
    //   });
    //   return;
    // }


    try {
      const response = await axios.post(`${BASE_URL}/api/profile`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });


      if (response.status === 200) {
        localStorage.setItem("token", response.data.data.token);
        const currentDateAndTime = new Date().toLocaleDateString();
        localStorage.setItem("loginDateTime", currentDateAndTime);
        toast.success('Otp Verified Successfully', {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 1000,
        });
        setTimeout(() => {
          navigate("/personal-deatils")
        }, 2000);
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      });

    }




  }


  return (
    <>
      <div className="login__wrapepr">
        <div className="login OTP_input">
          <h2>Enter OTP</h2>
          <input
            type="tel"
            maxLength="6"
            placeholder="OTP"
            className="OTP_input"
            value={otp}
            onChange={handleChangeOtp}
            onInput={(e) => (e.target.value) = e.target.value.replace(/[^0-9]/g, '')}
          />
          <button onClick={handleSubmit}>Submit</button>
        </div>
        <ToastContainer />
      </div>
    </>
  );
};

export default Otp;
