import React, { useEffect, useState } from "react";
import "./BlockUserList.css";
import {
  Alarm,
  Message,
  NavigateBefore,
  NavigateNext,
  Search,
} from "@mui/icons-material";
import RecentVisitorCard from "../../Components/RecentVisitorCard/RecentVisitorCard";
import RecentVisitors from "../../Sections/Profile/RecentVisitors/RecentVisitors";
import Notifications from "../../Sections/Profile/Notifications/Notifications";
import ProfileDetails from "../../Sections/Profile/ProfileDetails/ProfileDetails";
import Invitations from "../../Sections/Profile/Invitations/Invitations";
import NewMatches from "../../Sections/Profile/NewMatches/NewMatches";
import PremiumMatches from "../../Sections/Profile/PremiumMatches/PremiumMatches";
import Messages from "../../Sections/Profile/MessagesComponent/MessagesComponent";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import { Link, useNavigate } from "react-router-dom";
import { BASE_URL } from "../../BASE_URL";

import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import ReceivedRequests from "../../Sections/Profile2/ReceivedRequests/ReceivedRequests";
import BlockProfiles from "../../Sections/Profile2/BlockUserList/BlockProfiles";
import AcceptedRequests from "../../Sections/Profile2/AcceptedRequestsCard/AcceptedRequestsCard";
import DeclinedRequests from "../../Sections/Profile2/DeclinedRequests/DeclinedRequests";
import SentRequests from "../../Sections/Profile2/SentRequests/SentRequests";

// import Profiles from "../Profiles/Profiles.jsx";
// import Profiles from "../Profiles/Profiles.jsx";
// import { BASE_URL } from "../../BASE_URL";

const BlockProfilesList = () => {


  const navigate = useNavigate();

  useEffect(() => {
    // Check if token is not available
    const token = localStorage.getItem("saptvidhiUserToken");
    if (!token) {
      // If token is not available, navigate to the home page
      navigate("/");
    }
  }, []);


  const [value, setValue] = useState("1");
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const FetchRequest = async (event) => {
    // fetch("https://metrimonial.onrender.com/api/request")
    try {
      // const LURL = process.env.LURL;
      // fetch("${LURL}/api/term_and_condition");
      const response = await fetch(`${BASE_URL}/api/blockuser`);
      if (!response.ok) {
      }
      const responseData = await response.json();
      setData(responseData);
    } catch (error) {
      setError(error.message);
    }
  };
  return (
    <div className="profile2">
      <div className="profile2_left">
        <Box sx={{ }}>
          <TabContext value={value}>
            <TabPanel value="1">
              <BlockProfiles horizontal={false} />
            </TabPanel>
          </TabContext>
        </Box>
      </div>
      <div className="profile2_right">
        <Notifications />

        <div className="profile_messages_search_input_wrapper">
          <Search />
          <input
            placeholder="Search"
            type="text"
            className="profile_messages_search_input"
          />
        </div>
        <Messages />
      </div>
    </div>
  );
};

export default BlockProfilesList;
