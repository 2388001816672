import React from 'react';
import "./weddinggallary.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTag } from '@fortawesome/free-solid-svg-icons';

const WeddingGallary = () => {
    return (
        <>
            <div className="">
                <div className='popular-search-box'>
                    <div className='wedding-gallary-card-bg'>
                        <img src="./wedding-gallary-1.png" alt="" className='img-fluid' />
                        <div className='wedding-gallary-content'>
                            <h6 className='mb-0'>Bridal Lehenga</h6>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default WeddingGallary


