import React, { useEffect, useReducer, useState } from "react";
import MatchesRequestCard from "../../../Components/Profile2/MatchesRequestCard/MatchesRequestCard";
import "./MatchesRequests.css";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import LanguageIcon from "@mui/icons-material/Language";
import PlaceIcon from "@mui/icons-material/Place";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import PersonIcon from "@mui/icons-material/Person";
import TempleHinduIcon from "@mui/icons-material/TempleHindu";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import ChatIcon from "@mui/icons-material/Chat";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { useNavigate } from "react-router-dom";
import Slider from '@mui/material/Slider';
import axios from "axios";
import Select from "react-select";
import { BASE_URL } from "../../../BASE_URL";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import countrie from '../../../CountryStateCity.json';

import crown from "./crown.png";
const MatchesRequests = ({ horizontal, setValue }) => {


  const [reload, setReload] = useReducer(x => x + 1, 0);
  var declinedRequestsClasses =
    horizontal === true
      ? "profile2_declined_request declined_request_horizontal"
      : "profile2_declined_request declined_request_vertical";

  const [error, setError] = useState(null);
  const [receivedData, setReceivedData] = useState([]);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const fetchData = async () => {
    try {
      const response = await fetch(
        `${BASE_URL}/api/profile/userlist`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("saptvidhiUserToken"),
          },
        }
      );

      if (!response.ok) {
        throw new Error("Request failed");
      }

      const responseData = await response.json();

      setReceivedData(responseData?.data);
    } catch (error) {
      setError(error.message);
    }
  };

  useEffect(() => {
    fetchData();
    getAllShortListed();
  }, [reload]);
  const [shortData, setShortData] = useState();
  const [iconColors, setIconColors] = useState({});
  const SendRequest = async (data) => {
    try {
      const response = await fetch(`${BASE_URL}/api/request`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("saptvidhiUserToken"),
        },
        body: JSON.stringify({
          receiver_id: data._id,
          request_status: "pending",
          request_type: "request",
        }),
      });


      if (response.status == 200) {

        const user = receivedData.find((user) => user._id === data._id);

        fetchData();
        toast.success(`Request Sent To ${user.user_name}!`, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setIconColors((prevColors) => ({
          ...prevColors,
          [data._id]: "red",
        }));
      }
    } catch (err) {
      alert(err);
    }
  };

  const getAllShortListed = async () => {
    try {
      await axios
        .get(`${BASE_URL}/api/shortlist`, {
          headers: { Authorization: localStorage.getItem("saptvidhiUserToken") },
        })
        .then((res) => {
          setShortData(res.data.data);
        })
        .catch((err) => alert(err));
    } catch (err) {
      alert(err);
    }
  };



  const [straticon, setStaricon] = useState({})
  const handleShortlist = async (data) => {
    try {

      if (data.shortlist) {
        // If data.shortlist is true, it means the item is already shortlisted.
        // In this case, you can call the delete API to remove it from the shortlist.
        await axios
          .delete(`${BASE_URL}/api/shortlist?shortlist_id=${data._id}`, {
            headers: { Authorization: localStorage.getItem("saptvidhiUserToken") },
          })
          .then((res) => {
            setReload();
            if (res.data.code === 200) {
              const user = receivedData.find((user) => user._id === data._id);
              toast.success(`${user.user_name} Removed From Short List!`, {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 1000,
              });
              getAllShortListed();
            }
          })
          .catch((err) => alert(err));
      } else {
        const response = await fetch(`${BASE_URL}/api/shortlist`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("saptvidhiUserToken"),
          },
          body: JSON.stringify({
            shortlist_id: data._id,
          }),
        });
        setReload();
        if (response.status == 200) {
          setStaricon((prevColors) => ({
            ...prevColors,
            [data._id]: "gold",
          }));
          getAllShortListed();
          const user = receivedData.find((user) => user._id === data._id);
          toast.success(`${user.user_name} Shortlisted Successfully!`, {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 1000,
          });
        }
      }
    } catch (err) {
      alert(err);
    }
  };
  const navigate = useNavigate();

  const [maritalStatus, setMaritalStatus] = useState([]);
  const [religion, setReligion] = useState([]);
  const [motherTongues, setMotherTongues] = useState([]);
  const [highestQualification, setHighestQualification] = useState([]);
  const [salary, setSalary] = useState([]);
  const [foodPreference, setFoodPreference] = useState([]);
  const [drinkPreference, setDrinkPreference] = useState([]);
  const [smokePreference, setSmokePreference] = useState([]);
  const [jobTitlePreference, setJobTitlePreference] = useState([]);


  const loadPreference = async () => {
    try {
      const response = await fetch(
        `${BASE_URL}/api/preference/preferencedata`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("saptvidhiUserToken"),
          },
        }
      );
      const responseData = await response.json();

      const preferenceData = responseData.data;

      setMaritalStatus(preferenceData.marital_status);
      setReligion(preferenceData.religion);
      setMotherTongues(preferenceData.mother_tongue);
      setHighestQualification(preferenceData.highest_qualification);
      setSalary(preferenceData.salary);
      setFoodPreference(preferenceData.food_preference);
      setDrinkPreference(preferenceData.drink);
      setSmokePreference(preferenceData.smoke);
      setJobTitlePreference(preferenceData.job_title);
    } catch (error) {
    }
  };

  useEffect(() => {
    loadPreference();
  }, []);

  const [ageRange, setAgeRange] = useState([18, 80]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedOptionscountry, setSelectedOptionscountry] = useState([]);
  const [selectedOptionsMaritual, setSelectedOptionsMaritual] = useState([]);
  const [selectedOptionsHigh, setSelectedOptionsHigh] = useState([]);
  const [selectedOptionsFood, setSelectedOptionsFood] = useState([]);
  const [selectedOptionsSalary, setSelectedOptionsSalary] = useState([]);
  const [selectedOptions2, setSelectedOptions2] = useState([]);
  const [selectedOptions3, setSelectedOptions3] = useState([]);


  const optionsHigh = highestQualification.map((motherTongue) => ({
    value: motherTongue.hq_name,
    label: motherTongue.hq_name,
  }));
  const formatOptionLabelHigh = ({ label, isSelected }) => (
    <div>
      <span>{label}</span>
      {isSelected && <span className="selected-indicator">Selected</span>}
    </div>
  );
  const handleSelectChangeHigh = (selectedOptions) => {
    const hasDoesntMatter = selectedOptions.some(
      (option) => option.value === "Doesn't Matter"
    );
    if (
      selectedOptions[0].value === "Doesn't Matter" &&
      selectedOptions[1].value !== "Doesn't Matter"
    ) {
      setSelectedOptionsHigh([
        selectedOptions[1],
      ]);
    } else if (hasDoesntMatter) {
      setSelectedOptionsHigh([
        { value: "Doesn't Matter", label: "Doesn't Matter" },
      ]);
    } else {
      setSelectedOptionsHigh(selectedOptions);
    }
  };
  const [countries, setCountries] = useState(countrie);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [authToken, setAuthToken] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [state, setState] = useState([]);
  const [home_town, setHome_town] = useState([]);
  const [country, setCountry] = useState([]);
  const [searchCommunity, setSearchCommunity] = useState([])

  let selectedValues = selectedOptions.map((option) => option.value);
  const selectedReligion = selectedOptions2.map((option) => option.value);
  const selectedCommunity = selectedOptions3.map((option) => option.value);
  let selectedMaritual = selectedOptionsMaritual.map((option) => option.value);
  const selectedHigh = selectedOptionsHigh.map((option) => option.value);
  const selectedSalary = selectedOptionsSalary.map((option) => option.value);
  const selectedFood = selectedOptionsFood.map((option) => option.value);
  const handleAgeChange = (event, newAgeRange) => {
    setAgeRange(newAgeRange);
  };

  const options1 = religion.map((motherTongue) => ({
    value: motherTongue.religious_name,
    label: motherTongue.religious_name,
  }));
  const formatOptionLabel1 = ({ label, isSelected }) => (
    <div>
      <span>{label}</span>
      {isSelected && <span className="selected-indicator">Selected</span>}
    </div>
  );

  const options2 = searchCommunity.map((community) => ({
    value: community.community_name,
    label: community.community_name,
  }));
  const formatOptionLabel2 = ({ label, isSelected }) => (
    <div>
      <span>{label}</span>
      {isSelected && <span className="selected-indicator">Selected</span>}
    </div>
  );

  const handleSelectChange2 = (selectedOptions) => {
    if (selectedOptions.length > 1) {
      setSelectedOptions3([]);
    }
    const hasDoesntMatter = selectedOptions.some(
      (option) => option.value === "Doesn't Matter"
    );
    if (
      selectedOptions[0].value === "Doesn't Matter" &&
      selectedOptions[1].value !== "Doesn't Matter"
    ) {
      setSelectedOptions2([
        selectedOptions[1],
      ]);
    } else if (hasDoesntMatter) {
      setSelectedOptions2([
        { value: "Doesn't Matter", label: "Doesn't Matter" },
      ]);
    } else {
      setSelectedOptions2(selectedOptions);
    }
    const religiousNames = selectedOptions.map(option => option.value);

    fetchCommunities(religiousNames);
  };

  const handleSelectChange3 = (selectedCommunity) => {
    const hasDoesntMatter = selectedCommunity.some(
      (option) => option.value === "Doesn't Matter"
    );
    if (
      selectedCommunity[0].value === "Doesn't Matter" &&
      selectedCommunity[1].value !== "Doesn't Matter"
    ) {
      setSelectedOptions3([
        selectedOptions[1],
      ]);
    } else if (hasDoesntMatter) {
      setSelectedOptions3([
        { value: "Doesn't Matter", label: "Doesn't Matter" },
      ]);
    } else {
      setSelectedOptions3(selectedCommunity);
    }
    const religiousNames = selectedCommunity.map(option => option.value);

    fetchCommunities(religiousNames);
  };

  const options = motherTongues.map((motherTongue) => ({
    value: motherTongue.mt_name,
    label: motherTongue.mt_name,
  }));
  const formatOptionLabel = ({ label, isSelected }) => (
    <div>
      <span>{label}</span>
      {isSelected && <span className="selected-indicator">Selected</span>}
    </div>
  );

  const handleSelectChange = (selectedOptions) => {
    const hasDoesntMatter = selectedOptions.some(
      (option) => option.value === "Doesn't Matter"
    );
    if (
      selectedOptions[0].value === "Doesn't Matter" &&
      selectedOptions[1].value !== "Doesn't Matter"
    ) {
      setSelectedOptions([
        selectedOptions[1],
      ]);
    } else if (hasDoesntMatter) {
      setSelectedOptions([
        { value: "Doesn't Matter", label: "Doesn't Matter" },
      ]);
    } else {
      setSelectedOptions(selectedOptions);
    }
  };




  const optionsFood = foodPreference.map((motherTongue) => ({
    value: motherTongue.fp_name,
    label: motherTongue.fp_name,
  }));
  const formatOptionLabelFood = ({ label, isSelected }) => (
    <div>
      <span>{label}</span>
      {isSelected && <span className="selected-indicator">Selected</span>}
    </div>
  );
  const handleSelectChangeFood = (selectedOptions) => {
    const hasDoesntMatter = selectedOptions.some(
      (option) => option.value === "Doesn't Matter"
    );
    if (
      selectedOptions[0].value === "Doesn't Matter" &&
      selectedOptions[1].value !== "Doesn't Matter"
    ) {
      setSelectedOptionsFood([
        selectedOptions[1],
      ]);
    } else if (hasDoesntMatter) {
      setSelectedOptionsFood([
        { value: "Doesn't Matter", label: "Doesn't Matter" },
      ]);
    } else {
      setSelectedOptionsFood(selectedOptions);
    }
  };

  const optionsSalary = salary.map((motherTongue) => ({
    value: motherTongue.salary_value,
    label: motherTongue.salary_value,
  }));

  const formatOptionLabelSalary = ({ label, isSelected }) => (
    <div>
      <span>{label}</span>
      {isSelected && <span className="selected-indicator">Selected</span>}
    </div>
  );

  const handleSelectChangeSalary = (selectedOptions) => {
    const hasDoesntMatter = selectedOptions.some(
      (option) => option.value === "Doesn't Matter"
    );
    if (
      selectedOptions[0].value === "Doesn't Matter" &&
      selectedOptions[1].value !== "Doesn't Matter"
    ) {
      setSelectedOptionsSalary([
        selectedOptions[1],
      ]);
    } else if (hasDoesntMatter) {
      setSelectedOptionsSalary([
        { value: "Doesn't Matter", label: "Doesn't Matter" },
      ]);
    } else {
      setSelectedOptionsSalary(selectedOptions);
    }
  };




  const optionsMaritual = maritalStatus.map((motherTongue) => ({
    value: motherTongue.status,
    label: motherTongue.status,
  }));
  const formatOptionLabelMaritual = ({ label, isSelected }) => (
    <div>
      <span>{label}</span>
      {isSelected && <span className="selected-indicator">Selected</span>}
    </div>
  );
  const handleSelectChangeMaritual = (selectedOptions) => {
    const hasDoesntMatter = selectedOptions.some(
      (option) => option.value === "Doesn't Matter"
    );
    if (
      selectedOptions[0].value === "Doesn't Matter" &&
      selectedOptions[1].value !== "Doesn't Matter"
    ) {
      setSelectedOptionsMaritual([
        selectedOptions[1],
      ]);
    } else if (hasDoesntMatter) {
      setSelectedOptionsMaritual([
        { value: "Doesn't Matter", label: "Doesn't Matter" },
      ]);
    } else {
      setSelectedOptionsMaritual(selectedOptions);
    }
  };

  const [selectedOptionsState, setSelectedOptionsState] = useState([]);
  const [selectedOptionsCity, setSelectedOptionsCity] = useState([]);
  const [loadState, setLoadStates] = useState([]);
  const [loadCity, setLoadCity] = useState([]);


  const countryOptions = countries.map((country) => ({
    value: country.name,
    label: country.name,
  }));

  const handleSelect = (selectedOptions) => {
    const selectedCountryNames = selectedOptions.map(option => option.value);

    setSelectedOptionscountry(selectedOptions);
    setSelectedOptionsState([]);
    setSelectedOptionsCity([]);
    setCountry(selectedCountryNames);

    if (selectedCountryNames.length === 1) {
      const selectedCountry = findCountry(selectedCountryNames[0]);
      setLoadStates(selectedCountry)
    } else {
      setStates([]);
    }
  };

  const findCountry = (countryName) => countries.find((country) => country.name === countryName);

  // Helper function to find a state by name within a country
  const findState = (country, stateName) => country.states.find((state) => state.name === stateName);


  const stateOptions = loadState ?
    loadState.states?.map((state) => ({
      value: state.name,
      label: state.name,
    })) : [];




  const handleStateSelect = (selectedOptions) => {
    // Extract state names from selectedOptions and store them in an array
    const selectedStateNames = selectedOptions.map(option => option.value);

    setSelectedOptionsState(selectedOptions);
    setState(selectedStateNames); // Store state names as an array

    if (selectedStateNames.length === 1) {
      const selectedStateName = selectedStateNames[0];
      const selectedCountry = findCountry(selectedOptionscountry[0].value); // Assuming 'selectedOptionscountry' contains the selected country
      const selectedState = findState(selectedCountry, selectedStateName);
      // Now you have the selected state object in 'selectedState'
      setLoadCity(selectedState)
    } else {
      setLoadCity([])
      // Clear the selected city array when multiple states are selected
      if (selectedOptionsCity.length > 0) {
        setSelectedOptionsCity([]);
        setHome_town([]);
      }
      setCities([]);
    }
  };



  const cityOptions = loadCity && loadCity.cities
    ? loadCity.cities.map((city) => ({
      value: city.name,
      label: city.name,
    }))
    : [];

  const handleCitySelect = (selectedOptions) => {
    const selectedCityNames = selectedOptions.map(option => option.value);

    setSelectedOptionsCity(selectedOptions);
    setHome_town(selectedCityNames); // Store city names as an array
  };


  const fetchCommunities = async (religiousName) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/api/community?religious_name=${religiousName}`
      );

      if (Array.isArray(response.data.data)) {
        setSearchCommunity(response.data.data);
      } else {
        setSearchCommunity([response.data.data]);
      }
    } catch (error) {
    }
  };


  const filterData = async () => {
    setShow(false);
    try {

      const token = localStorage.getItem("saptvidhiUserToken");

      const data = {
        min_age: ageRange[0],
        max_age: ageRange[1],
        salary: selectedSalary,
        marital_status: selectedMaritual,
        religion: selectedReligion,
        community: selectedCommunity,
        mother_tongue: selectedValues,
        highest_qualification: selectedHigh,
        food_preference: selectedFood,
        country: country,
        state: state,
        home_town: home_town,
      }
      const response = await axios.post(
        `${BASE_URL}/api/profile/userlist`,
        data,
        {
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        setReceivedData(response.data.data)
      }
    } catch (error) {
      setError(error.message);
    }
  };


  const filterDataClear = () => {
    fetchData();
    setShow(false);
    setSelectedOptionsSalary([]);
    setSelectedOptionsMaritual([]);
    setSelectedOptions2([]);
    setSelectedOptions3([]);
    setSelectedOptions([]);
    setSelectedOptionsHigh([]);
    setSelectedOptionsFood([]);
    setCountry([]);
    setState([]);
    setHome_town([]);
    setSelectedOptionscountry([]);
    setSelectedOptionsState([]);
    setSelectedOptionsCity([]);

  }


  return (
    <div className="profile_declined_requests_wrapper">
      <div className="profile_declined_requests_wrapper_top">
        <h3 className="profile_requests_title">
          Matches <span>({receivedData.length})</span>
        </h3>
        <button
          className="filter_btn_set"
          variant="primary"
          onClick={handleShow}
        >
          Filter
        </button>

        <Offcanvas show={show} onHide={handleClose}>
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Filter</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <div className="ps-4">
              <div className="gender_state mb-2">
                <p className="mb-1">Age</p>
                <Slider
                  value={ageRange}
                  onChange={handleAgeChange}
                  valueLabelDisplay="auto"
                  aria-labelledby="range-slider"
                  min={18}
                  max={80}
                  style={{ color: "#f97096" }}
                />
              </div>
              <div className="gender_state mb-3">
                <Select
                  className="gender"
                  options={optionsMaritual}
                  isMulti
                  value={selectedOptionsMaritual}
                  onChange={handleSelectChangeMaritual}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      border: "2px solid #f97096",
                    }),
                  }}
                  formatOptionLabel={formatOptionLabelMaritual}
                  placeholder="Select Maritual Status"
                />
              </div>
              <div className="gender_state mb-3">
                <Select
                  className="gender"
                  options={options1}
                  isMulti
                  value={selectedOptions2}
                  onChange={handleSelectChange2}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      border: "2px solid #f97096",
                      borderColor: "#f97096",
                    }),
                  }}
                  formatOptionLabel={formatOptionLabel1}
                  placeholder="Select Religion"
                />
              </div>
              <div className="gender_state mb-3">
                <Select
                  className="gender"
                  options={options2}
                  isMulti
                  value={selectedOptions3}
                  onChange={handleSelectChange3}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      border: "2px solid #f97096",
                      borderColor: "#f97096",
                    }),
                  }}
                  formatOptionLabel={formatOptionLabel2}
                  placeholder="Select Community"
                  isDisabled={selectedOptions2.length > 1}
                />
              </div>
              <div className="gender_state mb-3">
                <Select
                  className="gender"
                  options={options}
                  isMulti
                  value={selectedOptions}
                  onChange={handleSelectChange}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      border: "2px solid #f97096",
                    }),
                  }}
                  formatOptionLabel={formatOptionLabel}
                  placeholder="Select Mother Tounge"
                />
              </div>
              <div className="gender_state mb-3">
                <Select
                  className="gender"
                  options={optionsHigh}
                  isMulti
                  value={selectedOptionsHigh}
                  onChange={handleSelectChangeHigh}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      border: "2px solid #f97096",
                    }),
                  }}
                  formatOptionLabel={formatOptionLabelHigh}
                  placeholder="Select Highest Qualification"
                />
              </div>
              <div className="gender_state mb-3">
                <Select
                  className="gender"
                  options={optionsFood}
                  isMulti
                  value={selectedOptionsFood}
                  onChange={handleSelectChangeFood}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      border: "2px solid #f97096",
                    }),
                  }}
                  formatOptionLabel={formatOptionLabelFood}
                  placeholder="Select Food Preference"
                />
              </div>
              <div className="gender_state mb-3">
                <Select
                  className="gender"
                  options={optionsSalary}
                  isMulti
                  value={selectedOptionsSalary}
                  onChange={handleSelectChangeSalary}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      border: "2px solid #f97096",
                    }),
                  }}
                  formatOptionLabel={formatOptionLabelSalary}
                  placeholder="Select Annual Income"
                />
              </div>
              <div className="gender_state mb-3">
                <div className="app">
                  <div className="dropdown-container">
                    <Select
                      options={countryOptions}
                      placeholder="Select Country"
                      value={selectedOptionscountry}
                      onChange={handleSelect}
                      isSearchable={true}
                      isMulti
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          border: "2px solid #f97096",
                        }),
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="gender_state mb-3">
                {/* <select
                  placeholder="Home town"
                  name="country"
                  value={state}
                  id=""
                  onChange={(e) => {
                    setState(e.target.value);
                    loadCities(e.target.value);
                  }}
                >
                  <option value="">Select your State:</option>
                  {states.map((c) => {
                    return <option value={c.state_name}>{c.state_name}</option>;
                  })}
                </select> */}
                <div className="dropdown-container">
                  <Select
                    options={stateOptions}
                    placeholder="Select State"
                    value={selectedOptionsState}
                    onChange={handleStateSelect}
                    isSearchable={true}
                    isMulti
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        border: "2px solid #f97096",
                      }),
                    }}
                  />

                </div>
              </div>
              <div className="gender_state">
                {/* <select
                  placeholder="Home town"
                  name="country"
                  value={home_town}
                  id=""
                  onChange={(e) => setHome_town(e.target.value)}
                >
                  <option value="">Select your Home Town:</option>
                  {cities.map((c) => {
                    return <option value={c.city_name}>{c.city_name}</option>;
                  })}
                </select> */}
                <Select
                  options={cityOptions}
                  placeholder="Select City"
                  value={selectedOptionsCity}
                  onChange={handleCitySelect}
                  isSearchable={true}
                  isMulti
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      border: "2px solid #f97096",
                    }),
                  }}
                />
              </div>
              <div className="gender_state filter_btn mt-4 text-center">
                <button onClick={filterDataClear} className="me-1">
                  clear
                </button>
                <button onClick={filterData} className="ms-1">
                  Apply
                </button>
              </div>
            </div>
          </Offcanvas.Body>
        </Offcanvas>
      </div>
      <div className={declinedRequestsClasses} id="card">
        {receivedData?.map((data) => (
          <div className="received_request_card mb-sm-0 mb-3">
            <div
              className="received_request_card_left"
              style={{ position: "relative" }}
            >
              {data.member_type === "paid" && (
                <img
                  src={crown}
                  className="premium-user-crown"
                  alt=""
                />
              )}
              <div className="left_intro_group">
                <img
                  src={data && data?.profile_photo}
                  alt=""
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate(`/userprofile/${data._id}`, {
                      state: { status: "pending" },
                    });
                  }}
                />
                <div className="received_request_card_name">
                  <PersonIcon sx={{ height: "15px" }} />
                  {data && data.user_name}
                  {/* <BoltIcon style={{ color: "#FCF204", height: "15px" }} /> */}
                </div>
                {/* {data && data[0]?.profile && data[0].profile[0]?.user_name} */}

                {/* <p className="online_now">
                  Online now
                  <FiberManualRecordIcon
                    sx={{ height: "8px", color: "#22B00B", margin: "0px" }}
                  />
                </p> */}
              </div>
            </div>
            <div className="received_request_card_right">
              <div
                className="card_right_top"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  navigate(`/userprofile/${data._id}`, {
                    state: { status: "pending" },
                  });
                }}
              >
                <p>
                  <TrendingUpIcon sx={{ height: "15px" }} />
                  {data && data.age} yrs, {data && data?.height}
                </p>
                <p>
                  <LanguageIcon sx={{ height: "15px" }} />
                  {data && data?.mother_tongue}
                </p>
                <p>
                  <PlaceIcon sx={{ height: "15px" }} />
                  {data && data?.home_town}, {data && data?.state}
                </p>

                <p>
                  <TempleHinduIcon sx={{ height: "15px" }} />
                  {data && data.religion},{data && data?.community}
                </p>
                <p>
                  <WorkOutlineIcon sx={{ height: "15px" }} />
                  {data && data.highest_qualification}
                </p>
              </div>
              <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
                <div style={{ display: "flex", }}>
                  <div style={{ textAlign: "center" }}>
                    <FavoriteBorderIcon
                      sx={{ height: "25px", cursor: "pointer", color: iconColors[data._id] || "black", }}
                      onClick={() => SendRequest(data)}
                    />
                    <p style={{ fontWeight: "400", fontSize: "10px" }}>
                      Interested
                    </p>
                  </div>


                  <div style={{ marginLeft: "15px", textAlign: "center" }}>

                    <StarBorderIcon
                      sx={{ height: "25px", cursor: "pointer", color: data?.shortlist ? "gold" : "black" }}
                      style={{
                        color: data?.shortlist ? "gold" : "black",
                      }}
                      onClick={() => handleShortlist(data)}
                    />
                    <p style={{ fontWeight: "400", fontSize: "10px" }}>
                      shortlist
                    </p>
                  </div>


                  <div style={{ marginLeft: "20px", textAlign: "center" }}>
                    <ChatIcon
                      sx={{ height: "25px", cursor: "pointer" }}
                      onClick={() => {
                        navigate('/messages', { state: { data: data } });
                        // const matched_id = data.firebase_id;
                        // navigate("/messages", { state: { matched_id } });
                        // toast.success('Data successfully Updated!', {
                        //   position: toast.POSITION.BOTTOM_RIGHT,
                        // });
                      }}
                    />
                    <p style={{ fontWeight: "400", fontSize: "10px" }}>chat</p>
                  </div>
                  <div style={{ marginLeft: "20px", textAlign: "center" }}>
                    <CancelOutlinedIcon
                      sx={{ height: "25px", cursor: "pointer" }}
                    />
                    <p style={{ fontWeight: "400", fontSize: "10px" }}>ignore</p>
                  </div>
                </div>
              </div>
            </div>
            <ToastContainer />
          </div>
        ))}
      </div>
    </div>
  );
};

export default MatchesRequests;
