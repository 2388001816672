import { Route, Routes } from "react-router-dom";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Career from "./Pages/Career/Career";
import Community from "./Pages/Community/Community";
import ContactUs from "./Pages/ContactUs/ContactUs";
import Faqs from "./Pages/Faqs/Faqs";
import Footer from "./Components/Footer/Footer";
import Header from "./Components/Header/Header";
import Home from "./Pages/Home/Home";
import PartnerCommunity from "./Pages/PartnerCommunity/PartnerCommunity";
import PrivacyPolicy from "./Pages/PrivacyPolicy/PrivacyPolicy";
import TermsAndConditions from "./Pages/TermsAndConditions/TermsAndConditions";
import SignUp from "./Pages/SignUp/SignUp";
import Profile from "./Pages/Profile/Profile";
import MessagesComponent from "./Sections/Profile/MessagesComponent/MessagesComponent";
import { useEffect, useState } from "react";
import UserProfile from "./Pages/UserProfile/UserProfile";
import Messages from "./Pages/Messages/Messages";
import AdvancedSearch from "./Pages/AdvancedSearch/AdvancedSearch";
import Login from "./Pages/Login/Login";
import Otp from "./Pages/Otp/Otp";
import VandorSignIn from "./Pages/vandor/VandorSignIn/VandorSignIn";
import VandorPasswordReset from "./Pages/vandor/VandorPasswordReset/VandorPasswordReset";
import VandorSuccess from "./Pages/vandor/VandorSuccess/VandorSuccess";
import VandorForgotPassEmail from "./Pages/vandor/VandorForgotPassEmail/VandorForgotPassEmail";
import VandorOtp from "./Pages/vandor/VandorOtp/VandorOtp";
import BidDetails from "./Pages/vandor/Big Details/BidDetails";
import UserProfileById from "./Pages/UserProfile/UserDetailById";
import ShortList from "./Pages/ShortListProfiles/ShortList";
import BlockList from "./Pages/BlockUsersList/BlockUserList";
import EditPersonalDetail from "./Pages/EditPersonalDetail/EditPersonalDetail";
import EditCareerAndEducation from "./Pages/EditCareerAndEducation/EditCareerAndEducation";
import PremiumPlanDetails from "./Pages/PrimiumPlanDisplay/PremiumPlanDetails";
import { BASE_URL } from "./BASE_URL";
import Requests from "./Pages/Profile2/Requests";
import UserDetailById from "./Pages/UserProfile/UserDetailById";
import Userprofile from "./Pages/ViewedProfile/Userprofile";
import UploadPhoto from "./Pages/UploadPhotoGraph/UploadPhoto";
import MembershipPlan from "./Pages/SelectPlan/MembershipPlan";
import Membershipplandetail from "./Pages/PlanDetails/Membershipplandetail";
import Registration from "./Pages/NewUser/Registration";
import RegisterPersonalDetail from "./Pages/Registration1/RegisterPersonalDetail";
import RegisterQualification from "./Pages/Registration2/RegisterQualification";
import RegisterFamilyDetails from "./Pages/Registration3/RegisterFamilyDetails";
import InsertPartenerPreference from "./Pages/Registration4/InsertPartenerPreference";
import Forgotpassword from "./Pages/forgotpassword/Forgotpassword";
import Health from "./Pages/healthcondition/Health";
import Socialmedia from "./Pages/socialmedia/Socialmedia";
import Inclomplete from "./Pages/incomplete/Incomplete";
import Comingsoon from "./Components/Coming-soon/Comingsoon";
import Plaform from "./Pages/Planform/Plaform";
import Vendorlogin from "./Pages/vendorLogin/Vendorlogin";
import Vendorforgot from "./Pages/vendorforgotpassword/VendorForgot";
import Vendorotp from "./Pages/Vendorotp/Vendorotp";
import Vendornewpassword from "./Pages/Vendornewpassword/Vendornewpassword";
import Vendorhome from './Pages/Vendorhome/Vendorhome';
import Lehenga from "./Pages/Bridallehenga/Lehenga";
import WeddingCards from "./Pages/WeddingCards/WeddingCards";
import VendorInfo from "./Pages/VendorInfo/VendorInfo";
import BanquetHall from "./Pages/BanquetHalls/BanquetHall";
import WeddingPackages from "./Pages/WeddingPackages/WeddingPackages";
import BridalMakeUp from "./Pages/BridalMakeUp/BridalMakeUp";

import { useAuthContext } from "./context/AuthContext";
import EditPersonalDetails2 from "./Pages/EditPersonalDetails2/EditPersonalDetails2";
import Payment from "./Pages/Payment/Payment";
import ChangePassword from "./Pages/ChangePassword/ChangePassword";

function App() {

  const { setAuthUser } = useAuthContext();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  const [name, setName] = useState("");
  const token = localStorage.getItem("saptvidhiUserToken");
  const getUset = async () => {

    try {
      const res = await fetch(
        `${BASE_URL}/api/profile/userdetails`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      const data = await res.json();
      setName(data?.data?.UserDetails?.user_name);
      localStorage.setItem("myId", data?.data?.UserDetails?._id);
      localStorage.setItem("memberType", data?.data?.UserDetails?.member_type);

      setAuthUser({ id: data?.data?.UserDetails._id })
    } catch (error) {
      console.log(error)
    }
  };

  useEffect(() => {
    getUset();
  }, []);


  return (
    <div className="App">
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/comunity-guidelines" element={<Community />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/faqs" element={<Faqs />} />
        <Route path="/contactus" element={<ContactUs />} />
        <Route path="/career" element={<Career />} />
        <Route path="/partner-community" element={<PartnerCommunity />} />
        {/* <Route path="/signup" element={<SignUp />} /> */}
        <Route path="/login" element={<Login />} />
        <Route path="/forgotpassword" element={<Forgotpassword />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/requests" element={<Requests />} />
        <Route path="/phonemessages" element={<MessagesComponent />} />
        <Route path="/messages" element={<Messages />} />
        <Route path="/userprofile/:id" element={<UserDetailById />} />
        <Route path="/change-password" element={<ChangePassword />} />
        {/* <Route path="/viewedprofile" element={<Userprofile />} /> */}
        <Route path="/uploadphotos" element={<UploadPhoto />} />
        <Route path="/selectplan" element={<MembershipPlan />} />
        <Route path="/package-payment/:url?/:packageid?" element={<Plaform />} />
        <Route path="/payment-method" element={<Payment />} />
        <Route path="/healthcondition" element={<Health />} />
        <Route path="/social-media" element={<Socialmedia />} />
        <Route path="/complete-your-profile" element={<Inclomplete />} />
        <Route path="/plandetails/displayplan/:id" element={<Membershipplandetail />} />
        <Route path="/advancedsearch" element={<AdvancedSearch />} />
        <Route path="/newuser" element={<Registration />} />
        <Route path="/Otp" element={<Otp />} />
        <Route path="/personal-deatils" element={<RegisterPersonalDetail />} />
        <Route path="/edit-personal-deatils" element={<EditPersonalDetail />} />
        <Route path="/education-Career-details" element={<RegisterQualification />} />
        <Route path="/edit-education-Career-details" element={<EditCareerAndEducation />} />
        <Route path="/family-detail" element={<RegisterFamilyDetails />} />
        <Route path="/partner-preference" element={<InsertPartenerPreference />} />
        <Route path="/userprofile" element={<UserProfile />} />
        <Route path="/edit-my-information" element={<EditPersonalDetails2 />} />
        <Route path="/our-plan-detail" element={<PremiumPlanDetails />} />
        <Route path="/blocklist" element={<BlockList />} />
        <Route path="/shortlist" element={<ShortList />} />
        <Route path="/vendor-sign-in" element={<Vendorlogin />} />
        <Route path="/vendor-forgot-password" element={<Vendorforgot />} />
        <Route path="/vendor-otp-verification" element={<Vendorotp />} />
        <Route path="/vendor-set-new-password" element={<Vendornewpassword />} />
        <Route path="/bridal-lehenga" element={<Lehenga />} />
        <Route path="/wedding-cards" element={<WeddingCards />} />
        <Route path="/vendor-information" element={<VendorInfo />} />
        <Route path="/vendor-homepage" element={<Vendorhome />} />
        <Route path="/banquet-halls" element={<BanquetHall />} />
        <Route path="/vendor-wedding-packages" element={<WeddingPackages />} />
        <Route path="/vendor-bridal-makeup" element={<BridalMakeUp />} />
        {/* <Route path="/vandor/signin" element={<VandorSignIn />} /> */}
        {/* <Route path="/vandor/passwordreset/e-mail" element={<VandorForgotPassEmail />} /> */}
        {/* <Route path="/vandor/passwordreset/otp" element={<VandorOtp />} /> */}
        {/* <Route path="/vandor/passwordreset" element={<VandorPasswordReset />} /> */}
        {/* <Route path="/vandor/passwordreset/successfull" element={<VandorSuccess />} /> */}
        {/* <Route path="/vandor/bid-details" element={<BidDetails />} /> */}
        {/* <Route path="/" element={<Comingsoon />} /> */}
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
