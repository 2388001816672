import React, { useState, useEffect } from 'react';
import { FileUploadOutlined, Close } from '@mui/icons-material';
import axios from 'axios';
import { BASE_URL } from '../../BASE_URL';
import './UploadPhotograph.css';
import { useNavigate, useLocation } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { doc, collection, updateDoc, getFirestore } from 'firebase/firestore';
import { app } from '../../firebase';

const UploadPhoto = () => {
  const [selectedPhotos, setSelectedPhotos] = useState([]);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const token = localStorage.getItem('token');
  const navigate = useNavigate();
  const location = useLocation();

  const firebaseId = localStorage.getItem("firebase_id");
  const firestore = getFirestore(app);

  const userId = localStorage.getItem("firebase_id");


  const handlePhotoUpload = async () => {

    if (selectedPhotos.length === 0) {
      toast.error('Please Select Image', {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      return;
    }


    try {
      const formData = new FormData();

      formData.append('firebase_id', userId);


      selectedPhotos.forEach((photo) => {
        formData.append('user_photo', photo);
      });
      const response = await axios.put(`${BASE_URL}/api/profile`, formData, {
        headers: {
          Authorization: token,
          'Content-Type': 'multipart/form-data',
        },
      });

      toast.success('Profile updated successfully!', {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });

      setTimeout(() => {
        const hyy = localStorage.getItem('multiplephoto');
        if (hyy === 'profile') {
          navigate('/userprofile');
        } else {
          navigate('/family-detail');
        }
      }, 2000);
    } catch (error) {
    }
  };


  const handleSkip = async () => {


    const formData = new FormData();

    formData.append('firebase_id', userId);


    selectedPhotos.forEach((photo) => {
      formData.append('user_photo', photo);
    });
    const response = await axios.put(`${BASE_URL}/api/profile`, formData, {
      headers: {
        Authorization: token,
        'Content-Type': 'multipart/form-data',
      },
    });

    toast.success('Profile updated successfully!', {
      position: "bottom-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });


    const hyy = localStorage.getItem('multiplephoto');
    if (hyy === 'profile') {
      navigate('/userprofile');
    } else {
      navigate('/profile');
    }
  }

  useEffect(() => {
    if (showSuccessMessage) {
      const timer = setTimeout(() => {
        setShowSuccessMessage(false);
      }, 2000); // 2 seconds
      return () => clearTimeout(timer);
    }
  }, [showSuccessMessage]);

  const handleFileChange = (event) => {
    const { files } = event.target;
    const uploadedPhotos = Array.from(files);
    setSelectedPhotos(uploadedPhotos);
  };

  const handleRemovePhoto = (index) => {
    const updatedPhotos = [...selectedPhotos];
    updatedPhotos.splice(index, 1);
    setSelectedPhotos(updatedPhotos);
  };

  return (
    <div className="upload_photographs">
      <h2>Upload Your Photos</h2>
      <div className="row w-100">
        <div className="col-md-6 col-sm-4 col-12">
          <div className="upload_photographs__upload_photo">
            <FileUploadOutlined />
            <p>Upload your photographs here</p>
            <div className="signup__upload_button__wrapper upload-photo-margin">
              <input
                type="file"
                multiple
                onChange={handleFileChange}
                accept=".png, .jpg, .jpeg"
                className="photos"
              />
              <p>Supported files: png, jpg, jpeg</p>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-sm-7 col-12">
          <div className="upload_photographs_selected_photos">
            {selectedPhotos.map((photo, index) => (
              <div
                className="upload_photographs_uploaded_image_wrapper"
                key={index}
              >
                <img
                  src={URL.createObjectURL(photo)}
                  alt=""
                  className="upload_photographs_uploaded_image"
                />
                <Close
                  className="upload_photographs_uploaded_image_close_icon"
                  onClick={() => handleRemovePhoto(index)}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
      <button className="upload_photographs_save" onClick={handlePhotoUpload}>
        Save
      </button>
      <p><a onClick={handleSkip} style={{ color: "grey", cursor: "pointer" }}>Skip</a></p>
      <ToastContainer />
    </div>
  );
};

export default UploadPhoto;