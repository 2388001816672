import React, { useState, useEffect, useRef } from 'react';
import { BASE_URL } from '../../BASE_URL';
import './onlineuser.css';
import axios from 'axios';
import { Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import { useNavigate } from 'react-router-dom';


function OnlineUser(onlineUserS) {

    const navigate = useNavigate();

    const [online, setOnline] = useState("");


    useEffect(() => {
        fetchAcceptedInvitations();
    }, []);

    const fetchAcceptedInvitations = async () => {
        try {
            const response = await axios.get(
                `${BASE_URL}/api/profile/dashboard_app`,
                {
                    headers: {
                        Authorization: localStorage.getItem("saptvidhiUserToken"),
                    },
                }
            );
            setOnline(response?.data?.data?.online_users);
        } catch (error) {
        }
    };

    const count = online.length;


    // const handleProfile = (profileId) => {
    //     navigate(`/userprofile/${profileId}`)
    // }


    return (
        <>

            <div className='online_user_list'>
                <h3 className='mb-3' style={{
                    fontWeight: "600",
                    fontSize: "20px"
                }}>
                    Online Users <span style={{ color: "#cf166f" }}>({onlineUserS.onlineUserS.length})</span>
                </h3>
                <div className='online_list_overflow'>
                    <Swiper
                        slidesPerView={11}
                        spaceBetween={30}
                        className="mySwiper"
                    >
                        {onlineUserS && onlineUserS.onlineUserS.map((e) => {
                            return (
                                <>
                                    <SwiperSlide>
                                        <div className="online_box text-center align-items-center" disable>
                                            <img
                                                src={e.profile_photo}
                                                alt={e.name}
                                                className='online-user-profile-slider'
                                                // onClick={() => handleProfile(e.profile_id)}
                                                onClick={() => navigate('/messages', { state: { data: e } })}
                                            />
                                            <p className="mb-0 ms-2">{e.user_name.slice(0, 8)}</p>
                                        </div>
                                    </SwiperSlide>
                                </>
                            )
                        })
                        }
                    </Swiper>
                </div>
            </div>
        </>
    )
}

export default OnlineUser;