import React from 'react';
import Modal from "react-bootstrap/Modal";
import { useState, useEffect } from 'react';
import { BASE_URL } from '../../BASE_URL';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';

function Editfamilyother({ onProfileUpdate }) {

    const navigate = useNavigate()

    const [show3, setShow3] = useState(false);
    const handleClose3 = () => setShow3(false);
    const handleShow = () => setShow3(true);


    const [editedFamilyStatus, setEditedFamilyStatus] = useState("");
    const [editedFamilyType, setEditedFamilyType] = useState("");
    const [editedFamilyValue, setEditedFamilyValue] = useState("");
    const [editedLivingFamily, setEditedLivingFamily] = useState("");
    const [editedFamilyIncome, setEditedFamilyIncome] = useState("");


    const [otherInfo, setOtherInfo] = useState("")
    const [dataLoaded, setDataLoaded] = useState(false);


    const contactNoFromLocalStorage = localStorage.getItem("contact_no");
    const formattedContactnum = localStorage.getItem("contactnumber");


    let contactNo;

    if (contactNoFromLocalStorage) {
        contactNo = contactNoFromLocalStorage;
    } else {
        contactNo = "+91" + formattedContactnum;
    }



    const userDetails = async () => {
        const token = localStorage.getItem("saptvidhiUserToken");
        const res = await fetch(
            `${BASE_URL}/api/profile/checkProfile`,
            {
                method: "POST",
                headers: {
                    Authorization: token,
                    "Content-Type": "application/json",
                },
            }
        );
        const data = await res.json();

        const userOtherInfo = data.data.user[0].user_other_infos;

        if (userOtherInfo) {
            setOtherInfo(userOtherInfo);

            if (userOtherInfo.family_status) {
                setEditedFamilyStatus(userOtherInfo.family_status);
            }

            if (userOtherInfo.family_values) {
                setEditedFamilyValue(userOtherInfo.family_values);
            }

            if (userOtherInfo.living_with_parents !== undefined) {
                setEditedLivingFamily(userOtherInfo.living_with_parents);
            }

            if (userOtherInfo.family_income !== undefined) {
                setEditedFamilyIncome(userOtherInfo.family_income);
            }

            if (userOtherInfo.family_type) {
                setEditedFamilyType(userOtherInfo.family_type);
            }
        }
    };

    useEffect(() => {
        userDetails();
    }, []);

    const family_status = ["Rich/Affluent", "Upper Middle class", "Middle class"]
    const family_type = ["Joint family", "Nuclear family", "Other"]
    const family_value = ["Orthodox", "Conservative", "Moderate", "Liberal"]
    const living_parent = ["No", "Yes", "Not", "Not Applicable"]
    const family_income = ["0-1 LPA", "1-2 LPA", "2-5 LPA", "5-10 LPA", "10-15 LPA", "15-20 LPA", "20-25 LPA", "25-30 LPA", "30-35 LPA", "35-40 LPA", "45-50 LPA"]






    const handleSubmit3 = async () => {
        try {
            const token = localStorage.getItem('token');

            // Compare edited values with original values
            const changedFields = {};

            if (editedFamilyStatus !== otherInfo?.family_status) {
                changedFields.family_status = editedFamilyStatus;
            }

            if (editedFamilyType !== otherInfo?.family_type) {
                changedFields.family_type = editedFamilyType;
            }

            if (editedFamilyValue !== otherInfo?.family_values) {
                changedFields.family_values = editedFamilyValue;
            }

            if (editedLivingFamily !== otherInfo?.living_with_parents) {
                changedFields.living_with_parents = editedLivingFamily;
            }

            if (editedFamilyIncome !== otherInfo?.family_income) {
                changedFields.family_income = editedFamilyIncome;
            }

            const res1 = await fetch(`${BASE_URL}/api/user_other_info`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: token,
                },
                body: JSON.stringify(changedFields),
            });

            onProfileUpdate();

            if (!res1.ok) {
                toast.error('Failed to submit family information.', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 1000,
                });
                return;
            }
            userDetails();
            toast.success('Family information submitted successfully.', {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 1000,
            });
            handleClose3();

        } catch (error) {
            toast.error("An unexpected error occurred");
        }
    };


    const handleEditFamily = () => {
        navigate("/edit-my-information", { state: "family" })
    }


    return (
        <>

            <Modal show={show3} onHide={handleClose3} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Family Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="class_for_label row mb-4 mx-2">
                        <div className="col-12 mb-3 mt-2">
                            <h5 >Family Status : </h5>
                            <div className="row">
                                {family_status.map((item) => (
                                    <div className="col-sm-6 col-12 health_checkbox" key={item}>
                                        <input
                                            type="radio"
                                            id={item}
                                            name="assets"
                                            value={item}
                                            onChange={() => setEditedFamilyStatus(item)}
                                            checked={editedFamilyStatus === item}
                                        />
                                        <label htmlFor={item}>{item}</label>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="col-12 mb-3">
                            <h5>Family Type : </h5>
                            <div className="row">
                                {family_type.map((item) => (
                                    <div className="col-sm-6 col-12 health_checkbox" key={item}>
                                        <input
                                            type="radio"
                                            id={item}
                                            name="why"
                                            value={item}
                                            onChange={() => setEditedFamilyType(item)}
                                            checked={editedFamilyType === item}
                                        />
                                        <label htmlFor={item}>{item}</label>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="col-12 mb-3">
                            <h5>Family Value : </h5>
                            <div className="row">
                                {family_value.map((item) => (
                                    <div className="col-sm-6 col-12 health_checkbox" key={item}>
                                        <input
                                            type="radio"
                                            id={item}
                                            name="so"
                                            value={item}
                                            onChange={() => setEditedFamilyValue(item)}
                                            checked={editedFamilyValue === item}
                                        />
                                        <label htmlFor={item}>{item}</label>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="mb-3">
                            <h5>Are you Living With Parents : </h5>
                            <div className="row">
                                {living_parent.map((item) => (
                                    <div className="col-sm-6 col-12 health_checkbox" key={item}>
                                        <input
                                            type="radio"
                                            id={item}
                                            name="ser"
                                            value={item}
                                            onChange={() => setEditedLivingFamily(item)}
                                            checked={editedLivingFamily === item}
                                        />
                                        <label htmlFor={item}>{item}</label>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="">
                            <h5>Family Income : </h5>
                            <div className="row">
                                {family_income.map((item) => (
                                    <div className="col-sm-6 col-12 health_checkbox" key={item}>
                                        <input
                                            type="radio"
                                            id={item}
                                            name="ahh"
                                            value={item}
                                            onChange={() => setEditedFamilyIncome(item)}
                                            checked={editedFamilyIncome === item}
                                        />
                                        <label htmlFor={item}>{item}</label>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className="footer-btn-modal">
                    <button onClick={handleClose3}>Close</button>
                    <button onClick={handleSubmit3}>Submit</button>
                </Modal.Footer>
            </Modal>
            <div className="userprofile_family_details pb-4 ps-3 pt-3">
                <div className="userprofile_section_header">
                    <h3>Family Background</h3>
                    <button className="edit_btn_profile" onClick={handleEditFamily} style={{ cursor: 'pointer' }} >
                        Edit
                    </button>
                </div>
                <div className="userprofile_family_details_content_wrapper ps-3">
                    <div className="userprofile_family_details_content">
                        <div>
                            <span>Family Income</span>
                            <p>{otherInfo.family_income}</p>
                        </div>
                    </div>
                    <div className="userprofile_family_details_content">
                        <div>
                            <span>Family Status</span>
                            <p>{otherInfo?.family_status}</p>
                        </div>
                    </div>
                    <div className="userprofile_family_details_content">
                        <div>
                            <span>Family Type</span>
                            <p>{otherInfo?.family_type}</p>
                        </div>
                    </div>
                    <div className="userprofile_family_details_content">
                        <div>
                            <span>Family Values</span>
                            <p>{otherInfo.family_values}</p>
                        </div>
                    </div>
                    <div className="userprofile_family_details_content">
                        <div
                            className="userprofile_basic_details_content"
                            style={{ fontWeight: "600" }}
                        >
                            <h6 style={{ fontWeight: "600" }}>Living With Family ? : <p style={{ fontWeight: "400" }}>{otherInfo && otherInfo.living_with_parents}</p></h6>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Editfamilyother;