import React from 'react';
import "./vendorpackage.css"

const VendorPackage = () => {
  return (
    <>
      <div className="container">
        <div>
          <div className='vendor-packages-border'>
            <div>
              <h5>Membership Packages</h5>
              <hr />
            </div>
            <div className='packages-grid'>
              <div className='first-package-vendor'>
                <div className='text-center'>
                  <h3 className='vendor-package-title'>Starter</h3>
                  <p className='vendor-package-detail my-4'>₹97000/ Yearly +18% <br /> taxes extra</p>
                  <div className='vendor-package-select-btn mt-4'>
                    <button className=''>Select</button>
                  </div>
                  <hr />
                  <p>Estimated leads yearly 113-188</p>
                  <hr />
                  <p>Estimated leads yearly 113-188</p>
                  <hr />
                  <p>Estimated leads yearly 113-188</p>
                  <hr />
                  <p>Estimated leads yearly 113-188</p>
                  <hr />
                  <p>Estimated leads yearly 113-188</p>
                  <hr />
                  <p>Estimated leads yearly 113-188</p>
                  <hr />
                  <p>Estimated leads yearly 113-188</p>
                  <hr />
                </div>
              </div>
              <div className='first-package-vendor'>
                <div className='text-center'>
                  <h3 className='vendor-package-title'>Starter</h3>
                  <p className='vendor-package-detail my-4'>₹97000/ Yearly +18% <br /> taxes extra</p>
                  <div className='vendor-package-select-btn mt-4'>
                    <button className=''>Select</button>
                  </div>
                  <hr />
                  <p>Estimated leads yearly 113-188</p>
                  <hr />
                  <p>Estimated leads yearly 113-188</p>
                  <hr />
                  <p>Estimated leads yearly 113-188</p>
                  <hr />
                  <p>Estimated leads yearly 113-188</p>
                  <hr />
                  <p>Estimated leads yearly 113-188</p>
                  <hr />
                  <p>Estimated leads yearly 113-188</p>
                  <hr />
                  <p>Estimated leads yearly 113-188</p>
                  <hr />
                </div>
              </div>
              <div className='first-package-vendor'>
                <div className='text-center'>
                  <h3 className='vendor-package-title'>Starter</h3>
                  <p className='vendor-package-detail my-4'>₹97000/ Yearly +18% <br /> taxes extra</p>
                  <div className='vendor-package-select-btn mt-4'>
                    <button className=''>Select</button>
                  </div>
                  <hr />
                  <p>Estimated leads yearly 113-188</p>
                  <hr />
                  <p>Estimated leads yearly 113-188</p>
                  <hr />
                  <p>Estimated leads yearly 113-188</p>
                  <hr />
                  <p>Estimated leads yearly 113-188</p>
                  <hr />
                  <p>Estimated leads yearly 113-188</p>
                  <hr />
                  <p>Estimated leads yearly 113-188</p>
                  <hr />
                  <p>Estimated leads yearly 113-188</p>
                  <hr />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default VendorPackage
