import React, { useEffect, useState } from "react";
import "./ProfileDetails.css";
import img1 from "../../../Assets/signup/img1.png";
import EditIcon from "@mui/icons-material/Edit";
import googleAds from "../../../Assets/googleads.png";
import { Link, useNavigate } from "react-router-dom";
import { BASE_URL } from "../../../BASE_URL";
import crown from "./king.png"
import ring from "./weddingring.png";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import axios from "axios";
import Circle from "./Circle";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightLong } from '@fortawesome/free-solid-svg-icons';
import Circle2 from "./Circle2";

const ProfileDetails = ({ handleAddShow }) => {
  const [user_id, setUser_Id] = useState("");
  const [profile__photo, setProfile_Photo] = useState("");
  const [profileData, setProfileData] = useState("");
  const [name, setName] = useState("");
  const [status, setStatus] = useState("");
  const [memberType, setMemberType] = useState("");
  const [receivedData, setReceivedData] = useState([]);
  const [recentVisitorsData, setRecentVisitorsData] = useState([]);
  const [dataLengthVisitors, setDataLengthVisitors] = useState("");
  const [dataLengthInvitations, setDataLengthInvitations] = useState("");
  const [recentVisitorCount, setRecentVisitorsCount] = useState("");
  const [slider, setSlider] = useState("")
  const [dataLengthAcceptedInvitations, setDataLengthAcceptedInvitations] =
    useState("");
  const [invitationsData, setInvitationsData] = useState([]);
  const token = localStorage.getItem("saptvidhiUserToken");
  const [banner, setBanner] = useState("");
  const [percentage, setPercentage] = useState("");

  useEffect(() => {
    fetchUser();
    fetchInvitations();
    fetchRecentVisitors();
    fetchAcceptedInvitations();
    userDetails();
  }, []);


  const fetchAcceptedInvitations = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/api/profile/dashboard_app`,
        {
          headers: {
            Authorization: localStorage.getItem("saptvidhiUserToken"),
          },
        }
      );
      const InvitationsData = response.data.data.receive_request;
      setBanner(response?.data?.data?.banner?.[0]?.photo?.[0]);
      setSlider(response?.data?.data?.slider?.[0]?.photo)
      setRecentVisitorsCount(response?.data?.data?.recent_visitors_count)
      setInvitationsData(InvitationsData);
      const DataLengthPendingInvitations =
        response.data.data.receive_request.length;
      setDataLengthInvitations(response?.data?.data?.receive_request_count);
      setDataLengthAcceptedInvitations(response?.data?.data?.premium_matches_count);
    } catch (error) {
      console.log("Failed !!", error);
    }
  };

  const fetchInvitations = async () => {
    try {
      const response = await fetch(`${BASE_URL}/api/request/confirm`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("saptvidhiUserToken"),
        },
      });

      if (!response.ok) {
        throw new Error("Request failed");
      }

      const responseData = await response.json();

      setReceivedData(responseData.data);

      const inivitationsDataLength = responseData.data.length;
    } catch (error) {
    }
  };

  const fetchRecentVisitors = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/api/profile/dashboard_app`,
        {
          headers: {
            Authorization: localStorage.getItem("saptvidhiUserToken"),
          },
        }
      );

      const recentVisitorsData = response.data.data.recent_visitors;
      setRecentVisitorsData(recentVisitorsData);
      const DataLengthVisitors = response.data.data.recent_visitors.length;
      setDataLengthVisitors(response?.data?.data?.recent_visitors_count);
    } catch (error) {
      console.log("Failed !!", error);
    }
  };

  const fetchUser = async () => {
    try {
      const r = await fetch(`${BASE_URL}/api/profile/userdetails`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      });

      if (!r.ok) {
        throw new Error("Request failed");
      }

      const data = await r.json();
      const my_data = data.data.UserDetails;
      setProfileData(my_data);

      const userName = data?.data?.UserDetails?.user_name || "";
      const user_id = data?.data?.UserDetails[0]?._id;
      localStorage.setItem("userId", my_data._id);
      setUser_Id(user_id);
      setName(userName);

      const profilePhoto = data?.data?.UserDetails?.profile_photo;

      setProfile_Photo(profilePhoto);
      const status = data?.data?.UserDetails?.marital_status;
      setStatus(status);

      const memberType = data?.data?.UserDetails?.member_type;
      setMemberType(memberType);
    } catch (error) {
      console.error(error);
    }
  };


  const navigate = useNavigate();
  ;

  let memtyp = memberType.charAt(0).toUpperCase() + memberType.slice(1).toLocaleLowerCase();

  const settings = {
    dots: true,
    // infinite: true,
    speed: 11100,
    slidesToShow: 1, // Display one image at a time
    slidesToScroll: 1,
    autoplay: false, 
    autoplaySpeed: 2000, // Set the autoplay interval in milliseconds (e.g., 3 seconds)
  };


  const contactNoFromLocalStorage = localStorage.getItem("contact_no");
  const formattedContactnum = localStorage.getItem("contactnumber");

  let contactNo;

  if (contactNoFromLocalStorage) {
    contactNo = contactNoFromLocalStorage;
  } else {
    contactNo = "+91" + formattedContactnum;
  }


  const userDetails = async () => {
    const token = localStorage.getItem("saptvidhiUserToken");
    const res = await fetch(
      `${BASE_URL}/api/profile/checkProfile`,
      {
        method: "POST",
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    );
    const data = await res.json();
    setPercentage(data.data.profile_percentage
    )

  };

  const handlePlans = () => {
    navigate("/selectplan")
  }

  const handlePlansDetails = () => {
    navigate("/our-plan-detail")
  }

  const handleUserProfile = () => {
    navigate("/userprofile")
  }



  return (
    <div className="profile__details mb-sm-5 mb-4 ">
      <div>
        <div className="profile__card">
          <div className="profile__card_user">
            <div className="d-flex" style={{ gap: "10px" }}>
              <img
                src={profile__photo}
                alt=""
                style={{ min: "60px", height: "60px", borderRadius: "50%" }}
                className="profile_photo_first_profile"
              />
              <div className="profile__card_name">
                <h3>{name}</h3>
                <p>{profileData.contact_no}</p>
              </div>
            </div>
            <a onClick={handleUserProfile} style={{ color: "black" }}>
              <button className="edit_btn_profile">EDIT</button>
            </a>
          </div>
          <div className="profile__card_status">
            <p className="profile__card_status_status d-flex align-items-center flex-column">
              <img src={ring} alt="" style={{ height: "25px", width: "25px" }} />
              {status}
            </p>
            <p
              className="profile__card_status_value"
              style={{ color: "#CF166F", display: "flex", alignItems: "center", flexDirection: "column" }}
            >
              <img src={crown} alt="" style={{ height: "25px", width: "25px" }} />{memtyp} User
            </p>
          </div>
          {memberType === "paid" ? (
            <button className="profile__get_premium btn_get_premium" onClick={handlePlansDetails}>
              <a style={{ textDecoration: "none", color: "white" }}>Package detail</a>
            </button>
          ) : (
            <button className="profile__get_premium btn_get_premium" onClick={handlePlans}>
              <a style={{ textDecoration: "none", color: "white" }}>Get Premium</a>
            </button>
          )}
        </div>
        <div className="profile__counts">
          <div className="profile__counts_card profile__counts_card1">
            <h3 className="">{dataLengthInvitations}</h3>
            <p>Receive Request</p>
          </div>
          <div className="profile__counts_card profile_card_background profile__counts_card2">
            <h3>
              {dataLengthAcceptedInvitations
                ? dataLengthAcceptedInvitations
                : 0}
            </h3>
            <p>Premium Matches</p>
          </div>
          <div className="profile__counts_card profile_card_background profile__counts_card2">
            <h3>{recentVisitorCount}</h3>
            <p>Recent Visitors</p>
          </div>
        </div>
      </div>


      <div className="backend-image-slider-container-main" >
        <div className="backend-image-slider-container" >
          <Slider {...settings} style={{ borderRadius: "10px" }} className="slider-main-width">
            {slider && slider.map((e, index) => (
              <div key={index} className="slide-item">
                <img src={e} alt={`Slide ${index + 1}`} className="backend-image-slider" />
              </div>
            ))}
          </Slider>
        </div>
        <div className="profile_circle_main" >
          {percentage && parseInt(percentage) >= 100 ? (
            <div style={{ textAlign: "center", display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}>
              <p className="mb-0" style={{ fontSize: "20px", color: "#cf166f" }}>Your profile is completed!</p>
            </div>
          ) : (
            <>
              <Circle2 percentage={percentage} />
              <div className="complete_profile_btn d-flex flex-column justify-content-center">
                <h6 style={{ color: "black" }}>Add a few more details to make your <br /> profile rich!</h6>
                <h6 onClick={handleAddShow} style={{ cursor: "pointer" }}>Complete Your Profile <FontAwesomeIcon icon={faArrowRightLong} style={{ marginLeft: "5px" }} /> </h6>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProfileDetails;
