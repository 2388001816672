import React, { useEffect } from "react";
import "./SentRequestsCard.css";
import img1 from "../../../Assets/profile2/img1.jpg";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import LanguageIcon from "@mui/icons-material/Language";
import PlaceIcon from "@mui/icons-material/Place";
import SchoolIcon from "@mui/icons-material/School";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import PersonIcon from "@mui/icons-material/Person";
import BoltIcon from "@mui/icons-material/Bolt";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import HeightIcon from "@mui/icons-material/Height";
import TempleHinduIcon from "@mui/icons-material/TempleHindu";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCrown } from '@fortawesome/free-solid-svg-icons';
// import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import ChatIcon from "@mui/icons-material/Chat";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { useNavigate } from "react-router-dom";
import RemoveDoneIcon from "@mui/icons-material/RemoveDone";
import PhoneIcon from "@mui/icons-material/Phone";
import crown from "../crown.png"
import { BASE_URL } from "../../../BASE_URL";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useState } from "react";
import Modal from 'react-bootstrap/Modal';
import axios from "axios";

const ReceivedRequestCard = ({ data, fetchData }) => {
  const navigate = useNavigate();
  const declineRequest = async () => {
    try {
      const response = await fetch(
        `${BASE_URL}/api/request?_id=${data._id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("saptvidhiUserToken"),
          },
          body: JSON.stringify({
            // receiver_id: data._id,
            request_status: "cancel",
            request_type: "request",
          }),
        }
      );
      if (response.status == 200) {
        fetchData();
        // setIsRequestSent(true);
        toast.success(`${data.profile[0].user_name} cancel successfully!`, {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 1000,
        });
      }
    } catch (err) {
      alert(err);
    }
  };


  const [premiuimMember , setPremiumMember] = useState("")

  useEffect(() => {
    setPremiumMember(localStorage.getItem("memberType"))
  }, []);

  const member_type = localStorage.getItem("memberType");

  const [seenContactDetails, setSeenContactDetails] = useState();
  const [messageSeen, setMessageSeen] = useState("")
  const [showSecondModal, setShowSecondModal] = useState(false);


  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  const handleClose1 = () => {
    // Close the first modal
    // setShowFirstModal(false);
    // Open the second modal
    setShowSecondModal(false);
  };

  const handleShow1 = async () => {
    setShow(false);

    if (member_type != "paid") {
      navigate("/SelectPlan")
      return;
    }

    setShowSecondModal(true);

    try {
      const token = localStorage.getItem("saptvidhiUserToken");
      const response = await axios.post(
        `${BASE_URL}/api/count_of_contact_details`,
        { profile_id: data?.profile?.[0]?._id },
        {
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.data.code === 200) {
        // alert("block successfully");
        // setIsModalOpen(false);
        setSeenContactDetails(response.data.data);
        // setShowContactDetails(true);
      } else {
        toast.error('Your date limit data over', {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 2000, // Display for 3 seconds
        });
      }
    } catch (err) {
      // Handle errors here
    }
  };

  const handlePaidMember = () => {
    setShow(true);
    if (member_type === "paid") {
      setMessageSeen("Are you sure you want to see the contact of this user?")
    } else (
      // navigate("/selectplan")
      setMessageSeen("Are you want to see plans?")
    )
  }



  return (
    <div className="received_request_card mb-sm-0 mb-3">
      <div className="received_request_card_left" style={{ position: "relative" }}>
        {data?.profile?.[0]?.member_type === "paid" && (
          <img
            src={crown}
            className="premium-user-crown"
            alt=""
          />
        )}
        <div className="left_intro_group">
          <img
            src={data && data.profile[0].profile_photo}
            alt=""
            style={{ cursor: "pointer" }}
            onClick={() => {
              navigate(`/userprofile/${data?.profile?.[0]?._id}`, {
                state: { status: "sent", id: data._id },
              });
            }}
          />
          <div className="received_request_card_name">
            <PersonIcon sx={{ height: "15px" }} />
            {data && data.profile[0].user_name}
          </div>
        </div>
      </div>
      <div className="received_request_card_right">
        <div
          className="card_right_top"
          style={{ cursor: "pointer" }}
          onClick={() => {
            navigate(`/userprofile/${data?.profile?.[0]?._id}`, {
              state: { status: "sent", id: data._id },
            });
          }}
        >
          <p>
            <TrendingUpIcon sx={{ height: "15px" }} />
            {data && data.profile[0].age} yrs, {data && data.profile[0].height}
          </p>
          <p>
            <LanguageIcon sx={{ height: "15px" }} />
            {data && data.profile[0].mother_tongue}

          </p>
          <p>
            <PlaceIcon sx={{ height: "15px" }} />
            {data && data.profile[0].home_town}, {data && data.profile[0].state}
          </p>
          <p>
            <TempleHinduIcon sx={{ height: "15px" }} />
            {data && data.profile[0].religion} , {data && data.profile[0].community}
          </p>
          <p>
            <WorkOutlineIcon sx={{ height: "15px" }} />
            {data && data.profile[0].highest_qualification}
          </p>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <div style={{ marginLeft: "15px", textAlign: "center" }}>
            <PhoneIcon
              sx={{ height: "25px", cursor: "pointer" }}
              onClick={handlePaidMember}
            />
            <p style={{ fontWeight: "400", fontSize: "10px" }}>Contact</p>
          </div>

          <Modal show={show} onHide={handleClose}>
            <Modal.Body style={{ paddingTop: "40px", textAlign: "center" }}>{messageSeen}</Modal.Body>
            <Modal.Footer style={{ borderTop: "none", display: "flex", justifyContent: "center", width: "100%" }}>
              <button variant="secondary" onClick={handleClose} style={{
                border: "none",
                width: "90px",
                padding: "7px 10px",
                color: "white",
                borderRadius: "10px",
                background: "linear-gradient(rgb(207, 22, 111) 0%, rgba(253, 7, 7, 0.5) 100%)"
              }}>
                No
              </button>
              <button variant="primary" onClick={handleShow1} style={{
                border: "none",
                width: "90px",
                padding: "7px 10px",
                color: "white",
                borderRadius: "10px",
                background: "linear-gradient(rgb(207, 22, 111) 0%, rgba(253, 7, 7, 0.5) 100%)"
              }}>
                Yes
              </button>
            </Modal.Footer>
          </Modal>


          <Modal show={showSecondModal} onHide={handleClose1}>
            <Modal.Body style={{ paddingTop: "40px" }} >
              <div>
                <div className="d-flex align-items-center justify-content-center">
                  <div >
                    <img src={data && data?.profile?.[0]?.profile_photo} alt="" style={{ borderRadius: "50%", height: "60px", width: "60px", marginRight: "20px" }} />
                  </div>
                  <div>
                    <div className="">
                      Phone number : {seenContactDetails?.[0]?.contact_no}
                    </div>
                    <div className="">
                      Email : {seenContactDetails?.[0]?.email}
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer style={{ borderTop: "none", display: "flex", justifyContent: "center", width: "100%" }}>
              <button variant="primary" onClick={handleClose1} style={{
                border: "none",
                width: "90px",
                padding: "7px 10px",
                color: "white",
                borderRadius: "10px",
                background: "linear-gradient(rgb(207, 22, 111) 0%, rgba(253, 7, 7, 0.5) 100%)"
              }}>
                Close
              </button>
            </Modal.Footer>
          </Modal>

          <div style={{ marginLeft: "20px", textAlign: "center" }}>
            <CancelOutlinedIcon
              sx={{ height: "25px", cursor: "pointer" }}
              onClick={declineRequest}
            />
            <p style={{ fontWeight: "400", fontSize: "10px" }}>Cancel</p>
          </div>

          {premiuimMember === "paid" ? (
            <>

            </>
          ) : (
            <>
              <div style={{ marginLeft: "15px", textAlign: "center" }}>
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate("/SelectPlan")}
                >
                  {" "}
                  <FontAwesomeIcon icon={faCrown} style={{ fontSize: "20px" }} />
                  <p style={{ fontWeight: "400", fontSize: "10px" }} >Upgrade Now</p>
                </span>
              </div>
            </>
          )}

          <ToastContainer />
        </div>
      </div>
    </div>
  );
};

export default ReceivedRequestCard;
