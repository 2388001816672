import { initializeApp } from "firebase/app";
import { Firestore, getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";


const firebaseConfig = {
  apiKey: "AIzaSyBnhuhWsy59n_f7LENWXeJBrULxwEuUpmA",
  authDomain: "saptavidhi-5c25a.firebaseapp.com",
  projectId: "saptavidhi-5c25a",
  storageBucket: "saptavidhi-5c25a.appspot.com",
  messagingSenderId: "23063497808",
  appId: "1:23063497808:web:33553b27e493b06697d555",
  measurementId: "G-4B4QCWN8RE"
};



export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);