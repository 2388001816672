import React from 'react'
import Download from '../../Components/Download/Download'
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./vendorlogin.css";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BASE_URL } from "../../BASE_URL";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

const Vendorlogin = () => {
    const [dell, setDell] = useState({
        contact_no: "",
        password: "",
    });
    const navigate = useNavigate();

    const [showPassword, setShowPassword] = useState(false);

    const [notifications, setNotifications] = useState([]);


    const txt = (e) => {
        const { name, value } = e.target;
        setDell({ ...dell, [name]: value });
    };

    const handleSubmit = async () => {
        handleLogin();
    };

    const handleLogin = async () => {
        const { contact_no, password } = dell;
        const formattedContactNo = "+91" + contact_no;

        if (!contact_no && !password) {
            toast.error('Please Enter Your Registered Mobile Number & Password!', {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 1000,
            });
            return;
        }

        if (!contact_no) {
            toast.error('Please Enter Register Mobile Number!', {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 1000,
            });
            return;
        }

        if (formattedContactNo.length !== 13) {
            toast.error('Please Enter 10 Digit Contact No!', {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 1000,
            });
            return;
        }


        if (!password) {
            toast.error('Please Enter Your Password!', {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 1000,
            });
            return;
        }


        const res = await fetch(`${BASE_URL}/api/profile/login`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                contact_no: formattedContactNo,
                password,
            }),
        });

        const abc = await res.json();

        if (abc.code === 200) {
            navigate("/profile");
            localStorage.setItem("user", JSON.stringify(abc.data));
            localStorage.setItem("token", abc.data.token);
            localStorage.setItem("preference-detail", "preference");
            localStorage.setItem("family-detail", "family");
            localStorage.setItem("fillup", "fill the data");
            localStorage.setItem("contact_no", formattedContactNo);
            localStorage.setItem("firebase_id", abc.data.Fields.firebase_id);
            const currentDateAndTime = new Date().toLocaleDateString();
            localStorage.setItem("loginDateTime", currentDateAndTime);
        } else {
            toast.error('Please Enter Registered Mobile Number and Password!', {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 1000,
            });
        }
    };


    useEffect(() => {
        const storedToken = localStorage.getItem("saptvidhiUserToken");
        if (storedToken) {
            navigate("/profile");
        }
    }, [navigate]);
    return (
        <>
            <div className='d-flex justify-content-center'>
                <div className="vendor_login__wrapepr">
                    <div className="vendor_login_only">
                        <div className='d-flex justify-content-center my-5'>
                            <input
                                placeholder="Mobile Number"
                                type="tel"
                                onInput={(e) => (e.target.value) = e.target.value.replace(/[^0-9]/g, '')}
                                maxLength="10"
                                onChange={txt}
                                name="contact_no"
                                style={{ width: "70%" }}
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter') {
                                        handleLogin();
                                    }
                                }}
                            />
                        </div>
                        <div className="password-input-container w-100 d-flex justify-content-center eye-password-relative mb-5"> {/* Container to include eye icon */}
                            <input
                                type={showPassword ? "text" : "password"} // Toggle input type based on showPassword state
                                placeholder="Enter Password"
                                style={{ width: "70%" }}
                                onChange={txt}
                                name="password"
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter') {
                                        handleLogin();
                                    }
                                }}
                            />

                            <FontAwesomeIcon // Eye icon to toggle password visibility
                                icon={showPassword ? faEyeSlash : faEye}
                                className="eye-password"
                                onClick={() => setShowPassword(!showPassword)}
                            />
                        </div>
                        <div id="recaptcha"></div>
                        <ToastContainer />
                        <div className='d-flex vendor-login-btn flex-column'>
                            <button onClick={() => navigate('/vendor-homepage')}>Login</button>
                            <p className='text-center mt-3' style={{cursor: "pointer"}}>Forget password?</p>
                            <p className='text-center'>Didn't have an account? <span>SignUp</span></p>
                        </div>
                    </div>
                </div>
            </div>
            <Download />
        </>
    )
}

export default Vendorlogin
