import {
  ArrowUpward,
  BarChart,
  CalendarMonth,
  CastForEducationOutlined,
  FemaleOutlined,
  GirlOutlined,
  Group,
  GroupOutlined,
  Language,
  LocationCityOutlined,
  Lock,
  LockOutlined,
  Mail,
  MailOutline,
  Person,
  Person2Outlined,
  Person3Outlined,
  PersonOutline,
  Phone,
  PhoneOutlined,
  SchoolOutlined,
} from "@mui/icons-material";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import MilitaryTechIcon from "@mui/icons-material/MilitaryTech";
import PeopleIcon from "@mui/icons-material/People";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import img1 from "../../Assets/userprofile/img1.png";
import img2 from "../../Assets/userprofile/img2.png";
import mother from "../../Assets/userprofile/mother.jpg";
import father from "../../Assets/userprofile/father.png";
import match from "../../Assets/userprofile/match.png";
import connect from "../../Assets/userprofile/connection.png";
import React, { useEffect, useState } from "react";
import "./UserProfile.css";
import EditIcon from "@mui/icons-material/Edit";
import Carousel from "react-bootstrap/Carousel";
import Modal from "react-bootstrap/Modal";
import crown from "./crown.png"
import vagan from "../../Assets/userprofile/vagan.png";
import drink from "../../Assets/userprofile/drink.png";
import smoke from "../../Assets/userprofile/smoke.png";
import Button from 'react-bootstrap/Button';
import { NavLink } from "react-router-dom";
import { BASE_URL } from "../../BASE_URL";
import axios from "axios";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera } from '@fortawesome/free-solid-svg-icons';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import { useNavigate } from 'react-router-dom';
import SchoolIcon from "@mui/icons-material/School";
import EditCareerAndEducation from "../EditCareerAndEducation/EditCareerAndEducation";
import { doc, collection, updateDoc, getFirestore } from 'firebase/firestore';
import { app } from "../../firebase"
import Editfamilyother from "../editFamilyOther/Editfmilyother";
import EditHealthDetails from "../healthDetails/EditHealthDetails";
import EditPersonalDetail from "../EditPersonalDetail/EditPersonalDetail";

function MyVerticallyCenteredModal(props) {

  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const [isDropdownVisible, setIsDropdownVisible] = useState(Array(props.userPhotos?.length || 0).fill(false));
  const [selectedOptions, setSelectedOptions] = useState(Array(props.userPhotos?.length || 0).fill(''));


  const firebaseId = localStorage.getItem("firebase_id");
  const firestore = getFirestore(app);

  const handleDotClick = (index) => {
    const newDropdownVisibility = [...isDropdownVisible];
    newDropdownVisibility[index] = !newDropdownVisibility[index];
    setIsDropdownVisible(newDropdownVisibility);
  };

  const handleOptionSelect = async (index, option) => {
    const filename = option.split('/').pop();

    const token = localStorage.getItem('token');
    fetch(`${BASE_URL}/api/profile/select_profile_photo`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify({ profile_photo_name: filename }),
    })
    const usersCollection = collection(firestore, 'users');
    await updateDoc(doc(usersCollection, firebaseId), { profilepic: filename });
    toast.success('Profile Photo updated Successfully!', {
      position: toast.POSITION.BOTTOM_RIGHT,
      autoClose: 1000,
    });
    const newDropdownVisibility = [...isDropdownVisible];
    newDropdownVisibility[index] = false;
    setIsDropdownVisible(newDropdownVisibility);
    const newUserPhotos = [...props.userPhotos];
    newUserPhotos[index] = option;
    props.setDemo(newUserPhotos);
    props.onHide();
  };





  const handleDeletePhoto = (index) => {
    const filename = props.userPhotos[index].split('/').pop();
    const token = localStorage.getItem('token');



    // Make the API call to delete the photo
    fetch(`${BASE_URL}/api/profile/delete_user_photo`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify({ delete_photo_name: filename }),
    })
      .then((response) => {
        if (!response.ok) {
          toast.success('You can not delete all photos!', {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 1000,
          });
          throw new Error('Network response was not ok');

        }
        toast.success('Profile Photo Deleted Successfully!', {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 1000,
        });
      })
      .catch((error) => {

      });
  };

  const handleNext = () => {
    setActiveSlideIndex((prevIndex) => (prevIndex + 1) % props.userPhotos.length);
  };

  const handleBack = () => {
    setActiveSlideIndex((prevIndex) => (prevIndex - 1 + props.userPhotos.length) % props.userPhotos.length);
  };




  return (
    <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">All Photos</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-12">
            <div style={{ position: 'relative' }}>
              <div style={{ position: 'absolute', top: '50%', transform: 'translateY(-50%)', left: 0 }}>
                {/* <button onClick={handleBack}>&#8249;</button> */}
                {/* <img src="./left.png" alt="" onClick={handleBack} style={{height: "15px"}}/> */}
                <KeyboardArrowLeft onClick={handleBack} style={{ cursor: "pointer", fontSize: "60px" }} />
              </div>
              <div style={{ position: 'absolute', top: '50%', transform: 'translateY(-50%)', right: 0 }}>
                {/* <button onClick={handleNext}>&#8250;</button> */}
                {/* <img src="./right.png" alt="" onClick={handleNext} style={{height: "20px"}}/> */}
                <KeyboardArrowRight onClick={handleNext} style={{ cursor: "pointer", fontSize: "60px" }} />
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

const contactNoFromLocalStorage = localStorage.getItem("contact_no");
const formattedContactnum = localStorage.getItem("contactnumber");

let contactNo;

if (contactNoFromLocalStorage) {
  contactNo = contactNoFromLocalStorage;
} else {
  contactNo = "+91" + formattedContactnum;
}

const UserProfile = () => {

  const navigate = useNavigate();

  useEffect(() => {
    // Check if token is not available
    const token = localStorage.getItem("saptvidhiUserToken");
    if (!token) {
      // If token is not available, navigate to the home page
      navigate("/");
    }
  }, []);


  const [details, setDetails] = useState("");
  const [demo, setDemo] = useState([]);
  const [ProfilePic, setProfilePic] = useState("")
  const [family, setFamily] = useState("");
  const [preference, setPreference] = useState("");
  const [religion, setReligion] = useState([]);
  const [language, setLanguage] = useState([]);
  const [edit, setEdit] = useState("")
  const [otherInfo, setOtherInfo] = useState("")
  const [modalShow, setModalShow] = React.useState(false);

  const userDetails = async () => {
    const token = localStorage.getItem("saptvidhiUserToken");
    const res = await fetch(
      `${BASE_URL}/api/profile/checkProfile`,
      {
        method: "POST",
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    );
    const data = await res.json();
    setDemo(data?.data?.user[0])
    setProfilePic(data.data.user[0].profile_photo)
    setDetails(data?.data?.user?.[0]?.user_name);
    setPreference(data?.data?.user?.[0]?.preference[0]);
    setReligion(data?.data?.user?.[0]?.preference?.[0]?.religion);
    setLanguage(data?.data?.user?.[0]?.preference?.[0]?.language);
    setEdit(data?.data?.user[0])
    if (data.data.user[0].user_other_infos) {
      setOtherInfo(data.data.user[0].user_other_infos);
    }
  };

  useEffect(() => {
    userDetails();
  }, []);


  // modal 
  const [motherTongues, setMotherTongues] = useState([]);
  const [religion1, setReligion1] = useState([]);
  const [maritalStatus, setMaritalStatus] = useState([]);

  useEffect(() => {
    const fetchMotherTongues = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}/api/mother_tongue`
        );

        if (Array.isArray(response.data.data)) {
          setMotherTongues(response.data.data);
        } else {
          setMotherTongues([response.data.data]);
        }
      } catch (error) {
      }
    };


    const fetchReligionPreference = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}/api/religion`
        );
        if (Array.isArray(response.data.data)) {
          setReligion1(response.data.data);
        } else {
          setReligion1([response.data.data]);
        }
      } catch (error) {
      }
    };

    const fetchMaritalStatus = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}/api/marital_status`
        );

        if (Array.isArray(response.data.data)) {
          setMaritalStatus(response.data.data);
        } else {
          setMaritalStatus([response.data.data]);
        }
      } catch (error) {
      }
    };

    fetchMotherTongues();
    fetchReligionPreference();
    fetchMaritalStatus();
  }, []);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);



  // modal end 


  // api for get value 


  // modal 2 start here 

  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  // Define state variables to store selected values
  const [selectedFoodPreference, setSelectedFoodPreference] = useState('');
  const [selectedSmokePreference, setSelectedSmokePreference] = useState('');
  const [selectedDrinkPreference, setSelectedDrinkPreference] = useState('');

  const [foodPreference, setFoodPreference] = useState([]);
  const [smokePreference, setSmokePreference] = useState([]);
  const [drinkPreference, setDrinkPreference] = useState([]);

  useEffect(() => {

    const fetchFoodPreference = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/api/food_preference`);
        if (Array.isArray(response.data.data)) {
          setFoodPreference(response.data.data);
        } else {
          setFoodPreference([response.data.data]);
        }
      } catch (error) {

      }
    };

    const fetchSmokePreference = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/api/smoke_status`);
        if (Array.isArray(response.data.data)) {
          setSmokePreference(response.data.data);
        } else {
          setSmokePreference([response.data.data]);
        }
      } catch (error) {

      }
    };

    const fetchDrinkPreference = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/api/drink_status`);
        if (Array.isArray(response.data.data)) {
          setDrinkPreference(response.data.data);
        } else {
          setDrinkPreference([response.data.data]);
        }
      } catch (error) {

      }
    };

    fetchFoodPreference();
    fetchSmokePreference();
    fetchDrinkPreference();
  }, []);


  // modal 2 end here 


  // modal 3 start from here 
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  // profile edit api start here

  const [formdata7, setformdata7] = useState({
    selectedGender: "",
    selectedToungue: "",
    selectedReligion: "",
    selectedMarraied: "",
    selectedHeight: "",
    selectedWeight: "",
    biod: "",
  })


  const Call7 = async () => {
    const token = localStorage.getItem("saptvidhiUserToken");
    const res = await fetch(
      `${BASE_URL}/api/profile/checkProfile`,
      {
        method: "POST",
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    );
    const data = await res.json();
    setformdata7(data?.data?.user?.[0]);
  };


  useEffect(() => {
    Call7();
  }, []);


  // profile edit api end here 





  // qualification edit start here 

  const [highquli, setHighquli] = useState('');
  const [coll, setColl] = useState('');
  const [Comp, setComp] = useState('');
  const [JobT, setJobT] = useState('');
  const [selectedSalaryRange1, setselectedSalaryRange1] = useState('');



  const handleEditEducationCareer = async () => {
    const token = localStorage.getItem("saptvidhiUserToken");
    try {
      const formData9 = new FormData();

      // Use the values from state or from formdata9 if the state values are empty
      formData9.append('highest_qualification', highquli || formdata9.highest_qualification);
      formData9.append('college', coll || formdata9.college);
      formData9.append('company_name', Comp || formdata9.company_name);
      formData9.append('job_title', JobT || formdata9.job_title);
      formData9.append('salary_range', selectedSalaryRange1 || formdata9.salary_range);
      formData9.append('profile_photo', demo.profile_photo); // Assuming demo.profile_photo is a File object

      const response = await axios.put(
        `${BASE_URL}/api/profile`,
        formData9,
        {
          headers: {
            Authorization: token,
            'Content-Type': 'multipart/form-data', // Use multipart/form-data for file uploads
          },
        }
      );

      // Call9();
      handleClose2(); // Close the modal
      toast.success('Data successfully updated!', {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      });
    } catch (error) {
    }
  };
  const [formdata9, setFormdata9] = useState({
    highest_qualification: "",
    college: "",
    company_name: "",
    job_title: "",
    salary: "",
  })

  const Call9 = async () => {
    const token = localStorage.getItem("saptvidhiUserToken");
    const res = await fetch(
      `${BASE_URL}/api/profile/checkProfile`,
      {
        method: "POST",
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    );
    const data = await res.json();
    setFormdata9(data?.data?.user?.[0]);
  };


  useEffect(() => {
    Call9();
    setHighquli(formdata9.highest_qualification);
    setColl(formdata9.college);
    setComp(formdata9.company_name);
    setJobT(formdata9.job_title);
    setselectedSalaryRange1(formdata9.salary_range);
  }, []);



  // qualification edit end here 



  // testing for edit api 

  const [formdata0, setFormdata0] = useState({
    food_preference: "",
    smoke: "",
    drink: "",
  })

  const Call0 = async () => {
    const token = localStorage.getItem("saptvidhiUserToken");
    const res = await fetch(
      `${BASE_URL}/api/profile/checkProfile`,
      {
        method: "POST",
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    );
    const data = await res.json();
    setFormdata0(data?.data?.user?.[0]);
  };

  useEffect(() => {
    Call0();
  }, []);



  const handleEditPreferences = async () => {
    const token = localStorage.getItem("saptvidhiUserToken");
    const updatedFoodPreference = selectedFoodPreference !== '' ? selectedFoodPreference : formdata0.food_preference;
    const updatedSmokePreference = selectedSmokePreference !== '' ? selectedSmokePreference : formdata0.smoke;
    const updatedDrinkPreference = selectedDrinkPreference !== '' ? selectedDrinkPreference : formdata0.drink;

    try {

      const formData = new FormData();
      formData.append('food_preference', updatedFoodPreference);
      formData.append('smoke', updatedSmokePreference);
      formData.append('drink', updatedDrinkPreference);
      formData.append('profile_photo', demo.profile_photo); // Assuming demo.profile_photo is a File object

      const response = await axios.put(
        `${BASE_URL}/api/profile`,
        formData,
        {
          headers: {
            Authorization: token,
            'Content-Type': 'multipart/form-data', // Use multipart/form-data for file uploads
          },
        }
      );

      handleClose1(); // Close the modal
      Call0();
      if (response.code == 200) {
        userDetails();
      }

      handleClose3();
      toast.success('Food Preference Update Successfully!', {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      });
    } catch (error) {
      toast.error('Network or other error occurred.', {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      });
    }
  };


  // end here 


  // modal 4 start here 

  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);



  const handleShow3 = () => {
    setShow3(true);
  }

  const [formdata3, setFormdata3] = useState({
    mother_occupation: "",
    father_occupation: "",
    no_of_sister: "",
    no_of_married_sister: "",
    no_of_brother: "",
    no_of_married_brother: "",
  })

  const Call = async () => {
    const token = localStorage.getItem("saptvidhiUserToken");
    const res = await fetch(`${BASE_URL}/api/family`, {
      method: "GET",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
    });
    const data = await res.json();
    setFormdata3(data.data !== null ? data.data : "");

  };

  useEffect(() => {
    Call();
  }, []);

  const [no__brother, setNoOfBrother] = useState('');
  const [no__married_brother, setNoOfMarriedBrother] = useState('');

  const [no__sister, setNoOfSister] = useState('');
  const [no__married_sister, setNoOfMarriedSister] = useState('');

  const generateMarriedBrotherOptions = () => {
    const maxMarriedBrother = parseInt(no_of_brother, 10);
    const options = [];

    for (let i = 0; i <= maxMarriedBrother; i++) {
      options.push(
        <option key={i} value={i}>
          {i}
        </option>
      );
    }

    return options;
  };

  const handlechange3 = (e) => {
    const { name, value } = e.target;
    setFormdata3({ ...formdata3, [name]: value });

    if (name === 'no_of_brother') {
      // Update the selected value for Number Of Brother
      setNoOfBrother(value);
      setFormdata3({ ...formdata3, [name]: value });
      // If Number Of Brother is updated, reset Number Of Married Brother
      setNoOfMarriedBrother('');
    } else if (name === 'no_of_married_brother') {
      // Update the selected value for Number Of Married Brother
      setNoOfMarriedBrother(value);
      setFormdata3({ ...formdata3, [name]: value });
    }
    if (name === 'no_of_sister') {
      // Update the selected value for Number Of Sister
      setNoOfSister(value);
      setFormdata3({ ...formdata3, [name]: value });
      // If Number Of Sister is updated, reset Number Of Married Sister
      setNoOfMarriedSister('');
    } else if (name === 'no_of_married_sister') {
      // Update the selected value for Number Of Married Sister
      setNoOfMarriedSister(value);
      setFormdata3({ ...formdata3, [name]: value });
    }


  };

  const generateMarriedSisterOptions = () => {
    const maxMarriedSister = parseInt(no_of_sister, 10);
    const options = [];

    for (let i = 0; i <= maxMarriedSister; i++) {
      options.push(
        <option key={i} value={i}>
          {i}
        </option>
      );
    }

    return options;
  };


  const { father_occupation, no_of_sister, no_of_married_brother, no_of_brother, no_of_married_sister, mother_occupation } = formdata3;

  const handleSubmit3 = async () => {
    const token = localStorage.getItem("saptvidhiUserToken");

    if (
      !father_occupation ||
      no_of_sister === null ||
      no_of_brother === null ||
      no_of_married_brother === null ||
      no_of_married_sister === null ||
      !mother_occupation
    ) {
      toast.error('Please fill all fields!', {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      });
      return;
    }

    try {
      const response = await fetch(`${BASE_URL}/api/family`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
        body: JSON.stringify({
          mother_occupation,
          father_occupation,
          no_of_sister,
          no_of_married_sister,
          no_of_brother,
          no_of_married_brother,
        }),
      });

      const data = await response.json();

      if (response.ok) {
        handleClose3();
        toast.success('Data successfully Updated!', {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 1000,
        });
      } else {
        toast.error('An error occurred while submitting data.', {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 1000,
        });
      }
    } catch (error) {
      toast.error('Network or other error occurred.', {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      });
    }
  };



  // modal 4 end here 



  const handleClick = () => {
    // Navigate to "/uploadphotos" page programmatically
    navigate('/uploadphotos');

    localStorage.setItem('multiplephoto', "profile");
  };

  const handleNavIgate = () => {
    localStorage.setItem('editpartenr', "partner");
    navigate('/partner-preference');
  }



  const [fatherOccupassion, setFatherOccupassion] = useState([])
  const [motherOccupassion, setMotherOccupassion] = useState([])


  const Call3 = async () => {
    const res = await fetch(`${BASE_URL}/api/father_occupation`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await res.json();
    setFatherOccupassion(data.data);
  };

  const Call2 = async () => {
    const res = await fetch(`${BASE_URL}/api/mother_occupation`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await res.json();
    setMotherOccupassion(data.data);
  };

  useEffect(() => {
    Call3();
    Call2();
  }, []);


  const handleEducationChange = () => {
    // You may add any additional logic you need before or after calling userDetails
    Call9();
    userDetails();
  };

  const upDate = () => {
    Call7();
  }

  const handleEditDietPreference = () => {
    navigate("/edit-my-information", { state: "diet" })
  }

  const handleEditFamily = () => {
    navigate("/edit-my-information", { state: "family" })
  }


  return (
    <div className="userprofile mb-sm-0 mb-4">
      <div className="userprofile__left">
        <div className="userprofile_profile">
          <div className="userprofile_profile_top" style={{ position: "relative" }}>
            <img
              src={ProfilePic}
              alt=""
              style={{
                borderRadius: "100%", border: "4px solid #f084b8",
                padding: "5px",
              }}
              onClick={() => setModalShow(true)}
            />
            <div className="d-inline icon_position" style={{ cursor: "pointer" }} onClick={handleClick}>
              <FontAwesomeIcon icon={faCamera} className="camera-icon" style={{ fontSize: "20px", color: "white" }} />
            </div>

            {demo?.member_type === "paid" && (
              <img
                src={crown}
                className="crown_img"
                alt=""
              />
            )}
          </div>

          {demo?.member_type === "paid" ? (
            <>
              <NavLink to={`/our-plan-detail`} className="text-decoration-none text-center">
                <div className="d-flex justify-content-center">
                  <p className="package_detail_add">Package Detail</p>
                </div>
              </NavLink>
            </>
          ) : (
            <>
              <NavLink to={`/SelectPlan`} className="text-decoration-none text-center">
                <div className="d-flex justify-content-center">
                  <p className="package_detail_add">Package Detail</p>
                </div>
              </NavLink>
            </>
          )}

          {/* <div>
            <NavLink to={`/our-plan-detail`} className="text-decoration-none text-center">
              <div className="d-flex justify-content-center">
                <p className="package_detail_add">Package Detail</p>
              </div>
            </NavLink>
          </div> */}
          {/*Photo Modal */}
          <MyVerticallyCenteredModal
            show={modalShow}
            onHide={() => setModalShow(false)}
            userPhotos={demo?.user_photo}
            setUserPhotos={setDemo}
            setDemo={setDemo} // Add this line to pass the setDemo setter as a prop
          />

          <div className="userprofile_profile_bottom">
            <div className="userprofile_profile_bottom_detail_wrapper">
              <PhoneOutlined />
              {demo?.contact_no}
              {/* <EditIcon /> */}
            </div>
            <div className="userprofile_profile_bottom_detail_wrapper">
              <MailOutline />
              {demo?.email}
              {/* <EditIcon /> */}
            </div>
            <div className="userprofile_profile_bottom_detail_wrapper">
              <LockOutlined />
              {demo?.member_type} Member
              {/* <EditIcon /> */}
            </div>
          </div>
        </div>
        <div className="userprofile_matches d-md-flex d-sm-none d-none">
          <div>
            <button className="edit_btn_profile float-end" onClick={handleNavIgate} style={{ cursor: "pointer" }} >
              Edit
            </button>
          </div>
          <div className="userprofile_matches_profile_images">
            <img src={demo.profile_photo} alt="" className="userprofile_matches_me" style={{ borderRadius: "100%" }} />
            <img
              src={connect}
              alt=""
              className="userprofile_matches_connection"
              style={{ width: "120px" }}
            />
            {/* <img src={img2} alt="" className="userprofile_matches_user" /> */}
          </div>
          <div className="userprofile_matches_description">
            <span>You match 8/8 of his</span>
            <p>Desired Preferences</p>
          </div>
          <div className="userprofile_matches_content_wrapper">
            <div className="userprofile_matches_content">
              <p>Age:</p>
              <img src={match} alt="" />
              {/* <p>18 to 25</p> */}
              <p>
                {edit?.preference?.[0]?.min_age} to {edit?.preference?.[0]?.max_age}
              </p>
            </div>
            <div className="userprofile_matches_content">
              <p>Height</p>
              <img src={match} alt="" />
              <p>
                {edit?.preference?.[0]?.min_height} to {edit?.preference?.[0]?.max_height}
              </p>
            </div>
            <div className="userprofile_matches_content">
              <p>Marital Status</p>
              <img src={match} alt="" />
              {/* <p>{preference?.marital_status}</p> */}
              <p>
                {edit?.preference?.[0]?.marital_status?.map((e) => {
                  return (
                    <>
                      <span className="d-block">{e}</span>
                    </>
                  );
                })}
              </p>
            </div>
            <div className="userprofile_matches_content">
              <p>Religion / Community</p>
              <img src={match} alt="" />
              <p>
                {edit?.preference?.[0]?.religion?.map((e) => {
                  return (
                    <>
                      <span className="d-block">{e}</span>
                    </>
                  );
                })}
              </p>
            </div>
            <div className="userprofile_matches_content">
              <p>Mother Tongue</p>
              <img src={match} alt="" />
              <p>
                {edit?.preference?.[0]?.language?.map((e) => {
                  return (
                    <>
                      <span className="d-block">{e} </span>
                    </>
                  );
                })}
              </p>
            </div>
            <div className="userprofile_matches_content">
              <p>Country Living in</p>
              <img src={match} alt="" />
              <p>{edit?.preference?.[0]?.country}</p>
            </div>
            <div className="userprofile_matches_content">
              <p>State Living in</p>
              <img src={match} alt="" />
              <p>{edit?.preference?.[0]?.location}</p>
            </div>
            <div className="userprofile_matches_content">
              <p>Annual I ncome</p>
              <img src={match} alt="" />
              <p>{edit?.preference?.[0]?.annual_income}</p>
            </div>
            <div className="userprofile_matches_content">
              <p>Diet</p>
              <img src={match} alt="" />
              {/* <p>{preference?.food_preferences}</p> */}
              <p>
                {edit?.preference?.[0]?.food_preferences?.map((e) => {
                  return (
                    <>
                      <span className="d-block">{e}</span>
                    </>
                  );
                })}
              </p>
            </div>
            <div className="userprofile_matches_content">
              <p>Smoke</p>
              <img src={match} alt="" />
              {/* <p>{preference?.smoke}</p> */}
              <p>
                {edit?.preference?.[0]?.smoke?.map((e) => {
                  return (
                    <>
                      <span className="d-block">{e}</span>
                    </>
                  );
                })}
              </p>
            </div>
            <div className="userprofile_matches_content">
              <p>Drink</p>
              <img src={match} alt="" />
              {/* <p>{preference?.drink}</p> */}
              <p>
                {edit?.preference?.[0]?.drink?.map((e) => {
                  return (
                    <>
                      <span className="d-block">{e}</span>
                    </>
                  );
                })}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="userprofile__right">
        <div className="userprofile_intro">
          <div className="userprofile_intro_name">
            <h2 title="Your Name">{demo?.user_name}</h2>
            {/* <p className="userprofile_intro_height">25 yrs, 5'55</p> */}
            <p className="userprofile_intro_height mb-1" title="Age , Height And Weight">
              {formdata7.age} yrs, {formdata7.height}, {formdata7.weight}kg
            </p>

            <div className="d-sm-flex d-block justify-content-between">
              <div>
                <p className="mb-sm-2 mb-1" title="community and religion">
                  <PeopleIcon style={{ marginRight: "5px" }} />
                  {formdata7?.community} , {formdata7?.religion}
                </p>
              </div>
              {otherInfo.profile_manage ? (
                <div>
                  <h6>Profile Managed By:
                    <p style={{ fontWeight: "400" }}>{otherInfo.profile_manage && otherInfo.profile_manage.map((e) => {
                      return (
                        <>
                          {e},
                        </>
                      )
                    })}</p>
                  </h6>
                </div>
              ) : ""}

            </div>
          </div>

          <div className="userprofile_intro_bio">
            <h3>Bio</h3>
            <p style={{ overflowWrap: "anywhere" }}>{formdata7.bio}</p>
          </div>
        </div>
        <div className="userprofile_basic_details">
          <EditPersonalDetail updateBio={upDate} />
        </div>
        <div>
          {otherInfo.thalassemia ? (
            <EditHealthDetails />
          ) : ""}
        </div>
        <div className="userprofile_food_preference">
          <div className="userprofile_section_header">
            <h3>Food Preference</h3>

            <button className="edit_btn_profile"
              // onClick={handleShow1} 
              onClick={handleEditDietPreference}
              style={{ cursor: "pointer" }} >
              Edit
            </button>

            {/* modal2 start here */}

            <Modal
              show={show1}
              onHide={handleClose1}
              backdrop="static"
              keyboard={false}
            >
              <Modal.Header closeButton>
                <Modal.Title>Edit Your Profile</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div>
                  <div className="gender_state w-100">
                    <label htmlFor="" style={{ fontWeight: "500" }}>Select Food Preference</label>
                    <select
                      className="food-preference rounded px-3 py-1 mb-3"
                      style={{ border: "1px solid #f97096" }}
                      required
                      value={selectedFoodPreference || formdata0.food_preference}
                      onChange={e => setSelectedFoodPreference(e.target.value)}
                    >
                      <option value="" disabled>Food Preference</option>
                      {foodPreference &&
                        foodPreference.map((food, index) => (
                          <option key={index} value={food.fp_name}>
                            {food.fp_name}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div className="gender_state w-100">
                    <label htmlFor="" style={{ fontWeight: "500" }}>Select Smoke Preference</label>
                    <select
                      className="smoke rounded px-3 py-1 mb-3"
                      style={{ border: "1px solid #f97096" }}
                      required
                      value={selectedSmokePreference || formdata0.drink}
                      onChange={e => setSelectedSmokePreference(e.target.value)}
                    >
                      <option value="" disabled>Smoke</option>
                      {smokePreference &&
                        smokePreference.map((smoke, index) => (
                          <option key={index} value={smoke.smoke_value}>
                            {smoke.smoke_value}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div className="gender_state w-100">
                    <label htmlFor="" style={{ fontWeight: "500" }}>Select Drink Preference</label>
                    <select
                      className="drink rounded px-3 py-1 mb-3"
                      style={{ border: "1px solid #f97096" }}
                      required
                      value={selectedDrinkPreference || formdata0.smoke}
                      onChange={e => setSelectedDrinkPreference(e.target.value)}
                    >
                      <option value="" disabled>Drink</option>
                      {drinkPreference &&
                        drinkPreference.map((drink, index) => (
                          <option key={index} value={drink.drink_value}>
                            {drink.drink_value}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer className="footer-btn-modal">
                <button onClick={handleClose1}>
                  Close
                </button>
                <button onClick={handleEditPreferences}>Save</button>
              </Modal.Footer>
            </Modal>

            {/* modal end here  */}

          </div>
          <div className="userprofile_food_preference_content_wrapper">
            <div className="userprofile_food_preference_content">
              <img src={vagan} alt="" />
              <p className="mb-0" title="Food Preference">{formdata0.food_preference}</p>
            </div>
            <div className="userprofile_food_preference_content">
              <img src={drink} alt="" />
              <p className="mb-0" title="Drink preference">{formdata0.drink}</p>
            </div>
            <div className="userprofile_food_preference_content">
              <img src={smoke} alt="" />
              <p className="mb-0" title="Smoke Preference">{formdata0.smoke}</p>
            </div>
          </div>
        </div>
        <div className="userprofile_education">
          <div className="userprofile_section_header">
            <h3>Education & Career</h3>
            <EditCareerAndEducation onProfileUpdate={handleEducationChange} />
          </div>
          <div className="userprofile_education_content_wrapper">
            <div className="userprofile_education_content">
              <WorkOutlineIcon />
              <p>
                <span title="Highest Qualification">{formdata9.highest_qualification}</span> - <span title="College">{formdata9.college}</span>
              </p>
            </div>
            <div className="userprofile_education_content">
              <MilitaryTechIcon />
              <p title="Company Name">{formdata9.company_name}</p>
            </div>
            <div className="userprofile_education_content">
              <CalendarTodayIcon />
              <p title="Job Title">{formdata9.job_title}</p>
            </div>
            {otherInfo?.employed_type?.[0] ? (
              <div className="userprofile_education_content">
                <CalendarTodayIcon />
                <p title="Job Title">{otherInfo?.employed_type?.[0]}</p>
              </div>
            ) : null}
            <div className="userprofile_education_content">
              <BarChart />
              <p title="Income Per Year">Earns INR {formdata9.salary} annually</p>
            </div>
          </div>
        </div>
        {formdata3 ? (
          <div className="userprofile_family_details">
            <div className="userprofile_section_header">
              <h3>Family Details</h3>
              <button className="edit_btn_profile"
                // onClick={handleShow3} 
                  onClick={handleEditFamily}
                style={{ cursor: 'pointer' }} >
                Edit
              </button>


              <Modal show={show3} onHide={handleClose3} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                  <Modal.Title>Family Information</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div>
                    <div className="mb-2">
                      <p className="mb-1" style={{ fontWeight: "500" }}>Mother Occupassion</p>
                      <select
                        className="gender rounded px-3 py-1"
                        style={{ border: "1px solid #f97096" }}
                        value={mother_occupation}
                        name="mother_occupation"
                        onChange={handlechange3}
                      >
                        <option value="" selected>Select Mother Occupassion</option>
                        {motherOccupassion.map((option) => (
                          <option value={option.name}>
                            {option.name}
                          </option>
                        ))}
                      </select>
                    </div>



                    <div className="mb-2">
                      <p className="mb-1" style={{ fontWeight: "500" }}>Select Your Father Occupassion</p>
                      <select
                        className="gender rounded px-3 py-1"
                        style={{ border: "1px solid #f97096" }}
                        value={father_occupation}
                        name="father_occupation"
                        onChange={handlechange3}
                      >
                        <option value="" selected>Select Father Occupassion</option>
                        {fatherOccupassion.map((option) => (
                          <option value={option.name}>
                            {option.name}
                          </option>
                        ))}
                      </select>
                    </div>


                    <div className="mb-2">
                      <label htmlFor="" style={{ fontWeight: "500" }}>Number Of Sister</label>
                      <select
                        className="rounded px-3 py-1"
                        value={no_of_sister}
                        style={{ border: "1px solid #f97096" }}
                        name="no_of_sister"
                        onChange={handlechange3}

                      >
                        <option value="" disabled>Total Sister</option>
                        <option value="0">0</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                      </select>
                    </div>

                    <div className="mb-2">
                      <label htmlFor="" style={{ fontWeight: '500' }}>
                        Number Of Married Sister
                      </label>
                      <select
                        className="rounded px-3 py-1"
                        style={{ border: '1px solid #f97096' }}
                        name="no_of_married_sister"
                        value={no_of_married_sister}
                        onChange={handlechange3}
                      >
                        <option value="" disabled>
                          Married Sister
                        </option>
                        {generateMarriedSisterOptions()}
                      </select>
                    </div>

                    <div className="mb-2">
                      <label htmlFor="" style={{ fontWeight: "500" }}>Number Of Brother</label>
                      <select
                        className="rounded px-3 py-1"
                        style={{ border: "1px solid #f97096" }}
                        name="no_of_brother"
                        value={no_of_brother}
                        onChange={handlechange3}

                      >
                        <option value="" disabled>Total Brother</option>
                        <option value="0">0</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                      </select>
                    </div>

                    <div className="mb-2">
                      <label htmlFor="" style={{ fontWeight: '500' }}>
                        Number Of Married Brother
                      </label>
                      <select
                        className="rounded px-3 py-1"
                        style={{ border: '1px solid #f97096' }}
                        name="no_of_married_brother"
                        value={no_of_married_brother}
                        onChange={handlechange3}
                      >
                        <option value="" disabled>
                          Married Brother
                        </option>
                        {generateMarriedBrotherOptions()}
                      </select>
                    </div>


                  </div>
                </Modal.Body>
                <Modal.Footer className="footer-btn-modal">
                  <button onClick={handleClose3}>Close</button>
                  <button onClick={handleSubmit3} >Submit</button>
                </Modal.Footer>
              </Modal>

            </div>
            <div className="userprofile_family_details_content_wrapper">
              <div className="userprofile_family_details_content">
                <img src={mother} alt="" />
                <div>
                  <span>Mother’s Occupation</span>
                  <p>{formdata3.mother_occupation}</p>
                </div>
              </div>
              <div className="userprofile_family_details_content">
                <img src={father} alt="" />
                <div>
                  <span>Father’s Occupation</span>
                  <p>{formdata3.father_occupation}</p>
                </div>
              </div>
              <div className="userprofile_family_details_content">
                <img src={mother} alt="" />
                <div>
                  <span>Sister</span>
                  <p>{formdata3.no_of_sister}</p>
                </div>
              </div>
              <div className="userprofile_family_details_content">
                <img src={mother} alt="" />
                <div>
                  <span>No Of Married Sister</span>
                  <p>{formdata3.no_of_married_sister}</p>
                </div>
              </div>
              <div className="userprofile_family_details_content">
                <img src={father} alt="" />
                <div>
                  <span>Brother</span>
                  <p>{formdata3.no_of_brother}</p>
                </div>
              </div>
              <div className="userprofile_family_details_content">
                <img src={father} alt="" />
                <div>
                  <span>No Of Married Brother</span>
                  <p>{formdata3.no_of_married_brother}</p>
                </div>
              </div>
            </div>
          </div>
        ) : ""}
        {otherInfo.family_status ? (
          <Editfamilyother onProfileUpdate={handleEducationChange} />
        ) : ""}
        <ToastContainer />
        <div className="userprofile_matches d-md-none d-sm-flex d-flex">
          <div>
            <button className="edit_btn_profile float-end" onClick={handleNavIgate} style={{ cursor: "pointer" }} >
              Edit
            </button>
          </div>
          <div className="userprofile_matches_profile_images">
            <img src={img1} alt="" className="userprofile_matches_me" />
            <img
              src={connect}
              alt=""
              className="userprofile_matches_connection"
            />
            <img src={img2} alt="" className="userprofile_matches_user" />
          </div>
          <div className="userprofile_matches_description">
            <span>You match 8/8 of his</span>
            <p>Desired Preferences</p>
          </div>
          <div className="userprofile_matches_content_wrapper">
            <div className="userprofile_matches_content">
              <p>Age:</p>
              <img src={match} alt="" />
              {/* <p>18 to 25</p> */}
              <p>
                {edit?.preference?.[0]?.min_age} to {edit?.preference?.[0]?.max_age}
              </p>
            </div>
            <div className="userprofile_matches_content">
              <p>Height</p>
              <img src={match} alt="" />
              <p>
                {edit?.preference?.[0]?.min_height} to {edit?.preference?.[0]?.max_height}
              </p>
            </div>
            <div className="userprofile_matches_content">
              <p>Marital Status</p>
              <img src={match} alt="" />
              {/* <p>{preference?.marital_status}</p> */}
              <p>
                {edit?.preference?.[0]?.marital_status?.map((e) => {
                  return (
                    <>
                      <span className="d-block">{e}</span>
                    </>
                  );
                })}
              </p>
            </div>
            <div className="userprofile_matches_content">
              <p>Religion / Community</p>
              <img src={match} alt="" />
              <p>
                {edit?.preference?.[0]?.religion?.map((e) => {
                  return (
                    <>
                      <span className="d-block">{e}</span>
                    </>
                  );
                })}
              </p>
            </div>
            <div className="userprofile_matches_content">
              <p>Mother Tongue</p>
              <img src={match} alt="" />
              <p>
                {edit?.preference?.[0]?.language?.map((e) => {
                  return (
                    <>
                      <span className="d-block">{e} </span>
                    </>
                  );
                })}
              </p>
            </div>
            <div className="userprofile_matches_content">
              <p>Country Living in</p>
              <img src={match} alt="" />
              <p>{edit?.preference?.[0]?.country}</p>
            </div>
            <div className="userprofile_matches_content">
              <p>State Living in</p>
              <img src={match} alt="" />
              <p>{edit?.preference?.[0]?.location}</p>
            </div>
            <div className="userprofile_matches_content">
              <p>Annual I ncome</p>
              <img src={match} alt="" />
              <p>{edit?.preference?.[0]?.annual_income}</p>
            </div>
            <div className="userprofile_matches_content">
              <p>Diet</p>
              <img src={match} alt="" />
              {/* <p>{preference?.food_preferences}</p> */}
              <p>
                {edit?.preference?.[0]?.food_preferences?.map((e) => {
                  return (
                    <>
                      <span className="d-block">{e}</span>
                    </>
                  );
                })}
              </p>
            </div>
            <div className="userprofile_matches_content">
              <p>Smoke</p>
              <img src={match} alt="" />
              {/* <p>{preference?.smoke}</p> */}
              <p>
                {edit?.preference?.[0]?.smoke?.map((e) => {
                  return (
                    <>
                      <span className="d-block">{e}</span>
                    </>
                  );
                })}
              </p>
            </div>
            <div className="userprofile_matches_content">
              <p>Drink</p>
              <img src={match} alt="" />
              {/* <p>{preference?.drink}</p> */}
              <p>
                {edit?.preference?.[0]?.drink?.map((e) => {
                  return (
                    <>
                      <span className="d-block">{e}</span>
                    </>
                  );
                })}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
