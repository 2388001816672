import { createAsyncThunk } from "@reduxjs/toolkit";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import "./Login.css";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BASE_URL } from "../../BASE_URL";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

const Login = () => {
  const [dell, setDell] = useState({
    contact_no: "",
    password: "",
  });
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);

  const [notifications, setNotifications] = useState([]);


  const txt = (e) => {
    const { name, value } = e.target;
    setDell({ ...dell, [name]: value });
  };

  const handleSubmit = async () => {
    handleLogin();
  };

  const handleLogin = async () => {
    const { contact_no, password } = dell;
    const formattedContactNo = "+91" + contact_no;

    if (!contact_no && !password) {
      toast.error('Please Enter Your Registered Mobile Number & Password!', {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      });
      return;
    }

    if (!contact_no) {
      toast.error('Please Enter Register Mobile Number!', {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      });
      return;
    }

    if (formattedContactNo.length !== 13) {
      toast.error('Please Enter 10 Digit Contact No!', {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      });
      return;
    }


    if (!password) {
      toast.error('Please Enter Your Password!', {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      });
      return;
    }


    const res = await fetch(`${BASE_URL}/api/profile/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        contact_no: formattedContactNo,
        password,
      }),
    });

    const abc = await res.json();

    if (abc.code === 200) {
      console.log(abc);
      navigate("/profile");
      localStorage.setItem("user", JSON.stringify(abc.data));
      localStorage.setItem("saptvidhiUserToken", abc.data.token);
      localStorage.setItem("preference-detail", "preference");
      localStorage.setItem("family-detail", "family");
      localStorage.setItem("fillup", "fill the data");
      localStorage.setItem("contact_no", formattedContactNo);
      localStorage.setItem("firebase_id", abc.data.Fields.firebase_id);
      const currentDateAndTime = new Date().toLocaleDateString();
      localStorage.setItem("loginDateTime", currentDateAndTime);
    } else {
      toast.error('Please Enter Registered Mobile Number and Password!', {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      });
    }
  };


  useEffect(() => {
    const storedToken = localStorage.getItem("saptvidhiUserToken");
    if (storedToken) {
      navigate("/profile");
    }
  }, [navigate]);

  return (
    <div className="login__wrapepr_new">
      <div className="login_new">
        <input
          placeholder="Registered Mobile Number"
          type="tel"
          onInput={(e) => (e.target.value) = e.target.value.replace(/[^0-9]/g, '')}
          maxLength="10"
          onChange={txt}
          name="contact_no"
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              handleLogin();
            }
          }}
        />
        <div className="password-input-container w-100 d-flex justify-content-center eye-password-relative"> {/* Container to include eye icon */}
          <input
            type={showPassword ? "text" : "password"} // Toggle input type based on showPassword state
            placeholder="Enter Password"
            onChange={txt}
            name="password"
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                handleLogin();
              }
            }}
          />

          <FontAwesomeIcon // Eye icon to toggle password visibility
            icon={showPassword ? faEyeSlash : faEye}
            className="eye-password"
            style={{right: 0}}
            onClick={() => setShowPassword(!showPassword)}
          />
        </div>
        <div id="recaptcha"></div>
        <div className="d-flex justify-content-between w-100">
          <Link to="/forgotpassword">Forgot Password</Link>
          <Link to="/newuser">New user ?</Link>
        </div>
        <ToastContainer />
        <button onClick={handleSubmit}>Login</button>
      </div>
    </div>
  );
};

export default Login;
