import React, { useEffect, useRef, useState } from 'react';
import "./vendorhome.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import VenueCard from '../../Components/VenueCard/VenueCard';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import PopularSearches from '../../Components/PopularSearches/PopularSearches';
import WeddingStory from '../../Components/WeddingStories/WeddingStory';
import WeddingGallary from '../../Components/vendor-wedding-galary/WeddingGallary';
import FeaturedVendor from '../../Components/featuredvendors/FeaturedVendors';
import WeddingBlog from '../../Components/weddingblog/WeddingBlog';

const Vendorhome = () => {

    const array = [
        "abc",
        "abc",
        "abc",
        "abc",
        "abc",
    ]

    const settings = {
        // dots: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
    };

    const settings2 = {
        // dots: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
    };

    const settings3 = {
        // dots: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
    };

    const settings4 = {
        // dots: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
    };

    const sliderRef = useRef(null);
    const [isSliderReady, setIsSliderReady] = useState(false);

    useEffect(() => {
        setIsSliderReady(true);
    }, []);

    const prevSlide = () => {
        if (isSliderReady && sliderRef.current) {
            sliderRef.current.slickPrev();
        }
    };

    const nextSlide = () => {
        if (isSliderReady && sliderRef.current) {
            sliderRef.current.slickNext();
        }
    };

    const settings5 = {
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
    };

    return (
        <>
            <div className="my-5">
                <div>
                    <div className='vendor-home-bg'>
                        <div className='h-100 w-100 d-flex flex-column justify-content-end align-items-center'>
                            <div className='vendor-homepage-header'>
                                <div>
                                    <h1 className='text-center'>Your <span>Wedding,</span> Your <span>Way</span></h1>
                                    <p className='text-center'>Find the best wedding vendors with thousands of trusted reviews</p>
                                </div>
                                <div className='d-flex justify-content-center gap-3 mt-5'>
                                    <select name="" id="">
                                        <option value="">Select Vendor Type</option>
                                    </select>
                                    <select name="" id="">
                                        <option value="">Select City</option>
                                    </select>
                                </div>
                                <div className='vendor-get-started'>
                                    <p className='text-center mt-4'>Get Started <FontAwesomeIcon icon={faArrowRight} /></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className='venue-cards-component'>
                        <h3 className='mb-3'>Popular Venue Searches</h3>
                        <div className='row venue-card-loop'>
                            <Slider {...settings}>
                                {array.map((e, index) => (
                                    <VenueCard key={index} />
                                ))}
                            </Slider>
                        </div>
                    </div>
                    <div className='venue-cards-component mt-5'>
                        <h3 className='mb-3'>Popular Searches</h3>
                        <div className='row venue-card-loop'>
                            <Slider {...settings2} >
                                {array.map((e, index) => (
                                    <PopularSearches key={index} />
                                ))}
                            </Slider>
                        </div>
                    </div>
                </div>
                <div className='vendor-wedding-categories mt-5'>
                    <div className="container">
                        <div className='wedding-categories-heading'>
                            <h3 className='mb-3'>Wedding Categories</h3>
                        </div>
                        <div className='wedding-categories-grid'>
                            <div className='wedding-categorie-bg'>
                            </div>
                            <div className='wedding-categorie-bg'>
                            </div>
                            <div className='wedding-categorie-bg'>
                            </div>
                            <div className='wedding-categorie-bg'>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='vendor-inhouse-services mt-5'>
                    <div className="container">
                        <div className='inhouse-services-heading'>
                            <h3 className='mb-3'>Our Inhouse Services</h3>
                        </div>
                        <div className='inhouse-services-grid'>
                            <div className='inhouse-services-bg'>
                            </div>
                            <div className='inhouse-services-inner-grid d-flex flex-column justify-content-between '>
                                <div className='inhouse-services-bg-2'></div>
                                <div className='inhouse-services-bg-3'></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className='venue-cards-component'>
                        <h3 className='mb-3'>Real Wedding Stories</h3>
                        <div className='row venue-card-loop'>
                            <Slider {...settings3}>
                                {array.map((e, index) => (
                                    <WeddingStory key={index} />
                                ))}
                            </Slider>
                        </div>
                    </div>
                </div>
                <div className='vendor-wedding-gallary'>
                    <div className="container">
                        <div className='wedding-gallary-component'>
                            <h3 className='mb-3'>Gallary to Look for</h3>
                            <div className='row wedding-gallary-loop'>
                                <WeddingGallary />
                                <WeddingGallary />
                                <WeddingGallary />
                                <WeddingGallary />
                                <WeddingGallary />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className='venue-cards-component'>
                        <h3 className='mb-3'>Featured Vendors</h3>
                        <div className='row featured-vendor-component'>
                            <FeaturedVendor />
                            <FeaturedVendor />
                            <FeaturedVendor />
                            <FeaturedVendor />
                        </div>
                    </div>
                </div>
                <div className="container my-4">
                    <div className='wedding-latest-blog-header'>
                        <h3 className='mb-3'>Wedding Categories</h3>
                    </div>
                    <div className="wedding-latest-blog-content">
                        <div className=''>
                            <div className="wedding-latest-blog-container">
                                <Slider {...settings5} ref={sliderRef}>
                                    {array.map((e, index) => (
                                        <WeddingBlog />
                                    ))}
                                </Slider>
                            </div>
                        </div>
                    </div>
                    <div className='wedding-blog-navigators'>
                        <button onClick={prevSlide}><FontAwesomeIcon icon={faArrowLeft} /></button>
                        <button onClick={nextSlide}><FontAwesomeIcon icon={faArrowRight} /></button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Vendorhome;
