import React from 'react';
import "./venuecard.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

const VenueCard = () => {
    return (
        <>
            <div className="">
                <div className='venue-card-grid-border'>
                    <div>
                        <img src="./venue-card.png" alt="" />
                    </div>
                    <div>
                        <h5>4 Star & Above</h5>
                        <div className='venue-cities mb-3'>
                                <span style={{border: "1px solid rgba(0, 0, 0, 0.2)"}}>Mumbai</span>
                                <span style={{border: "1px solid rgba(0, 0, 0, 0.2)"}}>Delhi</span>
                                <span style={{border: "1px solid rgba(0, 0, 0, 0.2)"}}>Agra</span>
                                <span style={{border: "1px solid rgba(0, 0, 0, 0.2)"}}>Goa</span>
                                <span style={{border: "1px solid rgba(0, 0, 0, 0.2)"}}>Shimla</span>
                        </div>
                        <button className='see-all-venue-button'>See All <FontAwesomeIcon icon={faArrowRight} /></button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default VenueCard
