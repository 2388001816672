import React from 'react';
import "./incomplete.css";
import Circle from "./Circle";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightLong } from '@fortawesome/free-solid-svg-icons';
import { NavLink, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { BASE_URL } from '../../BASE_URL';


function Inclomplete() {

    const [percentage, setPercentage] = useState("");

    const navigate = useNavigate();

    const [add, setAdd] = useState("")

    const Call = async () => {

        const token = localStorage.getItem("saptvidhiUserToken");


        const res = await fetch(`${BASE_URL}/api/user_other_info`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: token,
            },
        });
        const data = await res.json();
        setAdd(data.data);
    };

    useEffect(() => {
        Call();
        userDetails();
    }, []);


    const handleDisability = () => {
        navigate('/healthcondition', { state: { message: "disability", title: add.any_disability_title } });
    }
    const handleBloodGroup = () => {
        navigate('/healthcondition', { state: { message: "blood group", title: add.blood_group_title } });
    }
    const handleCurrentLocation = () => {
        navigate('/healthcondition', { state: { message: "country", title: add.current_location_title } });
    }
    const handleDoc = () => {
        navigate('/healthcondition', { state: { message: "Document", title: add.doc_verification_title } });
    }
    const handleFamily = () => {
        navigate('/healthcondition', { state: { message: "family", title: add.family_status_title } });
    }
    const handleHealth = () => {
        navigate('/healthcondition', { state: { message: "health info", title: add.health_info_title } });
    }
    const handleHiv = () => {
        navigate('/healthcondition', { state: { message: "thalassemia", title: add.hiv_title } });
    }
    const handleHobby = () => {
        navigate('/healthcondition', { state: { message: "hobbies", title: add.hobbies_title } });
    }
    const handleLangauge = () => {
        navigate('/healthcondition', { state: { message: "language", title: add.languages_and_speak_title } });
    }
    const handleManage = () => {
        navigate('/healthcondition', { state: { message: "profile manage", title: add.profile_manage_title } });
    }
    const handleSettle = () => {
        navigate('/healthcondition', { state: { message: "settledown", title: add.settling_abroad_title } });
    }
    const handleWhatsapp = () => {
        navigate('/healthcondition', { state: { message: "whatsapp", title: add.whatsapp_title } });
    }
    const handleBirth = () => {
        navigate('/healthcondition', { state: { message: "birth", title: add.birth_time_title } });
    }
    const handleSocial = () => {
        navigate('/social-media', { state: { message: "whatsapp", title: add.social_media_title } });
    }


    const contactNoFromLocalStorage = localStorage.getItem("contact_no");
    const formattedContactnum = localStorage.getItem("contactnumber");

    let contactNo;

    if (contactNoFromLocalStorage) {
        contactNo = contactNoFromLocalStorage;
    } else {
        contactNo = "+91" + formattedContactnum;
    }


    const userDetails = async () => {
        const token = localStorage.getItem("saptvidhiUserToken");
        const res = await fetch(
            `${BASE_URL}/api/profile/checkProfile`,
            {
                method: "POST",
                headers: {
                    Authorization: token,
                    "Content-Type": "application/json",
                },
            }
        );
        const data = await res.json();
        setPercentage(data.data.profile_percentage
        )

    };

    const roundedInteger = Math.round(percentage)


    return (
        <>
            <section>
                <div className="incomplete">
                    <div className="incomplete_box">
                        <div className="row">
                            <div className="col-12 mb-3">
                                <div className='incomplete-profile-header-container'>
                                    <Circle percentage={roundedInteger} />
                                    <div className="complete_profile_btn_2 complete_profile_btn d-flex flex-column justify-content-center">
                                        <h6 style={{ color: "black" }}>Add a few more details to make your <br /> profile rich!</h6>
                                        <h6 className='mb-0'>Complete Your Profile </h6>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 px-sm-5 px-3">
                                <div className="row">

                                    {add.any_disability_status === false ? (
                                        <div className="col-sm-6 col-12 mb-3" style={{ height: "100%" }}>
                                            <div className="incomplete_add" style={{ height: "100%" }}>
                                                <h5>Disability</h5>
                                                <div className='d-flex justify-content-between'>
                                                    <p className='mb-1'>Add Any Disability</p>
                                                    <h6 className='text-end ' style={{ cursor: "pointer" }} onClick={handleDisability}>Add</h6>
                                                </div>
                                            </div>
                                        </div>
                                    ) : null}
                                    {add.blood_group_status === false ? (
                                        <div className="col-sm-6 col-12 mb-3" style={{ height: "100%" }}>
                                            <div className="incomplete_add" style={{ height: "100%" }}>
                                                <h5>Blood Group</h5>
                                                <div className='d-flex justify-content-between'>
                                                    <p className='mb-1'>Add Blood Group</p>
                                                    <h6 className='text-end ' style={{ cursor: "pointer" }} onClick={handleBloodGroup}>Add</h6>
                                                </div>
                                            </div>
                                        </div>
                                    ) : null}
                                    {add.current_location_status === false ? (
                                        <div className="col-sm-6 col-12 mb-3" style={{ height: "100%" }}>
                                            <div className="incomplete_add" style={{ height: "100%" }}>
                                                <h5>Current Location & Assets</h5>
                                                <div className='d-flex justify-content-between'>
                                                    <p className='mb-1'>Add Current Location & Assets</p>
                                                    <h6 className='text-end ' style={{ cursor: "pointer" }} onClick={handleCurrentLocation}>Add</h6>
                                                </div>
                                            </div>
                                        </div>
                                    ) : null}
                                    {add.doc_verification_status === false ? (
                                        <div className="col-sm-6 col-12 mb-3" style={{ height: "100%" }}>
                                            <div className="incomplete_add" style={{ height: "100%" }}>
                                                <h5>Documents</h5>
                                                <div className='d-flex justify-content-between'>
                                                    <p className='mb-1'>Add Documents</p>
                                                    <h6 className='text-end ' style={{ cursor: "pointer" }} onClick={handleDoc}>Add</h6>
                                                </div>
                                            </div>
                                        </div>
                                    ) : null}
                                    {add.family_status_status === false ? (
                                        <div className="col-sm-6 col-12 mb-3" style={{ height: "100%" }}>
                                            <div className="incomplete_add" style={{ height: "100%" }}>
                                                <h5>Family Background</h5>
                                                <div className='d-flex justify-content-between'>
                                                    <p className='mb-1'>Add Family Background</p>
                                                    <h6 className='text-end ' style={{ cursor: "pointer" }} onClick={handleFamily}>Add</h6>
                                                </div>
                                            </div>
                                        </div>
                                    ) : null}
                                    {add.health_info_status === false ? (
                                        <div className="col-sm-6 col-12 mb-3" style={{ height: "100%" }}>
                                            <div className="incomplete_add" style={{ height: "100%" }}>
                                                <h5>Health Information</h5>
                                                <div className='d-flex justify-content-between'>
                                                    <p className='mb-1'>Add Health Information</p>
                                                    <h6 className='text-end' style={{ cursor: "pointer" }} onClick={handleHealth}>Add</h6>
                                                </div>
                                            </div>
                                        </div>
                                    ) : null}
                                    {add.hiv_status === false ? (
                                        <div className="col-sm-6 col-12 mb-3" style={{ height: "100%" }}>
                                            <div className="incomplete_add" style={{ height: "100%" }}>
                                                <h5>Hiv & Thelessemia </h5>
                                                <div className='d-flex justify-content-between'>
                                                    <p className='mb-1'>Add Hiv & Thelessemia Status</p>
                                                    <h6 className='text-end ' style={{ cursor: "pointer" }} onClick={handleHiv}>Add</h6>
                                                </div>
                                            </div>
                                        </div>
                                    ) : null}
                                    {add.hobbies_name_status === false ? (
                                        <div className="col-sm-6 col-12 mb-3" style={{ height: "100%" }}>
                                            <div className="incomplete_add" style={{ height: "100%" }}>
                                                <h5>Hobbies</h5>
                                                <div className='d-flex justify-content-between'>
                                                    <p className='mb-1'>Add Hobbies</p>
                                                    <h6 className='text-end ' style={{ cursor: "pointer" }} onClick={handleHobby}>Add</h6>
                                                </div>
                                            </div>
                                        </div>
                                    ) : null}
                                    {add.languages_and_speak_status === false ? (
                                        <div className="col-sm-6 col-12 mb-3" style={{ height: "100%" }}>
                                            <div className="incomplete_add" style={{ height: "100%" }}>
                                                <h5>Language Speaks</h5>
                                                <div className='d-flex justify-content-between'>
                                                    <p className='mb-1'>Add Language Speaks</p>
                                                    <h6 className='text-end ' style={{ cursor: "pointer" }} onClick={handleLangauge}>Add</h6>
                                                </div>
                                            </div>
                                        </div>
                                    ) : null}
                                    {add.profile_manage_status === false ? (
                                        <div className="col-sm-6 col-12 mb-3" style={{ height: "100%" }}>
                                            <div className="incomplete_add" style={{ height: "100%" }}>
                                                <h5>Profile Manage By</h5>
                                                <div className='d-flex justify-content-between'>
                                                    <p className='mb-1'>Add Profile Manage By</p>
                                                    <h6 className='text-end ' style={{ cursor: "pointer" }} onClick={handleManage}>Add</h6>
                                                </div>
                                            </div>
                                        </div>
                                    ) : null}
                                    {add.settling_abroad_status === false ? (
                                        <div className="col-sm-6 col-12 mb-3" style={{ height: "100%" }}>
                                            <div className="incomplete_add" style={{ height: "100%" }}>
                                                <h5>Add Employee-in & Settling Abroad</h5>
                                                <div className='d-flex justify-content-between'>
                                                    <p className='mb-1'>Employee-in & Settling Abroad</p>
                                                    <h6 className='text-end ' style={{ cursor: "pointer" }} onClick={handleSettle}>Add</h6>
                                                </div>
                                            </div>
                                        </div>
                                    ) : null}
                                    {add.social_media_status === false ? (
                                        <div className="col-sm-6 col-12 mb-3" style={{ height: "100%" }}>
                                            <div className="incomplete_add" style={{ height: "100%" }}>
                                                <h5>Social Media</h5>
                                                <div className='d-flex justify-content-between'>
                                                    <p className='mb-1'>Add Social Media Links</p>
                                                    <h6 className='text-end ' style={{ cursor: "pointer" }} onClick={handleSocial}>Add</h6>
                                                </div>
                                            </div>
                                        </div>
                                    ) : null}
                                    {add.whatsapp_status === false ? (
                                        <div className="col-sm-6 col-12 mb-3" style={{ height: "100%" }}>
                                            <div className="incomplete_add" style={{ height: "100%" }}>
                                                <h5>Whatsapp Verification</h5>
                                                <div className='d-flex justify-content-between'>
                                                    <p className='mb-1'>Complete Your Whatsapp Verification</p>
                                                    <h6 className='text-end ' style={{ cursor: "pointer" }} onClick={handleWhatsapp}>Add</h6>
                                                </div>
                                            </div>
                                        </div>
                                    ) : null}
                                    {add.birth_time_status === false ? (
                                        <div className="col-sm-6 col-12 mb-3" style={{ height: "100%" }}>
                                            <div className="incomplete_add" style={{ height: "100%" }}>
                                                <h5>Birth Details</h5>
                                                <div className='d-flex justify-content-between'>
                                                    <p className='mb-1'>Add Birth Time & Birth Place</p>
                                                    <h6 className='text-end ' style={{ cursor: "pointer" }} onClick={handleBirth}>Add</h6>
                                                </div>
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Inclomplete;