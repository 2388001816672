import React from "react";
import MessagesComponent from "../../Sections/Profile/MessagesComponent/MessagesComponent";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import img1 from "../../Assets/profile2/img1.jpg";
import Message from "../../Components/Message/Message";
import SendIcon from "@mui/icons-material/Send";
import {
  EmojiEmotions,
  EmojiEmotionsOutlined,
  Search,
} from "@mui/icons-material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { BASE_URL } from "../../BASE_URL";
import axios from 'axios';
import { useState, useEffect, useRef } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { addDoc, collection, doc, onSnapshot, query, orderBy, where, getDocs, getDoc, updateDoc, Firestore, getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { app, db } from '../../firebase';
import { v4 as uuidv4 } from 'uuid';

const SideMessage = () => {

  const location = useLocation();
  const navigate = useNavigate();


  const [Yay, setYay] = useState([]);
  const token = localStorage.getItem('token');
  const paid_member = localStorage.getItem('memberType');
  
  const [userDetailsArray, setUserDetailsArray] = useState([]);

  const fetchData = async () => {
    try {
      const res = await fetch(`${BASE_URL}/api/message/allMessageDisplay`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
      });
      const data = await res.json();
      console.log(data)
      setYay(data?.data || []);
      setUserDetailsArray(data?.data || []);
    } catch (error) {
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
;

  const [openMessages, setOpenMessages] = useState(false);
  const [messagesClasses, setMessagesClasses] = useState(
    "profile__messages_wrapper"
  );


  useEffect(() => {
    const token = localStorage.getItem("saptvidhiUserToken");
    if (!token) {
      navigate("/");
    }
  }, []);

  const handleViewAll = () => {
    if (!openMessages) {
      setMessagesClasses(
        "profile__messages_wrapper profile__messages_wrapper_full"
      );
      setOpenMessages(true);
    } else {
      setMessagesClasses("profile__messages_wrapper");
      setOpenMessages(false);
    }
  };

  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };


  const handleNavigate = (e) => {
    navigate('/messages', { state: { data: e } })
  }





  return (
    <div className="messages">
      <div className="messages_right">
        <div className="profile__messages">
          <Link to="/profile" className="porfile__messages_back_icon">
            <ArrowBackIcon />
          </Link>
          <div className="profile__messages_messages__header">
            <div className="mprofile__messages_messages__header_details">
              <h3>
                New Message <span>({userDetailsArray.length})</span>
              </h3>
            </div>
            <button className="messages__view_all" onClick={() => handleViewAll()}>
              {openMessages ? "Close All" : "View All"}
            </button>
          </div>
          <div className={messagesClasses}>
            {userDetailsArray && userDetailsArray.length > 0 ? (
              userDetailsArray.map((profile, index) => (
                <div key={index} className="profilemessagecard" style={{ cursor: "pointer" }} onClick={() => handleNavigate(profile.profile)}>
                  <img src={profile.profile.profile_photo} style={{ borderRadius: "50%" }} alt="" />
                  <div className="profilemessage__details">
                    <p className="profilemessage__name mb-0"><b>{profile.profile.user_name}</b></p>
                    <p>{profile.latestMessage.message}</p>
                  </div>
                  <div className="profilemessage__time">
                    <p className="profilemessage_day">Today</p>
                    <p className="profilemessage_unread_messages">1</p>
                  </div>
                </div>
              ))
            ) : (
              <p>No messages to display</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SideMessage;
