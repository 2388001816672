import React from 'react';
import "./weddingblog.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faCalendar } from '@fortawesome/free-solid-svg-icons';

const WeddingBlog = () => {
    return (
        <>
            <div className="">
                <div className='wedding-blog-grid-border'>
                    <div>
                        <img src="./wedding-blog-1.png" alt="" />
                    </div>
                    <div className='d-flex flex-column justify-content-center'>
                        <h5> <FontAwesomeIcon icon={faCalendar} /> 02-03-2024</h5>
                        <h6>Isha And Anmol</h6>
                        <p>We’ve been a nearly strategic error sit voluptatem accuatie laudantie totam aperiam</p>
                        <div className='wedding-blog-owner-name'>
                            <img src="./wedding-blogger.png" alt="" />
                            <p>Isha</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default WeddingBlog
