import React, { useState } from 'react';
import "./forgotpassword.css";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import OtpInput from 'react-otp-input';
import { auth } from '../../firebase';
import { signInWithPhoneNumber, RecaptchaVerifier } from "firebase/auth";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BASE_URL } from '../../BASE_URL';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

function Forgotpassword() {

    const navigate = useNavigate();

    const [showPassword, setShowPassword] = useState(false);
    const [showPassword1, setShowPassword1] = useState(false);

    const [mobileNumber, setMobileNumber] = useState('');
    const [otp, setOtp] = useState('');
    const [userOtp, setUserOtp] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [showNewPasswordFields, setShowNewPasswordFields] = useState(true);
    const [myUser, setMyUser] = useState(null);
    const [captcha, setCaptcha] = useState(true);

    const handleOtpChange = (otp) => {
        // Check if the entered value is a number
        if (!isNaN(otp) || otp === '') {
            setUserOtp(otp);
        }
    };

    const handleNumberChange = (e) => {
        setMobileNumber(e.target.value)
        const fpass = e.target.value;
        localStorage.setItem("forgotPassword", fpass)
    }

    const handleClose = () => {
        setShowModal(false);
        setOtp('');
        setMobileNumber('');
    };

    const handleSendOtp = async () => {
        if (!mobileNumber) {
            toast.error('Please Enter Your Registered Mobile Number!', {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 2000,
            });
            return;
        }
        if (mobileNumber.length !== 10 || isNaN(mobileNumber)) {
            toast.error('Please Enter a Valid 10 Digit Mobile Number!', {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 2000,
            });
            return;
        }

        const number = "+91" + mobileNumber;
        const random = Math.floor(Math.random() * 900000) + 100000;

        const url = `https://rslri.connectbind.com:8443/bulksms/bulksms?username=DG35-webearl&password=digimile&type=0&dlr=1&destination=${number}&source=WEBEAR&message=Dear User, Your one time password is ${random} and it is valid for 10 minutes. Do not share it to anyone. Thank you, Team WEBEARL TECHNOLOGIES.&entityid=1101602010000073269&tempid=1107169899584811565`;

        setShowModal(true);
        setOtp(random);

        try {
            const response = await axios.get(url);
            console.log(response);
            if (response.status === 200) {
                toast.success('OTP sent successfully!', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 2000,
                });
            } else {
                toast.error('Failed to send OTP. Please try again.', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 2000,
                });
            }
        } catch (error) {
            console.error('Error sending OTP:', error);
            toast.error('Error sending OTP. Please check your network connection and try again.', {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 2000,
            });
        }
    };





    const handleKeyDown = (e) => {
        if ([46, 8, 9, 27, 13].indexOf(e.keyCode) !== -1 ||
            (e.keyCode === 65 && e.ctrlKey === true) ||
            (e.keyCode === 67 && e.ctrlKey === true) ||
            (e.keyCode === 86 && e.ctrlKey === true) ||
            (e.keyCode >= 35 && e.keyCode <= 39)) {
            return;
        }
        if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
            e.preventDefault();
        }
    };


    const sendOtp = async () => {
        const number = "+91" + mobileNumber;

        localStorage.setItem("forgotNumber", number);

        try {
            const recaptcha = new RecaptchaVerifier(auth, "recaptcha", {})
            const confirmation = await signInWithPhoneNumber(auth, number, recaptcha)
            setMyUser(confirmation);
            if (confirmation) {
                setCaptcha(false)
            } else {
                setCaptcha(true)

            }
            setShowModal(true);

        } catch (error) {
            console.log("Error sending OTP:", error);
        }
    }

    const verifyOtp = async () => {

        if (!userOtp) {
            toast.error('Please Enter Otp!', {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 2000,
            });
            return;
        }

        if (userOtp.length < 6) {
            toast.error('Please Enter Valid Otp!', {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 2000,
            });
            return;
        }

        if (otp != userOtp) {
            toast.error('Enter Valid OTP!', {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 2000,
            });
            return;
        }

        if (otp == userOtp) {
            try {
                toast.success('OTP verified successfully!', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 2000,
                });
                setShowNewPasswordFields(true);
                handleClose();
            } catch (error) {
                console.error('Error verifying OTP:', error);
                toast.error('Wrong Otp', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 2000,
                });
            }
        }
    };

    const [newPass, setNewPass] = useState("")
    const [oldPass, setOldPass] = useState("")

    const handleNewpassword = (e) => {
        setNewPass(e.target.value)
    }
    const handleOldpassword = (e) => {
        setOldPass(e.target.value)
    }


    const handleNewPasswordSubmit = async () => {

        if (!newPass && !oldPass) {
            toast.error('Please Enter New Password and Confirm Password!', {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 2000,
            });
            return;
        }

        if (!newPass) {
            toast.error('Please Enter New Password!', {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 2000,
            });
            return;
        }

        if (!oldPass) {
            toast.error('Please Enter Confirm Password!', {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 2000,
            });
            return;
        }

        if (newPass != oldPass) {
            toast.error('Please New Password And Confirm Password Not Matched!', {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 2000,
            });
            return;
        }

        const myNumber = localStorage.getItem("forgotPassword")

        const number = "+91" + myNumber;


        try {
            const res = await fetch(`${BASE_URL}/api/profile/forgatepassword`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    contact_no: number,
                    new_password: newPass,
                }),
            });

            if (res.ok) {
                localStorage.removeItem("forgotPassword");
                toast.success('Password Updated Successfully!', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 2000,
                });
                setTimeout(() => {
                    navigate("/login")
                }, 2000);
            } else {
                // Handle non-successful response
                console.error('Failed to submit new password:', res.statusText);
                toast.error('Failed to submit new password!', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 2000,
                });
            }
        } catch (error) {
            // Handle fetch error
            console.error('Error submitting new password:', error);
            toast.error('Error submitting new password!', {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 2000,
            });
        }

    };


    return (
        <div className='container'>
            <div className="forgotpassword">
                <div className='forgot_password_box'>

                    {showNewPasswordFields ? null : (
                        <div className='text-center back-white-shadow'>
                            <input
                                type="tel"
                                value={mobileNumber}
                                onInput={(e) => (e.target.value) = e.target.value.replace(/[^0-9]/g, '')}
                                onChange={
                                    // (e) => setMobileNumber(e.target.value)
                                    handleNumberChange
                                }
                                className='mb-4'
                                placeholder='Please enter mobile number'
                                onKeyDown={handleKeyDown}
                                maxLength={10}
                            />
                            <button className='opt-btn ' onClick={handleSendOtp}>
                                Send OTP
                            </button>
                        </div>
                    )}
                    {showNewPasswordFields ? (
                        <div className='text-center back-white-shadow'>
                            <div className='new-password-relative'>
                                <input type="password" className='mb-3' placeholder='New Password' onChange={handleNewpassword} />
                                <FontAwesomeIcon // Eye icon to toggle password visibility
                                    icon={showPassword ? faEyeSlash : faEye}
                                    className="eye-password"
                                    onClick={() => setShowPassword(!showPassword)}
                                />
                            </div>
                            <div className='confirm-password-relative'>
                                <input type="password" className='mb-5' placeholder='Confirm New Password' onChange={handleOldpassword} />
                                <FontAwesomeIcon // Eye icon to toggle password visibility
                                    icon={showPassword1 ? faEyeSlash : faEye}
                                    className="eye-password"
                                    onClick={() => setShowPassword1(!showPassword1)}
                                />
                            </div>
                            <button className='opt-btn' onClick={handleNewPasswordSubmit}>
                                Submit
                            </button>
                        </div>
                    ) : null}
                    {captcha && <div id="recaptcha"></div>}

                </div>
            </div>
            <Modal
                show={showModal}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Enter OTP</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='d-flex flex-column align-items-center'>

                        <OtpInput
                            value={userOtp}
                            onChange={handleOtpChange}
                            numInputs={6}
                            renderSeparator={<span>-</span>}
                            renderInput={(props) => <input {...props} />}
                        />
                        <div className='mt-5 text-end'>
                            <button className='close-modal-otp-btn' onClick={handleClose}>
                                Close
                            </button>
                            <button className='submit-modal-otp-btn ms-2' onClick={verifyOtp}>
                                Submit
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <ToastContainer />
        </div>
    )
}

export default Forgotpassword;
