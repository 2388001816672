import React, { useEffect, useState } from "react";
import DeclineRequestCard from "../../../Components/Profile2/DeclineRequestCard/DeclineRequestCard";
// import "./DeclinedRequests.css";
import { BASE_URL } from "../../../BASE_URL";
import CancelRequestCard from "../../../Components/Profile2/CancelRequestCatd/CancelRequestCard";


const CancelRequests = ({ horizontal, setValue }) => {
  var declinedRequestsClasses =
    horizontal === true
      ? "profile2_declined_request declined_request_horizontal"
      : "profile2_declined_request declined_request_vertical";

  const [error, setError] = useState(null);
  const [receivedData, setReceivedData] = useState([]);
  console.log(receivedData);
  // console.log(receivedData);
  const [filterOption, setFilterOption] = useState(""); // State to hold the selected filter option

  const fetchData = async (selectedOption) => {
    try {
      const response = await fetch(
        `${BASE_URL}/api/request/display_type`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("saptvidhiUserToken"),
          },
        }
      );

      if (!response.ok) {
        throw new Error("Request failed");
      }

      const responseData = await response.json();


      // const receivedRequests = responseData.data[0]?.profile || [];
      const iDeclineRequest = responseData.data.iDeclineRequests;
      console.log(responseData.data);

      if (selectedOption == "by me") {
        setReceivedData(responseData.data.icancelRequests);
      }
      
      if (selectedOption == "by they"){
        setReceivedData(responseData.data.theyCancelRequests);
      }
      
      if (!selectedOption){
        setReceivedData(responseData.data.icancelRequests);
      }

    } catch (error) {
      setError(error.message);
    }
  };



  useEffect(() => {
    fetchData();
  }, []);

  const reload = () => {
    fetchData();
  }

  const handleFilterChange = (event) => {
    const selectedOption = event.target.value;
    setFilterOption(selectedOption);
    fetchData(selectedOption); // Call fetchData with selected option
  };


  return (
    <>
      <div className="profile_declined_requests_wrapper_top mb-3">
        <h3 className="profile_requests_title">
          Cancel Requests <span>({receivedData.length})</span>
        </h3>
        {/* <p
          onClick={() => {
            setValue("4");
          }}
        >
          View All
        </p> */}
        <select
          name=""
          id=""
          className="i-decline-they-decline"
          onChange={handleFilterChange} // Call handleFilterChange on select change
        >
          <option value="by me">By Me</option>
          <option value="by they">By They</option>
        </select>
      </div>
      <div className="profile_declined_requests_wrapper">

        {receivedData.length === 0 ? (
          <div className="nothing-see">
            <p>No decline requests</p>
          </div>
        ) : (
          <div className={declinedRequestsClasses} id="card">
            {[...receivedData].reverse().map((request) => (
              <CancelRequestCard key={request._id} data={request} fetchData={reload} />
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default CancelRequests;
