import React, { useState } from 'react';
import "./vendorinfo.css";
import VendorPackage from '../../Components/VendorPackages/VendorPackage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo, faImages, faBookmark, faMessage, faCode } from '@fortawesome/free-solid-svg-icons';
import VendorInformation from '../../Components/VendorInformation/VendorInformation';
import VendorInquiries from '../../Components/Inquiries/Inquiries';

const VendorInfo = () => {

    const [activeLink, setActiveLink] = useState(0)


    return (
        <>
            <div className="container my-5">
                <div>
                    <div className="vendorinfo-grid">
                        <div className='vendor-info-menu-box'>
                            <h5 className={activeLink === 0 ? 'selected-vendor-info-menu' : ''} onClick={() => setActiveLink(0)}>
                                <FontAwesomeIcon icon={faCircleInfo} className='me-2' /> Information
                            </h5>
                            <h5 className={activeLink === 1 ? 'selected-vendor-info-menu' : ''} onClick={() => setActiveLink(1)}>
                                <FontAwesomeIcon icon={faCircleInfo} className='me-2' /> My Catalog
                            </h5>
                            <h5 className={activeLink === 2 ? 'selected-vendor-info-menu' : ''} onClick={() => setActiveLink(2)}>
                                <FontAwesomeIcon icon={faCircleInfo} className='me-2' /> Inquiries
                            </h5>
                            <h5 className={activeLink === 3 ? 'selected-vendor-info-menu' : ''} onClick={() => setActiveLink(3)}>
                                <FontAwesomeIcon icon={faImages} className='me-2' /> Project
                            </h5>
                            <h5 className={activeLink === 4 ? 'selected-vendor-info-menu' : ''} onClick={() => setActiveLink(4)}>
                                <FontAwesomeIcon icon={faBookmark} className='me-2' /> Membership Plans
                            </h5>
                            <h5 className={activeLink === 5 ? 'selected-vendor-info-menu' : ''} onClick={() => setActiveLink(5)}>
                                <FontAwesomeIcon icon={faMessage} className='me-2' /> Reviews
                            </h5>
                            <h5 className={activeLink === 6 ? 'selected-vendor-info-menu' : ''} onClick={() => setActiveLink(6)}>
                                <FontAwesomeIcon icon={faCode} className='me-2' /> Google My Business
                            </h5>
                        </div>

                        {activeLink === 0 ? (
                            <>
                            </>
                        ) : activeLink === 1 ? (
                            <>
                                <VendorInformation />
                            </>
                        ) : activeLink === 2 ? (
                            <>
                                <VendorInquiries />
                            </>
                        ) : activeLink === 3 ? (
                            <>
                            </>
                        ) : activeLink === 4 ? (
                            <>
                                <VendorPackage />
                            </>
                        ) : activeLink === 5 ? (
                            <></>
                        ) : (
                            <></>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}

export default VendorInfo
