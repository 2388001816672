import React, { useEffect, useState } from "react";
import "./BlockProfiles.css";
import img1 from "../../../Assets/profile2/img1.jpg";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import LanguageIcon from "@mui/icons-material/Language";
import PlaceIcon from "@mui/icons-material/Place";
import SchoolIcon from "@mui/icons-material/School";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import PersonIcon from "@mui/icons-material/Person";
import BoltIcon from "@mui/icons-material/Bolt";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import HeightIcon from "@mui/icons-material/Height";
import TempleHinduIcon from "@mui/icons-material/TempleHindu";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import ChatIcon from "@mui/icons-material/Chat";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import StarOutlinedIcon from "@mui/icons-material/StarOutlined";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import UploadIcon from "@mui/icons-material/Upload";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import Modal from "react-bootstrap/Modal";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BASE_URL } from "../../../BASE_URL";

const MatchesRequests = ({ horizontal, setValue }) => {
  var declinedRequestsClasses =
    horizontal === true
      ? "profile2_declined_request declined_request_horizontal"
      : "profile2_declined_request declined_request_vertical";

  const [error, setError] = useState(null);
  const [receivedData, setReceivedData] = useState([]);

  useEffect(() => {
    getAllShortListed();
  }, []);

  const [shortData, setShortData] = useState();

  const getAllShortListed = async () => {
    try {
      await axios
        .get(`${BASE_URL}/api/blockuser`, {
          headers: { Authorization: localStorage.getItem("saptvidhiUserToken") },
        })
        .then((res) => {
          setReceivedData(res.data.data);
        })
        .catch((err) => alert(err));
    } catch (err) {
      alert(err);
    }
  };

  const navigate = useNavigate();


  const [modalStates, setModalStates] = useState({});

  const [showModal1, setShowModal1] = useState(false);

  const openModal = (userId) => {
    // Set the modal state to true for the specified user
    setModalStates((prevStates) => ({
      ...prevStates,
      [userId]: true,
    }));
  };

  const closeModal = (userId) => {
    // Set the modal state to false for the specified user
    setModalStates((prevStates) => ({
      ...prevStates,
      [userId]: false,
    }));
  };

  const isModalOpen = (userId) => modalStates[userId] || false;

  const handleUnblock = async (id) => {
    try {
      await axios
        .delete(`${BASE_URL}/api/blockuser?_id=${id}`, {
          headers: { Authorization: localStorage.getItem("saptvidhiUserToken") },
        })
        .then((res) => {
          if (res.data.code === 200) {
            const user = receivedData.find((user) => user._id === id);
            getAllShortListed();
            toast.success(`${user.profile[0].user_name} Unblock Successfully`, {
              position: toast.POSITION.BOTTOM_RIGHT,
              autoClose: 1000,
            });
            closeModal(id);
          }
        })
        .catch((err) => console.log(err));
    } catch (err) {
      alert(err);
    }
  };

  return (
    <div className="profile_declined_requests_wrapper">
      <div className="profile_declined_requests_wrapper_top">
        <h3 className="profile_requests_title">
          Blocked Profiles <span>({receivedData.length})</span>
        </h3>
        {/* <p
          onClick={() => {
            setValue("4");
          }}
        >
          View All
        </p> */}
      </div>
      {receivedData && receivedData.length > 0 ? (
        <div className={declinedRequestsClasses} id="card">
          {receivedData?.map((data) => (
            <div className="received_request_card">
              <div className="received_request_card_left" style={{ position: "relative" }}>
                <div
                  className="left_intro_group"
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    navigate(`/userprofile/${data.profile?.[0]?._id}`, {
                      state: { status: "block", id: data._id },
                    })
                  }
                >
                  <img src={data && data?.profile?.[0]?.profile_photo} alt="" />
                  <div className="received_request_card_name">
                    <PersonIcon sx={{ height: "15px" }} />
                    {data && data?.profile?.[0]?.user_name}
                  </div>
                </div>
              </div>
              <div className="received_request_card_right">
                <div
                  className="card_right_top"
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    navigate(`/userprofile/${data.profile[0]._id}`, {
                      state: { status: "block", id: data._id },
                    })
                  }
                >
                  <p>
                    <TrendingUpIcon sx={{ height: "15px" }} />
                    {/* 23 yrs, 5’8” */}
                    {data && data?.profile?.[0]?.age} yrs,{" "}
                    {data && data?.profile?.[0]?.height}
                  </p>
                  <p>
                    <LanguageIcon sx={{ height: "15px" }} />
                    {/* Gujarati, Ahir */}
                    {data && data?.profile?.[0]?.mother_tongue}
                  </p>
                  <p>
                    <PlaceIcon sx={{ height: "15px" }} />
                    {/* Surat, Gujarat */}
                    {data && data?.profile?.[0]?.home_town},{" "}
                    {data && data?.profile?.[0]?.state}
                  </p>
                  <p>
                    <TempleHinduIcon sx={{ height: "15px" }} />
                    {/* Software Developer / Programmar */}
                    {data && data?.profile?.[0]?.religion} , {data && data?.profile?.[0]?.community}
                  </p>
                  <p>
                    {/* B.E./B.Tech */}
                    <WorkOutlineIcon sx={{ height: "15px" }} />
                    {data && data?.profile?.[0]?.highest_qualification}
                  </p>
                </div>
                <p
                  className=""
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    navigate(`/userprofile/${data?.profile?.[0]?._id}`, {
                      state: { status: "block" },
                    })
                  }
                >
                  {data && data?.profile?.[0]?.bio}
                  <span
                    style={{ color: "#CF166F", cursor: "pointer", fontSize: "10px" }}
                    onClick={() => navigate("/selectplans")}
                  >
                    {" "}
                    Upgrade Now
                  </span>
                </p>
                <div
                  style={{ display: "flex", justifyContent: "center", width: "100%" }}
                >
                  <div style={{ display: "grid", placeItems: "center" }}>
                    <div style={{ marginLeft: "20px", textAlign: "center" }}>
                      <CancelOutlinedIcon
                        sx={{ height: "25px", cursor: "pointer" }}
                        onClick={() => openModal(data._id)}
                      />
                      <p style={{ fontWeight: "400", fontSize: "10px" }} onClick={() => openModal(data._id)}>UnBlock</p>
                      <Modal show={isModalOpen(data._id)} onHide={() => closeModal(data._id)}>
                        <Modal.Header closeButton1>
                          <Modal.Title>Confirm Unblock</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          Are you sure you want to unblock this user?
                        </Modal.Body>
                        <Modal.Footer>
                          <button className="block_confirm_button_2" variant="secondary" onClick={() => closeModal(data._id)}>
                            No
                          </button>
                          <button className="block_confirm_button" variant="primary" onClick={() => handleUnblock(data._id)}>
                            Yes
                          </button>
                        </Modal.Footer>
                      </Modal>
                    </div>
                  </div>
                </div>
              </div>
              <ToastContainer />
            </div>
          ))}
        </div>
      ) : (
        <div className="nothing-see">
          <p>Nothing to see</p>
        </div>
      )}
    </div>
  );
};

export default MatchesRequests;
