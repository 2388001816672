import React from 'react';

const Circle2 = ({ percentage }) => {
  const radius = 40; // Radius of the circle
  const circumference = 2 * Math.PI * radius; // Circumference of the circle

  // Calculate the strokeDashoffset based on the percentage
  const strokeDashoffset = circumference - (percentage / 100) * circumference;

  const roundedInteger = Math.round(percentage)

  return (
    <svg viewBox="0 0 100 100" preserveAspectRatio="xMidYMid meet" xmlns="http://www.w3.org/2000/svg" style={{ maxWidth: '100%' }}>
      <circle
        cx="50%"
        cy="50%"
        r={radius}
        fill="transparent"
        stroke="#cf166f" // Orange color
        strokeWidth="10" // Stroke width
        strokeDasharray={circumference}
        strokeDashoffset={strokeDashoffset}
      />
      <text
        x="50%"
        y="50%"
        textAnchor="middle"
        dy=".3em"
        fontSize="16"
        fill="#000" // Text color
      >
        {roundedInteger}%
      </text>
    </svg>
  );
};

export default Circle2;
