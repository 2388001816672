import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./Header.css";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../../BASE_URL";
import Offcanvas from 'react-bootstrap/Offcanvas';
import Modal from 'react-bootstrap/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRightFromBracket } from '@fortawesome/free-solid-svg-icons';

import { useAuthContext } from "../../context/AuthContext";

const Header = () => {
  const { setAuthUser } = useAuthContext();
  const navigate = useNavigate();

  const location = useLocation();

  const isVendorIncluded = location.pathname.includes("vendor");
  const [vendor, setVendor] = useState(isVendorIncluded);

  useEffect(() => {
    setVendor(location.pathname.includes("vendor"));
  }, [location]);

  const [name, setName] = useState("");
  const naviGATE = useNavigate();
  const token = localStorage.getItem("saptvidhiUserToken");

  const getUset = async () => {
    const res = await fetch(`${BASE_URL}/api/profile/userdetails`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    const data = await res.json();

    const fullName = data?.data?.UserDetails?.user_name;
    const names = fullName?.split(" ");

    // Check if there are at least two names
    if (names?.length >= 2) {
      const firstName = names[0];
      const lastNameInitial = names[1].charAt(0);

      const abbreviatedName = `${firstName} ${lastNameInitial}`;
      setName(abbreviatedName)
    } else {
      setName(data?.data?.UserDetails?.user_name);
    }


  };

  useEffect(() => {
    if (token) {
      getUset();
    }
  }, [token]);

  const [openDropDown, setOpenDropDown] = useState(false);

  // Toggle the dropdown menu
  const handleClickMenu = () => {
    setOpenDropDown(!openDropDown);
  };

  const goToProfile = () => {
    navigate("/userprofile")
    setOpenDropDown(false)
  }

  const goToMessages = () => {
    navigate("/messages")
    setOpenDropDown(false)
  }

  const goToBlockList = () => {
    navigate("/blocklist")
    setOpenDropDown(false)
  }

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const navigate1 = useNavigate();

  const handleNav = async () => {
    if (token && token.length > 0) {
      navigate1("/profile");
      await getUset();
    } else {
      navigate1("/");
    }
  };

  const [show1, setShow1] = useState(false);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => {
    setShow1(true)
    setOpenDropDown(false)
  }


  const handleLogout = () => {
    handleShow1();
  }

  const handleLog = () => {
    localStorage.clear("userToken");
    localStorage.clear("memberType");
    setAuthUser(null)
    handleClose1();
    navigate("/login");
  }

  const handleNo = () => {
    handleClose1();
  }

  const goToChangePassword = () => {
    navigate("/change-password")
  }

  const handleLogin = () => {
    navigate("/login")
  }


  return (
    <div className="header">
      <div className="h2__link ms-md-5 ms-sm-4 ms-3 ">
        <div>
          {vendor ? (
            <img src="/SaptaVidhi T.png" className="img_header_logo header-logo-photo" onClick={() => naviGATE("/vendor-homepage")} alt="" />
          ) : (
            <img src="/SaptaVidhi T.png" className="img_header_logo header-logo-photo" onClick={handleNav} alt="" />
          )}
        </div>
      </div>
      <Modal
        show={show1}
        onHide={handleClose1}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body>
          <div className="text-center">
            <FontAwesomeIcon icon={faRightFromBracket} style={{ fontSize: "40px" }} />
          </div>
          <p className="text-center mt-2" style={{ fontSize: "20px", fontWeight: "600" }}>Are you sure want to log out?</p>
          <div className="d-flex justify-content-center mt-4 " style={{ gap: "20px" }}>
            <button className="logout-yes-no-1" onClick={handleNo}>
              No
            </button>
            <button onClick={handleLog} className="logout-yes-no-2">Yes</button>
          </div>
        </Modal.Body>
      </Modal>
      <div className="header__links w-100">
        <Link to={token ? "/profile" : "/"}>Home</Link>
        {token ? (
          <Link to="/requests">Requests</Link>
        ) : (
          ""
        )}
        <Link to={token ? '/SelectPlan' : '/SelectPlan'}>{token ? 'Our Plans' : 'Our Plans'}</Link>
        <Link to={token ? '/ContactUs' : '/ContactUs'}>{token ? 'Contact Us' : 'Contact Us'}</Link>

        {token ? (
          <>
            <div className="header-menu-open">
              <p
                className="AfterLogheader__button mb-0"
                onClick={handleClickMenu}
              >
                {name}
              </p>
              {openDropDown && (
                <div className="header-menu-options">
                  <ul>
                    <li onClick={goToProfile}>My Profile</li>
                    <li onClick={goToMessages}>Chat</li>
                    <li onClick={goToBlockList}>Block List</li>
                    <li onClick={goToChangePassword}>Change Password</li>
                    <li onClick={handleShow1}>Logout</li>
                  </ul>
                </div>
              )}
            </div>
          </>
        ) : (
          <>
            {vendor ? (
              <p className="header__button" onClick={() => naviGATE("/vendor-sign-in")} style={{ marginRight: "35px" }}>
                Login
              </p>
            ) : (
              <p className="header__button" onClick={() => naviGATE("/login")} style={{ marginRight: "35px" }}>
                Login
              </p>
            )}
          </>
        )}
      </div>
      <div className="side_navbar">
        <button onClick={handleShow}>
          <img src="/side-nav.png" alt="" className="img_for_side_nav" />
        </button>

        <Offcanvas show={show} backdropClassName="hyyyyyyy" onHide={handleClose}>
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>
              <div>
                <img src="/SaptaVidhi T.png" onClick={handleNav} alt="" style={{ width: "100px", cursor: "pointer" }} />
              </div>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body className="side_nav_body">
            <div>
              <ul className="list-unstyled side_nav_link">
                <li>
                  <Link
                    style={{ textDecoration: "none", color: "black" }}
                    to="/"
                    onClick={handleClose}
                  >
                    Home
                  </Link>
                </li>
                {token && (
                  <li>
                    <Link
                      to="/userprofile"
                      style={{ textDecoration: "none", color: "black" }}
                      onClick={handleClose}
                    >
                      My Profile
                    </Link>
                  </li>
                )}
                {token && (
                  <li>
                    <Link
                      to="/blocklist"
                      style={{ textDecoration: "none", color: "black" }}
                      onClick={handleClose}
                    >
                      Block List
                    </Link>
                  </li>
                )}
                {token && (
                  <li>
                    <Link
                      style={{ textDecoration: "none", color: "black" }}
                      to="/requests"
                      onClick={handleClose}
                    >
                      Requests
                    </Link>
                  </li>
                )}
                <li>
                  <Link
                    style={{ textDecoration: "none", color: "black" }}
                    to="/SelectPlan"
                    onClick={handleClose}
                  >
                    Our Plans
                  </Link>
                </li>
                <li>
                  <Link
                    style={{ textDecoration: "none", color: "black" }}
                    to="/ContactUs"
                    onClick={handleClose}
                  >
                    Contact Us
                  </Link>
                </li>
              </ul>
              {token ? (
                <div className="Logout_btn_nav">
                  <button
                    onClick={() => {
                      handleLogout();
                    }}
                  >
                    Log Out
                  </button>
                </div>
              ) : (
                <div className="Logout_btn_nav">
                  <button
                    onClick={handleLogin}
                  >
                    Login
                  </button>
                </div>
              )}
            </div>
          </Offcanvas.Body>
        </Offcanvas>
      </div>
    </div>
  );
};

export default Header;
