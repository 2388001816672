import React, { useEffect, useState } from "react";
// import "./DeclineRequestCard.css";
import img1 from "../../../Assets/profile2/img1.jpg";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import LanguageIcon from "@mui/icons-material/Language";
import PlaceIcon from "@mui/icons-material/Place";
import SchoolIcon from "@mui/icons-material/School";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import PersonIcon from "@mui/icons-material/Person";
import BoltIcon from "@mui/icons-material/Bolt";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import HeightIcon from "@mui/icons-material/Height";
import TempleHinduIcon from "@mui/icons-material/TempleHindu";
// import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import ChatIcon from "@mui/icons-material/Chat";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { useNavigate } from "react-router-dom";
import RemoveDoneIcon from "@mui/icons-material/RemoveDone";
import LoopIcon from "@mui/icons-material/Loop";
import crown from "../crown.png"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCrown } from '@fortawesome/free-solid-svg-icons';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BASE_URL } from "../../../BASE_URL";

const CancelRequestCard = ({ data, fetchData }) => {

  // console.log(data);

  const [dats, setDats] = useState("");
  const [display, setDisplay] = useState(false);

  useEffect(() => {
    if (data) {
      if (data.sender_profile) {
        setDats(data.sender_profile)
        setDisplay(true)
      } else if (data.receiver_profile) {
        setDats(data.receiver_profile)
      }
    }
  }, [data]);


  const navigate = useNavigate();
  const declineRequest = async () => {
    try {
      const response = await fetch(`${BASE_URL}/api/request/changestatus`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("saptvidhiUserToken"),
        },
        body: JSON.stringify({
          request_id: data._id,
          new_status: "pending",
        }),
      });
      if (response.status == 200) {
        fetchData()
        toast.success('Request Accept Successfully!', {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 1000,
        });
      }
    } catch (err) {
      alert(err);
    }
  };

  const member_type = localStorage.getItem("memberType");

  return (
    <div className="received_request_card mb-sm-0 mb-3"

    >
      <div className="received_request_card_left" style={{ position: "relative" }}
        onClick={() => {
          navigate(`/userprofile/${dats?.[0]?._id}`, {
            state: { status: data.request_status, id: data._id },
          });
        }}
      >
        {data.sender_profile?.[0]?.member_type === "paid" && (
          <img
            src={crown}
            className="premium-user-crown"
            alt=""
          />
        )}
        <div className="left_intro_group">
          <img
            src={dats?.[0]?.profile_photo}
            alt=""
            style={{ cursor: "pointer" }}

          />
          <div className="received_request_card_name">
            <PersonIcon sx={{ height: "15px" }} />
            {dats?.[0]?.user_name}
          </div>
        </div>
      </div>
      <div className="received_request_card_right">
        <div
          className="card_right_top"
          style={{ cursor: "pointer" }}
          onClick={() => {
            navigate(`/userprofile/${dats?.[0]?._id}`, {
              state: { status: dats, id: data._id },
            });
          }}
        >
          <p>
            <TrendingUpIcon sx={{ height: "15px" }} />
            {dats && dats?.[0]?.age} yrs, {dats && dats?.[0]?.height}
          </p>
          <p>
            <LanguageIcon sx={{ height: "15px" }} />
            {dats && dats?.[0]?.mother_tongue}
          </p>
          <p>
            <PlaceIcon sx={{ height: "15px" }} />
            {dats && dats?.[0]?.home_town}, {dats && dats?.[0]?.state}
          </p>
          <p>
            <TempleHinduIcon sx={{ height: "15px" }} />
            {dats && dats?.[0]?.religion} , {dats && dats?.[0]?.community}
          </p>
          <p>
            <WorkOutlineIcon sx={{ height: "15px" }} />
            {dats && dats?.[0]?.highest_qualification}
          </p>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
          }}
        >
          {display ? (
            <>

            </>
          ) : (
            <div style={{ marginLeft: "15px", textAlign: "center" }}>
              <LoopIcon
                sx={{ height: "25px", cursor: "pointer" }}
                onClick={declineRequest}
              />
              <p style={{ fontWeight: "400", fontSize: "10px" }}>
                Resend
              </p>
            </div>

          )}
          {member_type === "paid member" ? (<>
            <div style={{ marginLeft: "15px", textAlign: "center" }}>
              <span
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/SelectPlan")}
              >
                {" "}
                <FontAwesomeIcon icon={faCrown} style={{ fontSize: "20px" }} />
                <p style={{ fontWeight: "400", fontSize: "10px" }} >Upgrade Now</p>
              </span>
            </div>
          </>) : (
            <>

            </>
          )}

        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default CancelRequestCard;
