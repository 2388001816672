import React, { useState, useEffect } from "react";
import ProfileMessageCard from "../../../Components/ProfileMessageCard/ProfileMessageCard";
import "./MessagesComponent.css";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { BASE_URL } from "../../../BASE_URL";

const MessagesComponent = ({ users, userDetailsArray, onSelectUser }) => {
  const [openMessages, setOpenMessages] = useState(false);
  const [messagesClasses, setMessagesClasses] = useState(
    "profile__messages_wrapper"
  );

  const navigate = useNavigate();

  useEffect(() => {
    // Check if token is not available
    const token = localStorage.getItem("saptvidhiUserToken");
    if (!token) {
      // If token is not available, navigate to the home page
      navigate("/");
    }
  }, []);

  const handleViewAll = () => {
    if (!openMessages) {
      setMessagesClasses(
        "profile__messages_wrapper profile__messages_wrapper_full"
      );
      setOpenMessages(true);
    } else {
      setMessagesClasses("profile__messages_wrapper");
      setOpenMessages(false);
    }
  };

  const token = localStorage.getItem('token');


  return (
    <></>
  );
};

export default MessagesComponent;
