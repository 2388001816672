import { Search } from "@mui/icons-material";
import React, { useEffect, useState, useRef } from "react";
import PlanCard from "../../Components/PlanCard/PlanCard";
import "./SelectPlan.css";
import { useNavigate } from "react-router-dom";
import { faChevronRight, faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BASE_URL } from "../../BASE_URL";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";

const MembershipPlan = () => {
  const [receivedData, setReceivedData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const scrollContainer = useRef(null);

  const scrollLeft = () => {
    if (scrollContainer.current) {
      scrollContainer.current.scrollBy({
        left: -100,
        behavior: "smooth",
      });
    }
  };

  const scrollRight = () => {
    if (scrollContainer.current) {
      scrollContainer.current.scrollBy({
        left: 100,
        behavior: "smooth",
      });
    }
  };


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${BASE_URL}/api/membership_plan`,
          {
            method: "GET",
            headers: {
              "Content-type": "application/json",
              Authorization: localStorage.getItem("saptvidhiUserToken"),
            },
          }
        );
        if (!response.ok) {
          throw new error("Request Failed");
        }



        const responseData = await response.json();

        setReceivedData(responseData.data);
        console.log(responseData.data);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const renderFeatures = (data) => {


    const sortedFeatures = data.membership_feature_id.sort((a, b) => {

      if (a.membership_feature_status === b.membership_feature_status) {
        return 0;
      }

      if (a.membership_feature_status) {
        return -1;
      }
      return 1;
    });

    return sortedFeatures.map((feature) => {
      const matchingFeature = data.membership_feature_name.find(
        (item) => item._id === feature.membership_id
      );
      if (matchingFeature) {
        return (
          <div className="d-flex px-2">
            <p style={{ fontSize: "12px" }}>
              {feature.membership_feature_status ? (
                <FontAwesomeIcon icon={faCheck} />
              ) : (
                <FontAwesomeIcon icon={faTimes} />
              )}{" "}
            </p>
            <p className="ps-1" style={{ fontSize: "12px", color: "rgba(0, 0, 0, 0.9)" }}>
              {matchingFeature.feature_name}
            </p>
          </div>
        );
      }
      return null;
    });
  };

  const contactNoFromLocalStorage = localStorage.getItem("contact_no");
  const formattedContactnum = localStorage.getItem("contactnumber");

  let contactNo;

  if (contactNoFromLocalStorage) {
    contactNo = contactNoFromLocalStorage;
  } else {
    contactNo = "+91" + formattedContactnum;
  }





  const token = localStorage.getItem("saptvidhiUserToken")
  const navigate = useNavigate();

  const handlePrice = async (planDetail) => {
    if (!token) {
      navigate("/login")
      return;
    } else {
      navigate("/package-payment", { state: planDetail?._id  });
    }
  };



  return (
    <div className="selectplan">
      <div className="selectplan__upper">
        <p>Upgrade immediately to enjoy the premium benefits!!</p>
        <h2>
          Select Your <span>Plan</span>
        </h2>
        <p>Choose the plan that’s right for you.</p>
      </div>

      <div className="selectplan__middle">
      </div>
      <div className="selectplan__lower" ref={scrollContainer}>


        {loading ? (
          <p>Loading...</p> // Show loading message while data is being fetched
        ) : receivedData.length === 0 ? (
          <p>No plans available</p> // Show a message if no plans are fetched
        ) : (
          receivedData &&
          receivedData.map((request) => (
            <div className="for_height">
              <div className="plan_backg">
                <div className="h-100 w-100 d-flex justify-content-center align-items-center">
                  <div className="plan_card_content d-flex flex-column justify-content-between py-4">
                    <div className="">
                      <div className="plan_header_card text-center">
                        <h5>{request.plan_name}</h5>
                        <p className="mb-1">₹{request.plan_selling_price}</p>
                        <p className="mb-1">{request.plan_days} Days Plan</p>
                        <p>{request.contact_limit} Contact Limits</p>
                      </div>
                      <div className="plan_body_card">
                        <div className="text-center">
                          <h4>Features</h4>
                        </div>
                      </div>
                      <div className="plan_footer_card">
                        <div className="">
                          {renderFeatures(request)}
                        </div>
                      </div>
                    </div>

                    <div className="plan_btn_footer text-center py-2">
                      <button onClick={() => handlePrice(request)}>
                        Continue
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )))}

      </div>
      <div className="buttons_slider">
        <FontAwesomeIcon className="left_btn_icon slide_icon me-3" icon={faChevronLeft} onClick={scrollLeft} />
        <FontAwesomeIcon className="right_btn_icon slide_icon" icon={faChevronRight} onClick={scrollRight} />
      </div>
    </div>
  );
};
export default MembershipPlan;






// const options = {
//   key: "rzp_test_jq9ZGGAqPS8vVx",
//   amount: sellingPrice * 100,
//   currency: "INR",
//   name: "Saptavidhi",
//   description: "Membership Plan Payment",
//   handler: async function (response) {



//     try {
//       const res = await fetch(`${BASE_URL}/api/booking/insert`, {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//           Authorization: token,
//         },
//         body: JSON.stringify({
//           membership_plan_id: memberId,
//           payment: "upi", // Assuming response contains necessary payment details
//         }),
//       });
//       if (!res.ok) {
//         throw new Error("Failed to update membership plan");
//       }
//       // Handle success
//       alert("Payment successful! Membership plan updated.");
//       // Redirect to profile or any other page
//       // navigate("/profile")
//     } catch (error) {
//       // Handle error
//       console.error("Error updating membership plan:", error);
//       alert("Error updating membership plan. Please try again.");
//     }
//   },
//   prefill: {
//     contact: contactNo,
//   },
// };

// const razorpay = new window.Razorpay(options);
// razorpay.open();