import React, { useEffect, useState } from "react";
import "./Profile2.css";
import Notifications from "../../Sections/Profile/Notifications/Notifications";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import ReceivedRequests from "../../Sections/Profile2/ReceivedRequests/ReceivedRequests";
import MatchesRequests from "../../Sections/Profile2/MatchesRequests/MatchesRequests";
import AcceptedRequests from "../../Sections/Profile2/AcceptedRequestsCard/AcceptedRequestsCard";
import DeclinedRequests from "../../Sections/Profile2/DeclinedRequests/DeclinedRequests";
import SentRequests from "../../Sections/Profile2/SentRequests/SentRequests";
import { BASE_URL } from "../../BASE_URL";
import ShortList from "../ShortListProfiles/ShortList";
import SideMessage from "../../Components/SideMessage/Sidemessage";
import CancelRequests from "../../Sections/Profile2/CancelRequests/CancelRequests";

const Requests = () => {
  const [value, setValue] = useState("1");
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("saptvidhiUserToken");
    if (!token) {
      navigate("/");
    }
  }, []);



  const FetchRequest = async (event) => {
    try {
      const response = await fetch(
        `${BASE_URL}/api/request`
      );
      if (!response.ok) {
        throw new Error("Request failed");
      }
      const responseData = await response.json();
      setData(responseData);
    } catch (error) {
      setError(error.message);
    }
  };

  const handleNotificationClick = (notificationType) => {

    if (notificationType === "request decline") {
      setValue("5")
    }
    if (notificationType === "request sent") {
      setValue("2")
    }
    if (notificationType === "request accept") {
      setValue("3")
    }

  };


  return (
    <div className="profile2">
      <div className="profile2_left">
        <Box className="tab-container-requests">
          <TabContext value={value} >
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                display: "flex",
                gap: "20px",
                position: "sticky",
                top: "0px",
                zIndex: "111",
                backgroundColor: "white",
                padding: "20px 0px"
              }}
            >
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
                className="exapmle"
                TabIndicatorProps={{
                  sx: { backgroundColor: "rgba(207, 22, 111, 0.5)" },
                }}
                sx={{
                  "& button": { fontFamily: "poppins" },
                  "& button:active": { color: "rgba(207, 22, 111, 0.5)" },
                  "& button.Mui-selected": { color: "rgba(207, 22, 111, 0.5)" },
                  display: "flex",
                  gap: "30px",
                }}
              >
                <Tab label="Matches" value="1" />
                <Tab
                  label="Received request"
                  value="2"
                  onClick={FetchRequest}
                />
                <Tab
                  label="Accepted request"
                  value="3"
                />
                <Tab
                  label="Sent request"
                  value="4"
                />
                <Tab
                  label="Declined request"
                  value="5"
                />
                <Tab
                  label="Short List"
                  value="6"
                />
                <Tab
                  label="Cancel Requests"
                  value="7"
                />
              </TabList>
            </Box>
            <TabPanel value="1" className="tabs-padding">
              <MatchesRequests horizontal={false} />
            </TabPanel>
            <TabPanel value="2" className="tabs-padding">
              <ReceivedRequests horizontal={false} />
            </TabPanel>
            <TabPanel value="3" className="tabs-padding">
              <AcceptedRequests horizontal={false} />
            </TabPanel>
            <TabPanel value="4" className="tabs-padding">
              <SentRequests horizontal={false} />
            </TabPanel>
            <TabPanel value="5" className="tabs-padding">
              <DeclinedRequests horizontal={false} />
            </TabPanel>
            <TabPanel value="6" className="tabs-padding">
              <ShortList horizontal={false} />
            </TabPanel>
            <TabPanel value="7" className="tabs-padding">
              <CancelRequests horizontal={false} />
            </TabPanel>
          </TabContext>
        </Box>
      </div>
      <div>
        <div className="profile2_right row h-100">
          <div className="col-12">
            <Notifications onNotificationClick={handleNotificationClick} />
          </div>

          <div className="col-12 sidemessage_stuck">
            <div>
              <div className="">
                <SideMessage />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Requests;
