import React from "react";
import Download from "../../Components/Download/Download";
import { useState, useEffect } from "react";
import { BASE_URL } from "../../BASE_URL";

const PrivacyPolicy = () => {


  const [Yay, setYay] = useState("")

  const token = localStorage.getItem('token');

  const Call = async () => {
    const res = await fetch(`${BASE_URL}/api/term_and_condition`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    const data = await res.json();
    setYay(data.data[0]);
  };

  useEffect(() => {
    Call();
  }, []);


  const stripHTMLTags = (html) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;
    return tempDiv.textContent || tempDiv.innerText || "";
  };

  const plainTextContent = stripHTMLTags(Yay.term_and_condition);


  return (
    <>



      <div className="container__wrapper font-h1-policies">
      <h2 className="text-center mb-3 mt-3 fw-bold" style={{fontSize: "36px"}}>T&C and Privacy Policies</h2>

        {/* <div>{plainTextContent}</div> */}

        <div className="font-size-h1">
          <div style={{ textAlign: 'justify' }} dangerouslySetInnerHTML={{ __html: Yay.term_and_condition }} />
        </div>
      </div>
      {/* <Download /> */}
    </>
  );
};

export default PrivacyPolicy;
