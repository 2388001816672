import React, { useState } from 'react';
import "./banquethall.css"
import BanquetHallCard from '../../Components/BanquetHallCard/BanquetHallCard';
import BanquetHallReviewCard from '../../Components/BanquetHallReviewCard/BanquetHallReviewCard';

const BanquetHall = () => {

    const data = [
        "why",
        "why",
        "why",
        "why",
        "why",
        "why",
        "why",
        "why"
    ]

    const data2 = [
        "why",
        "why",
        "why",
        "why"
    ]

    const [menu, setMenu] = useState("wedding")

    return (
        <>
            <div className='container'>
                <div className='mt-5'>
                    <div className='mb-2'>
                        <h3 className='fw-semibold mb-1'>Banquet Halls</h3>
                        <p>Showing <span style={{ color: "gray", opacity: "0.95", fontWeight: "600" }}> 23,148 results </span> as per your search criteria </p>
                    </div>

                    <div className='banquet-hall-filters mb-5'>
                        <div className="banquet-hall-filter-grid">
                            <div>
                                <select name="" id="">
                                    <option value="">No. Of Guests</option>
                                </select>
                            </div>
                            <div>
                                <select name="" id="">
                                    <option value="">Price Per Plate</option>
                                </select>
                            </div>
                            <div>
                                <select name="" id="">
                                    <option value="">Rental Cost</option>
                                </select>
                            </div>
                            <div>
                                <select name="" id="">
                                    <option value="">Venue Type</option>
                                </select>
                            </div>
                            <div>
                                <select name="" id="">
                                    <option value="">Space</option>
                                </select>
                            </div>
                            <div>
                                <select name="" id="">
                                    <option value="">Room Count</option>
                                </select>
                            </div>
                            <div>
                                <select name="" id="">
                                    <option value="">Rating</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div className='banquet-hall-grid mb-5'>
                        {data.map((e) => (
                            <>
                                <BanquetHallCard />
                            </>
                        ))}
                    </div>
                </div>
                <div className='banquet-hall-review-container mt-5 pt-3'>
                    <div className='banquet-hall-review-header mb-3'>
                        <h3 className='fw-semibold mb-1'>Latest Reviews</h3>
                    </div>
                    <div className='banquet-hall-review-grid'>
                        {data2.map((e) => {
                            return (
                                <BanquetHallReviewCard />
                            )
                        })}
                    </div>
                </div>
            </div>
        </>
    )
}

export default BanquetHall
