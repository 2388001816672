import React from 'react';
import "./feeaturedvendors.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';

const FeaturedVendor = () => {
    return (
        <>
            <div className="">
                <div className='popular-search-box'>
                    <div className='featured-vendor-bg'>
                        <img src="./featured-vendor-1.png" alt="" className='img-fluid' />
                        <div className='featured-vendor-content'>
                            <div className='d-flex justify-content-between'>
                                <span className='ps-3'>Wedding</span>
                                <h5 className='mb-0'><FontAwesomeIcon icon={faStar} style={{color: "gold"}} /> 4.9</h5>
                            </div>
                            <h6 className='ps-3 mt-2'>Wedding catering, sohana road</h6>
                            <span className='ps-3'>Rs. 1500 onwards</span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FeaturedVendor;
