import React, { useState, useEffect, useRef } from "react";
import CarouselCard from "../../../Components/CarouselCard/CarouselCard";
import logo1 from "../../../Assets/carousel/img1.png";
import logo2 from "../../../Assets/carousel/img2.png";
import logo3 from "../../../Assets/carousel/img3.png";
import logo5 from "../../../Assets/carousel/img5.png";
import "./Carousel.css";

const Carousel = () => {
  const elements = [
    {
      logo: logo1,
      heading: "Free Chat",
      cardno: "1",
      content:
        "Choose us for genuine matches, free chats, and a seamless, trustworthy experience designed to help you find true love.",
    },
    {
      logo: logo2,
      heading: "Read Biodata",
      cardno: "2",
      content:
        "Experience unmatched connections with our unique voice feature, making your biodata come alive and enhancing every match effortlessly.",
    },
    {
      logo: logo3,
      heading: "Person Verification",
      cardno: "3",
      content:
        "Reliable, thorough, meticulous screening ensuring perfect matches for a lifetime of happiness. Trust us for authentic connections",
    },
    {
      logo: logo5,
      heading: "Community wise match",
      cardno: "4",
      content:
        "Exclusive community-centric matchmaking, fostering cultural connection. Discover your perfect match within our vibrant community network.",
    },
  ];

  const [active, setActive] = useState(1);
  const carouselRef = useRef(null);

  const btnpressprev = () => {
    if (active > 1) {
      const scrollWidth = carouselRef.current.clientWidth > 430 ? 430 : carouselRef.current.clientWidth - 80;
      carouselRef.current.scrollLeft -= scrollWidth;
      setActive((prevActive) => prevActive - 1);
    }
  };

  const btnpressnext = () => {
    if (active < elements.length) {
      const scrollWidth = carouselRef.current.clientWidth > 430 ? 430 : carouselRef.current.clientWidth - 80;
      carouselRef.current.scrollLeft += scrollWidth;
      setActive((prevActive) => prevActive + 1);
    }
  };

  return (
    <div className="container">
      <div className="homepage-carousel">
        <div className="carousel_container" ref={carouselRef}>
          {elements.map((element) => (
            <CarouselCard
              key={element.cardno}
              active={active}
              btnpressnext={btnpressnext}
              btnpressprev={btnpressprev}
              cardno={element.cardno}
              logo={element.logo}
              heading={element.heading}
              content={element.content}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Carousel;
