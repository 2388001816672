import React, { useEffect, useState } from 'react';
import "./catalogcard.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot, faCircle, faEye, faPencil, faXmark } from '@fortawesome/free-solid-svg-icons';

const CustomModal = ({ show, handleClose }) => {

    const modalStyle = {
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.6)',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: "1111"
        },
        content: {
            marginTop: "100px",
            backgroundColor: 'white',
            width: '900px',
            borderRadius: '8px',
            position: 'relative',
            opacity: 1,
        },
    };

    return (
        <>
            {show && (
                <div style={modalStyle.overlay}>
                    <div style={modalStyle.content}>
                        <div>
                            <div className='catalog-modal-title'>
                                <h1 className='mb-0'>Add New Catalog</h1>
                                <button onClick={handleClose}><FontAwesomeIcon icon={faXmark} /></button>
                            </div>
                            <div className='modal-catalog-form-1'>
                                <h2>Catalog Information</h2>
                                <hr />
                                <div className='modal-catalog-form-1-grid'>
                                    <div>
                                        <label htmlFor="">Property Name</label>
                                        <input type="text" placeholder='Property Name' />
                                    </div>
                                    <div>
                                        <label htmlFor="">Parking Capacity</label>
                                        <select name="" id="">
                                            <option value="">Parking Capacity</option>
                                        </select>
                                    </div>
                                    <div>
                                        <label htmlFor="">Catering Policy</label>
                                        <select name="" id="">
                                            <option value="">Catering Policy</option>
                                        </select>
                                    </div>
                                    <div>
                                        <label htmlFor="">Start Of Venue</label>
                                        <input type="text" placeholder='Property Name' />
                                    </div>
                                    <div>
                                        <label htmlFor="">Decor Policy</label>
                                        <select name="" id="">
                                            <option value="">Parking Capacity</option>
                                        </select>
                                    </div>
                                    <div>
                                        <label htmlFor="">DJ Policy</label>
                                        <select name="" id="">
                                            <option value="">Catering Policy</option>
                                        </select>
                                    </div>
                                    <div>
                                        <label htmlFor="">Room Count</label>
                                        <select name="" id="">
                                            <option value="">Catering Policy</option>
                                        </select>
                                    </div>
                                    <div>
                                        <label htmlFor="">Room Price</label>
                                        <input type="text" placeholder='Property Name' />
                                    </div>
                                    <div>
                                        <label htmlFor="">Small Party Venue</label>
                                        <select name="" id="">
                                            <option value="">Catering Policy</option>
                                        </select>
                                    </div>
                                    <div>
                                        <label htmlFor="">Space</label>
                                        <input type="text" placeholder='Property Name' />
                                    </div>
                                    <div>
                                        <label htmlFor="">Hall Seating Area</label>
                                        <select name="" id="">
                                            <option value="">Parking Capacity</option>
                                        </select>
                                    </div>
                                    <div>
                                        <label htmlFor="">Lawn Seating Area</label>
                                        <select name="" id="">
                                            <option value="">Catering Policy</option>
                                        </select>
                                    </div>
                                    <div>
                                        <label htmlFor="">Veg Price</label>
                                        <input type="text" placeholder='Property Name' />
                                    </div>
                                    <div>
                                        <label htmlFor="">Non Veg Price</label>
                                        <input type="text" placeholder='Property Name' />
                                    </div>
                                </div>
                            </div>
                            <div className='modal-catalog-form-1'>
                                <h2>Address Information</h2>
                                <hr />
                                <div className='modal-catalog-form-1-grid'>
                                    <div>
                                        <label htmlFor="">City</label>
                                        <select name="" id="">
                                            <option value="">Parking Capacity</option>
                                        </select>
                                    </div>
                                    <div>
                                        <label htmlFor="">State</label>
                                        <select name="" id="">
                                            <option value="">Parking Capacity</option>
                                        </select>
                                    </div>
                                    <div>
                                        <label htmlFor="">Pincode</label>
                                        <input type="text" placeholder='Property Name' />
                                    </div>
                                </div>
                                <div className='text-input-ofpincode'>
                                    <label htmlFor="">Pincode</label>
                                    <input type="text" placeholder='Property Name' />
                                </div>
                            </div>
                            <div className='modal-catalog-form-1'>
                                <div className='d-flex justify-content-between'>
                                    <h2>Upload Images of Products</h2>
                                    <button>Choose file</button>
                                </div>
                                <hr />
                                <div className='modal-catalog-form-grid'>
                                    <img src="./catalog-slider.png" alt="" />
                                    <img src="./catalog-slider.png" alt="" />
                                    <img src="./catalog-slider.png" alt="" />
                                    <img src="./catalog-slider.png" alt="" />
                                    <img src="./catalog-slider.png" alt="" />
                                </div>
                            </div>
                            <div className='d-flex justify-content-end pb-4 pt-2 modal-catalog-form-1'>
                                <button>Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );

}

const CatalogCard = (onGet) => {

    const [data, setData] = useState("")

    useEffect(() => {
        if (onGet) {
            setData(onGet)
        }
    }, [onGet]);

    const [isOpen, setIsOpen] = useState(false);

    const [show, setShow] = useState(false);

    const handleClose = () => {
        setShow(false);
    };

    const handleOpen = () => {
        setShow(true);
    };


    return (
        <>
            <div className='catalog-card-border '>
                <div className='d-flex gap-3'>
                    <div>
                        <img src="./catalog-card.png" alt="" />
                    </div>
                    <div className='w-100 pe-4 d-flex flex-column justify-content-center'>
                        <div className='d-flex justify-content-between'>
                            <h4>The Beginning</h4>
                            <div className='catalog-card-edit'>
                                <span onClick={() => setIsOpen(true)}><FontAwesomeIcon icon={faEye} className='catalog-card-eye' /></span>
                                <span onClick={handleOpen}><FontAwesomeIcon icon={faPencil} className='catalog-card-edit-btn' /></span>
                            </div>
                        </div>
                        <h6>Banquet halls, Lawns / Farm</h6>
                        <p><FontAwesomeIcon icon={faLocationDot} /> Vashi, Navi Mumbai</p>
                        <div className='banquet-hall-amineties'>
                            <span>100-2000 Pax</span>
                            <span>100-2000 Pax</span>
                            <span>100-2000 Pax</span>
                        </div>
                        <div className='banquet-price'>
                            <div className='banquet-price-first'>
                                <FontAwesomeIcon icon={faCircle} className='banquet-red-icon' />
                                <h5>₹2,000</h5>
                            </div>
                            <div className='banquet-price-second'>
                                <FontAwesomeIcon icon={faCircle} className='banquet-green-icon' />
                                <h5>₹2,000</h5>
                            </div>
                        </div>
                    </div>
                </div>

                <CustomModal show={show} handleClose={handleClose} />

            </div>
        </>
    )
}

export default CatalogCard
