import React from 'react';
import "./weddingstory.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTag } from '@fortawesome/free-solid-svg-icons';

const WeddingStory = () => {
    return (
        <>
            <div className="">
                <div className='popular-search-box'>
                    <div className='wedding-story-card-bg'>
                        <img src="./wedding-story.png" alt="" className='img-fluid' />
                        <div className='wedding-story-content'>
                            <span className='ps-3'><FontAwesomeIcon icon={faTag} style={{marginRight: "3px"}} /> Wedding</span>
                            <h6 className='ps-3 mt-3'>Isha and Anmol</h6>
                            <p className='ps-3'>We’ve been a nearly strategic error sit voluptatem accuatie laudantie totam aperiam</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default WeddingStory;
