import React, { useEffect, useState } from 'react';
import "./payment.css"
import { BASE_URL } from '../../BASE_URL';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes, } from '@fortawesome/free-solid-svg-icons';

const Payment = () => {

    const location = useLocation();
    const { planDetail } = location.state;

    const [planInfo, setPlanInfo] = useState('')

    useEffect(() => {
        setPlanInfo(planDetail)
    }, [planDetail]);

    console.log(planDetail)



    return (
        <>
            <div className="container">
                <div className="payment-grid">
                    {/* <div className="for_height">
                        <div className="plan_backg">
                            <div className="h-100 w-100 d-flex justify-content-center align-items-center">
                                <div className="plan_card_content d-flex flex-column justify-content-between py-4">
                                    <div className="">
                                        <div className="plan_header_card text-center">
                                            <h5>{planInfo.plan_name}</h5>
                                            <p className="mb-1">₹{planInfo.plan_selling_price}</p>
                                            <p className="mb-1">{planInfo.plan_days} Days Plan</p>
                                            <p>{planInfo.contact_limit} Contact Limits</p>
                                        </div>
                                        <div className="plan_body_card">
                                            <div className="text-center">
                                                <h4>Features</h4>
                                            </div>
                                        </div>
                                        <div className="plan_footer_card">
                                            <div className="">
                                                {renderFeatures(planInfo)}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="plan_btn_footer text-center py-2">
                                        <button onClick={() => handlePrice(planInfo.plan_selling_price, planInfo._id, planInfo.plan_name)}>
                                            Continue
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    <div>
                        {/* {displayMethod && (
                            <iframe src={displayMethod} frameborder="0" className='payment-method-box'></iframe>
                        )} */}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Payment
