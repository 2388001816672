import {
  CalendarMonth,
  FemaleOutlined,
  Language,
  LocationCityOutlined,
  PersonOutline,
} from "@mui/icons-material"; import React, { useEffect, useState } from "react";
import axios from "axios";
import "./EditPersonalDetail.css";
import "../Login/Login.css";
import { BASE_URL } from "../../BASE_URL";
import Modal from 'react-bootstrap/Modal';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../UserProfile/UserProfile.css'
import Select from "react-select";
import makeAnimated from 'react-select/animated';
import latestCountries from "../../CountryStateCity.json"
import { useNavigate } from "react-router-dom";

const EditPersonalDetail = ({ updateBio }) => {

  const navigate = useNavigate();

  const [otherInfo, setOtherInfo] = useState([]);
  const [currentLocation, setCurrentLocation] = useState([]);
  const [hobbies, setHobbies] = useState([]);
  const [aassets, setAssets] = useState([])
  const [langaugeSpeak, setLangaugeSpeak] = useState([]);

  const [Yay, setYay] = useState("")

  const Call = async () => {
    const token = localStorage.getItem("saptvidhiUserToken");
    const res = await fetch(`${BASE_URL}/api/profile/userdetails`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    const data = await res.json();
    setYay(data.data.UserDetails);
  };

  const checkProfile = async () => {
    const token = localStorage.getItem("saptvidhiUserToken");
    const res = await fetch(
      `${BASE_URL}/api/profile/checkProfile`,
      {
        method: "POST",
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    );
    const data = await res.json();

    if (data.data.user[0].user_other_infos) {
      setOtherInfo(data.data.user[0].user_other_infos)
      setHobbies(data.data.user[0].user_other_infos.hobbies_name)
      setLangaugeSpeak(data.data.user[0].user_other_infos.languages_and_speak)
      setCurrentLocation(data.data.user[0].user_other_infos)
      setAssets(data.data.user[0].user_other_infos.assets)
    }
  };


  useEffect(() => {
    Call();
    checkProfile();
  }, []);



  return (
    <div>
      <div className="r_login__wrapper1">


        <div>
          <div className="userprofile_section_header">
            <h3>Basic Details</h3>
            <button className="edit_btn_profile" style={{ cursor: "pointer" }} onClick={() => navigate("/edit-my-information")} >
              Edit
            </button>

          </div>
          <div>
            <div className="row">
              <div className="col-6 mb-2">
                <div className="userprofile_basic_details_content">
                  <FemaleOutlined />
                  <p className="mb-0" title="Gender">{Yay.gender}</p>
                </div>
              </div>
              <div className="col-6 mb-2">
                <div className="userprofile_basic_details_content">
                  <Language />
                  <p className="mb-0" title="Community">{Yay.community}</p>
                </div>
              </div>
              <div className="col-6 mb-2">
                <div className="userprofile_basic_details_content">
                  <CalendarMonth />
                  <p className="mb-0" title="Date Of Birth">
                    {Yay.dob}
                    {otherInfo && otherInfo.birth_time && (
                      <>
                        <span>({otherInfo.birth_time})</span>
                      </>
                    )}
                  </p>
                </div>
              </div>
              <div className="col-6 mb-2">
                <div className="userprofile_basic_details_content">
                  <PersonOutline />
                  <p className="mb-0" title="Religion">{Yay.religion}</p>
                </div>
              </div>
              {otherInfo && otherInfo.birth_place !== undefined && (
                <div className="col-sm-6 col-12 mb-2">
                  <div className="userprofile_basic_details_content">
                    <p className="mb-0" title="Religion">
                      <span style={{ fontWeight: "600" }}>Birth Place : </span>
                      {otherInfo.birth_place}
                    </p>
                  </div>
                </div>
              )}
              <div className="col-sm-6 col-12 mb-2">
                <div className="userprofile_basic_details_content">
                  <LocationCityOutlined />
                  <p className="mb-0" title="City , State And Country">Live in {Yay.home_town} , {Yay.state} , {Yay.country} </p>
                </div>
              </div>
              <div className="col-6 mb-1">
                <div className="userprofile_basic_details_content">
                  <img
                    src="weddingRing.png"
                    style={{
                      height: "20px",
                      fontWeight: "600",
                      paddingRight: "5px",
                    }}
                  />
                  <p className="mb-0" title="Marital Status">{Yay.marital_status}</p>
                </div>
              </div>


              <div className="pb-2">
                <hr />
              </div>

              {/* hobbies  */}

              {hobbies && hobbies.length > 0 && (
                <div className="col-12 mb-2" style={{ overflowWrap: "anywhere" }}>
                  <div className="userprofile_basic_details_content user-profile-by-id-hobbies-display">
                    <h6 className="mb-0 width_sm_hobby">Hobbies :</h6>
                    <div style={{ display: "flex", flexWrap: "wrap", rowGap: "8px" }}>

                      {hobbies && hobbies.map((e) => {
                        return (
                          <>
                            <p className="mb-0 label_for_p" title="Hobbies">{e}</p>
                          </>
                        )
                      })}
                    </div>
                  </div>
                </div>
              )}

              {aassets && aassets.length > 0 && (

                <div className="col-12 mb-2" style={{ overflowWrap: "anywhere" }}>
                  <div className="userprofile_basic_details_content">
                    <h6 className="mb-0 width_sm_hobby">Assets :</h6>
                    <div style={{ display: "flex", flexWrap: "wrap", rowGap: "8px" }}>

                      {aassets && aassets.map((e) => {
                        return (
                          <p className="mb-0 label_for_p" title="Assets">{e}</p>
                        )
                      })}
                    </div>
                  </div>
                </div>
              )}

              {langaugeSpeak.length > 0 ? (
                <div className="col-12 mb-2">
                  <div className="userprofile_basic_details_content">
                    <h6 className="mb-0">Langauge Speak :</h6>
                    {langaugeSpeak.map((e) => (
                      <p className="mb-0 label_for_p" title="Language Speaks" key={e}>
                        {e}
                      </p>
                    ))}
                  </div>
                </div>
              ) : null}

              {currentLocation && currentLocation.length > 0 && (
                <div className="col-sm-6 col-12 mb-2">
                  <div className="userprofile_basic_details_content">
                    <h6 className="mb-0">current location :</h6>
                    <p className="mb-0" title="current cit, state, country">{currentLocation.current_location_city},{currentLocation.current_location_state},{currentLocation.current_location_country}</p>
                  </div>
                </div>
              )}


              {otherInfo && otherInfo.length > 0 && (
                <div className="col-sm-6 col-12">
                  <div className="userprofile_basic_details_content">
                    <h6 title="Settling In Abbroad">Settling In Abbroad? : {otherInfo.settling_abroad}</h6>
                  </div>
                </div>

              )}

              {Yay.settle_down ? (

                <div className="col-sm-6 col-12">
                  <ToastContainer />
                  <div className="userprofile_basic_details_content">
                    <p title="Planning to settle-down">Planning to settle-down in {Yay.settle_down}</p>
                  </div>
                </div>
              ) : null}

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditPersonalDetail;
