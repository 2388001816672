import React from "react";
import { BASE_URL } from "../../BASE_URL";
import { useState, useEffect } from "react";

const ContactUs = () => {

  const [address, setaddress] = useState("")
  const [contact, setcontact] = useState("")
  const [email, setemail] = useState("")
  const [title, settitle] = useState("")

  const Call = async () => {
    const res = await fetch(`${BASE_URL}/api/contect_us`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await res.json();
    setaddress(data?.data?.[0]?.address);
    setcontact(data?.data?.[0]?.contectno);
    setemail(data?.data?.[0]?.email);
    settitle(data?.data?.[0]?.title)
  };

  useEffect(() => {
    Call();
  }, []);

  const handlePhoneClick = (phoneNumber) => {
    window.location.href = `tel:+91${phoneNumber}`;
  };

  const handleEmailClick = (emailAddress) => {
    window.location.href = `mailto:${emailAddress}`;
  };


  return (
    <div className="container__wrapper contact__container">
      <div className="container ">
        <h2 className="say_hello">Say hello to us!</h2>
        <p>{title}</p>

        <h3>Address</h3>
        {address && address.map((e) => {
          return (
            <div className="mb-4" dangerouslySetInnerHTML={{ __html: e }} />
          )
        })}
        <div className="mb-5">
          <h3>Phone</h3>
          {contact && contact.map((e) => {
            return (
              <p className="mb-2" style={{ color: "#00000080", cursor: "pointer" }} onClick={() => handlePhoneClick(e)}>{"+91 " + e}</p>
            )
          })}
        </div>
        <h3>Email</h3>
        {email && email.map((e) => {
          return (
            <p style={{cursor: "pointer"}} onClick={() => handleEmailClick(e)}>{e}</p>
          )
        })}
      </div>
    </div>
  );
};

export default ContactUs;
