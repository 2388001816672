import React, { useState, useEffect } from "react";
import ProfileNotificationCard from "../../../Components/ProfileNotificationCard/ProfileNotificationCard";
import "./Notifications.css";
import img1 from "../../../Assets/signup/img1.png";
import "../../../Components/ProfileNotificationCard/ProfileNotificationCard.css"

import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import { BASE_URL } from "../../../BASE_URL";

const Notifications = ({ onNotificationClick }) => {
  const [openNotification, setOpenNotification] = useState(false);
  const [notificationsClasses, setNotificationsClasses] = useState(
    "profile__notifications_wrapper"
  );

  const handleViewAll = () => {
    if (!openNotification) {
      setNotificationsClasses(
        "profile__notifications_wrapper profile__notifications_wrapper_full"
      );
      setOpenNotification(true);
    } else {
      setNotificationsClasses("profile__notifications_wrapper");
      setOpenNotification(false);
    }
  };


  const [Yay, setYay] = useState("")

  const Call = async () => {
    const token = localStorage.getItem("saptvidhiUserToken");
    const res = await fetch(`${BASE_URL}/api/profile/notification`, {
      method: "GET",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
    });
    const data = await res.json();
    setYay(data.data);
  };

  useEffect(() => {
    Call();
  }, []);

  const handleNotificationClick = (notificationType) => {
    onNotificationClick(notificationType);
  }




  return (
    <div className="profile__notification">
      <div className="notification__header">
        <NotificationsNoneIcon />
        <div className="notification__header_details">
          <h3>
            Notifications
          </h3>
          {/* <p>You have 5 recommandation to chat</p> */}
        </div>

        <button
          className="notification__view_all"
          onClick={() => handleViewAll()}
        >
          {openNotification ? "Close All" : "View All"}
        </button>
      </div>
      <div className={notificationsClasses}>
        {Yay && Yay.length > 0 ? (
          Yay.map((e) => (
            <div className="ProfileNotificationCard" key={e.notificationId} style={{ cursor: "pointer" }} onClick={() => handleNotificationClick(e.type)}>
              <img src={e.profile_photo} alt="" style={{ height: "30px", width: "30px", borderRadius: "50%" }} />
              <p className="ProfileNotificationCard__message mb-0">
                <span style={{ fontWeight: "700" }}>{e.user_name}</span>
                <br />
                {e.massage}
              </p>
              <span className="ProfileNotificationCard__time">{e.createdAt.slice(11, 16)}</span>
            </div>
          ))
        ) : (
          <p>No notifications</p>
        )}
      </div>
    </div>
  );
};

export default Notifications;
