import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Registration3.css";
import { NavLink, useNavigate } from "react-router-dom";
import { BASE_URL } from "../../BASE_URL";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function RegisterFamilyDetails() {
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}/api/family`,
          {
            headers: {
              Authorization: localStorage.getItem("saptvidhiUserToken"),
            },
          }
        );
        setData(response.data.data);
        if (response.data.data !== null) {
          setSelectedFatherOccupation(response.data.data.father_occupation);
          setSelectedMotherOccupation(response.data.data.mother_occupation);
          setSelectedNumberOfBrothers(response.data.data.no_of_brother);
          setSelectedNumberOfSisters(response.data.data.no_of_sister);
          setSelectedNumberOfMarriedBrothers(
            response.data.data.no_of_married_brother
          );
          setSelectedNumberOfMarriedSisters(
            response.data.data.no_of_married_sister
          );
        }

      } catch (error) {
      }
    };
    fetchData();
  }, []);

  const token = localStorage.getItem("saptvidhiUserToken");

  let navigate = useNavigate();

  useEffect(() => {
    // Check if token is not available
    const token = localStorage.getItem("saptvidhiUserToken");
    if (!token) {
      // If token is not available, navigate to the home page
      navigate("/");
    }
  }, []);

  const handleSubmit = async () => {
    try {
      // Check for empty form fields
      if (
        !selectedFatherOccupation ||
        !selectedMotherOccupation ||
        !selectedNumberOfBrothers ||
        !selectedNumberOfSisters ||
        !selectedNumberOfMarriedBrothers ||
        !selectedNumberOfMarriedSisters
      ) { toast.error('Please Fill All Fields', {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 1000,
        });
        return;
      }

      if (!selectedFatherOccupation) {
        toast.error('Please Select Father Occupation', {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 1000,
        });
        return;
      }

      if (!selectedMotherOccupation) {
        toast.error('Please Select Mother Occupation', {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 1000,
        });
        return;
      }

      if (!selectedNumberOfBrothers) {
        toast.error('Please Select Number Of Brothers', {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 1000,
        });
        return;
      }

      if (!selectedNumberOfSisters) {
        toast.error('Please Select Number Of Sister', {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 1000,
        });
        return;
      }

      if (!selectedNumberOfMarriedBrothers) {
        toast.error('Please Select Number Of Married Brothers', {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 1000,
        });
        return;
      }

      if (!selectedNumberOfMarriedSisters) {
        toast.error('Please Select Number Of Married Sisters', {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 1000,
        });
        return;
      }

      if (data) {
        const response = await axios.put(
          `${BASE_URL}/api/family`,
          {
            father_occupation: selectedFatherOccupation,
            mother_occupation: selectedMotherOccupation,
            no_of_brother: selectedNumberOfBrothers,
            no_of_sister: selectedNumberOfSisters,
            no_of_married_brother: selectedNumberOfMarriedBrothers,
            no_of_married_sister: selectedNumberOfMarriedSisters,
          },
          {
            headers: {
              Authorization: token,
            },
          }
        );
      }
      else {
        const response = await axios.post(
          `${BASE_URL}/api/family`,
          {
            father_occupation: selectedFatherOccupation,
            mother_occupation: selectedMotherOccupation,
            no_of_brother: selectedNumberOfBrothers,
            no_of_sister: selectedNumberOfSisters,
            no_of_married_brother: selectedNumberOfMarriedBrothers,
            no_of_married_sister: selectedNumberOfMarriedSisters,
          },
          {
            headers: {
              Authorization: token,
            },
          }
        );

        toast.success('Family Detail Added Successfully', {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 2000, // Display for 2 seconds
        });


        // Navigate to the next page after a delay (2 seconds)
        setTimeout(() => {
          let path = `/partner-preference`;
          navigate(path);
          localStorage.setItem('multiplephoto', "family");
        }, 2000);
      }

      // Add your desired actions here, such as navigating to the next page
    } catch (error) {
      // Handle the error as needed (e.g., show error message)
    }

    // localStorage.setItem('multiplephoto', "family");
  };

  const [selectedFatherOccupation, setSelectedFatherOccupation] = useState("");
  const [selectedMotherOccupation, setSelectedMotherOccupation] = useState("");
  const [selectedNumberOfBrothers, setSelectedNumberOfBrothers] = useState("");
  const [selectedNumberOfSisters, setSelectedNumberOfSisters] = useState("");
  const [availableNumberOfMarriedBrothers, setAvailableNumberOfMarriedBrothers] = useState([]);
  const [availableNumberOfMarriedSisters, setAvailableNumberOfMarriedSisters] = useState([]);
  const [selectedNumberOfMarriedBrothers, setSelectedNumberOfMarriedBrothers] =
    useState("");
  const [selectedNumberOfMarriedSisters, setSelectedNumberOfMarriedSisters] =
    useState("");


  useEffect(() => {
    // Generate options based on selectedNumberOfBrothers
    const options = [];
    for (let i = 0; i <= selectedNumberOfBrothers; i++) {
      options.push(i);
    }
    setAvailableNumberOfMarriedBrothers(options);
  }, [selectedNumberOfBrothers]);

  useEffect(() => {
    // Generate options based on selectedNumberOfSisters
    const options = [];
    for (let i = 0; i <= selectedNumberOfSisters; i++) {
      options.push(i);
    }
    setAvailableNumberOfMarriedSisters(options);
  }, [selectedNumberOfSisters]);



  const [fatherOccupassion, setFatherOccupassion] = useState([])
  const [motherOccupassion, setMotherOccupassion] = useState([])


  const Call = async () => {
    const res = await fetch(`${BASE_URL}/api/father_occupation`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await res.json();
    setFatherOccupassion(data.data);
  };

  const Call2 = async () => {
    const res = await fetch(`${BASE_URL}/api/mother_occupation`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await res.json();
    setMotherOccupassion(data.data);
  };

  useEffect(() => {
    Call();
    Call2();
  }, []);

  const handleFamily = () => {
    navigate("/profile")
    localStorage.removeItem('family-detail');
  }



  return (
    <div>
      <div className="my_login__wrapepr family_wrapper">
        <div className="my_login my_login-padding family_reg_height py-5">
          <h2>Family Details</h2>
          <div className="gender_state">
            <p className="mb-1" style={{ fontWeight: "500" }}>Father Occupassion</p>
            <select
              className="gender rounded px-3 py-1"
              style={{ border: "1px solid #f97096" }}
              value={selectedFatherOccupation}
              name="father_occupation"
              onChange={(e) => setSelectedFatherOccupation(e.target.value)}
            >
              <option value="" selected>Select Father Occupassion</option>
              {fatherOccupassion.map((option) => (
                <option value={option.name}>
                  {option.name}
                </option>
              ))}
            </select>
          </div>



          <div className="gender_state">
            <p className="mb-1" style={{ fontWeight: "500" }}>Mother Occupassion</p>
            <select
              className="gender rounded px-3 py-1"
              style={{ border: "1px solid #f97096" }}
              value={selectedMotherOccupation}
              name="mother_occupation"
              onChange={(e) => setSelectedMotherOccupation(e.target.value)}
            >
              <option value="" selected>Select Mother Occupassion</option>
              {motherOccupassion.map((option) => (
                <option value={option.name}>
                  {option.name}
                </option>
              ))}
            </select>
          </div>
          <div className="gender_state">
            <p className="mb-1" style={{ fontWeight: "500" }}>Number Of Brother</p>
            <select
              className="gender rounded px-3 py-1"
              style={{ border: "1px solid #f97096" }}
              value={selectedNumberOfBrothers}
              onChange={(e) => setSelectedNumberOfBrothers(e.target.value)}
            >
              <option value="">No. Of Brother</option>
              {Array.from({ length: 8 }, (_, i) => (
                <option key={i} value={i}>
                  {i}
                </option>
              ))}
            </select>
          </div>
          <div className="gender_state">
            <p className="mb-1" style={{ fontWeight: "500" }}>Number Of Sister</p>
            <select
              className="gender rounded px-3 py-1"
              style={{ border: "1px solid #f97096" }}
              value={selectedNumberOfSisters}
              onChange={(e) => setSelectedNumberOfSisters(e.target.value)}
            >
              <option value="">No. Of Sister</option>
              {Array.from({ length: 8 }, (_, i) => (
                <option key={i} value={i}>
                  {i}
                </option>
              ))}
            </select>
          </div>
          <div className="gender_state">
            <p className="mb-1" style={{ fontWeight: "500" }}>Number Of Married Brother</p>
            <select
              className="gender rounded px-3 py-1"
              style={{ border: "1px solid #f97096" }}
              value={selectedNumberOfMarriedBrothers}
              onChange={(e) => setSelectedNumberOfMarriedBrothers(e.target.value)}
            >
              <option value="" selected>No. Of Married Brother</option>
              {availableNumberOfMarriedBrothers.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
          <div className="gender_state">
            <p className="mb-1" style={{ fontWeight: "500" }}>Number Of Married Sister</p>
            <select
              className="gender rounded px-3 py-1"
              style={{ border: "1px solid #f97096" }}
              value={selectedNumberOfMarriedSisters}
              onChange={(e) => setSelectedNumberOfMarriedSisters(e.target.value)}
            >
              <option value="">No. Of Married Sister</option>
              {availableNumberOfMarriedSisters.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
          <div id="recaptcha"></div>
          <button className="btn__next my_btn_next" onClick={handleSubmit}>
            Next
          </button>
          <p
            onClick={handleFamily}
            style={{ textAlign: "center", marginBottom: "15px" }}
          >
            skip
          </p>
        </div>
        <ToastContainer />
      </div>
    </div>
  );
}

export default RegisterFamilyDetails;
