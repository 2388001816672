import React from "react";
import "./Footer.css";
import android from "../../Assets/Download/android.png";
import ios from "../../Assets/Download/apple.png";
import facebook from "../../Assets/footer/facebook.png";
import insta from "../../Assets/footer/insta.png";
import linkedin from "../../Assets/footer/linkedin.png";
import youtube from "../../Assets/footer/youtube.png";
import { Link, useNavigate } from "react-router-dom";

const Footer = () => {

  const navigate = useNavigate();

  const handleNavigate = () => {
    const url = 'https://play.google.com/store/apps/details?id=com.saptavidhi&hl=en_US'; // Replace with your actual Google Play Store URL
    window.open(url, '_blank'); // Opens the URL in a new tab
  }


  return (
    <>
      <div className="footer">
        <div className="footer__links">
          <h3 className="footer_h3">SaptaVidhi</h3>
          <Link to="/career">Career</Link>
          <Link to="/comunity-guidelines">Community Guidelines</Link>
          {/* <Link to="/terms-and-conditions">Terms and conditions</Link> */}
          <Link to="/privacy-policy">T&C And Privacy Policy</Link>
          <Link to="/faqs">Frequently Asked Questions</Link>
          <Link to="/contactus">Contact us</Link>
          <Link to="/partner-community">Partner Community</Link>
        </div>
        <div className="footer__contact_us">
          <h3 className="footer_h3">Contact Us</h3>
          <a href="mailto:contact@saptavidhi.com" className="mb-2" target="_blank">contact@saptavidhi.com</a>
          <a href="tel:+91 96623 99609" target="_blank">+91 96623 99609</a>

          <div className="footer__button" onClick={handleNavigate}>
            <img src={android} className="download__logo me-2" alt="" />
            <div className="download__button__content">
              <span>GET IT ON</span>
              <p className="mb-1">Google Play Store</p>
            </div>
          </div>
        </div>
        <div className="footer__connect-us">
          <h3 className="footer_h3">Connect with Us</h3>
          <div className="connect__linkes">
            <a href="https://www.facebook.com/saptavidhi" target="_blank"><img className="footer__logo" src="/logos_facebook.png" alt="" /></a>
            <a href="https://www.instagram.com/saptavidhi/" target="_blank"><img className="footer__logo" src="/Instagram.png" alt="" /></a>
            <a href="https://www.linkedin.com/company/saptavidhi/" target="_blank"><img className="footer__logo" src="/Linkedin.png" alt="" target="_blank" /></a>
            <a href="https://twitter.com/SaptaVidhi" target="_blank"><img className="footer__logo" src="/x.jpg" alt="" target="_blank" /></a>
            <a href="https://www.youtube.com/@Saptavidhi" target="_blank"><img className="footer__logo" src="/youtube.jpg" alt="" target="_blank" /></a>
            {/* <img className="footer__logo" src={youtube} alt="" /> */}
          </div>
          <div className="footer__button">
            <img src={ios} className="me-2" alt="image not found" style={{ height: "20px", width: "20px" }} />
            <div className="download__button__content ">
              <span>GET IT ON</span>
              <p className="mb-1">App Store</p>
            </div>
          </div>
        </div>
      </div>
      <div className="w-100 text-center all-right-reserved">
        <h6 className="pt-4">Copyright c 2023. All rights reserved SAPTAVIDHI PVT LTD</h6>
      </div>
    </>
  );
};

export default Footer;
