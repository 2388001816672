import React from 'react';
import "./vendor-information.css"
import CatalogCard from '../CatalogCrad/CatalogCrad';

const VendorInformation = () => {

    const array = [
        { name: "yuvraj" },
        { name: "yuvraj" },
        { name: "yuvraj" },
        { name: "yuvraj" },
        { name: "yuvraj" },
    ]

    return (
        <>
            <div className="container">
                <div>
                    <div className='vendor-packages-border'>
                        <div>
                            <div className='vendor-catalog-title'>
                                <h5>My Catalog</h5>
                                <button>Add Catalog</button>
                            </div>
                            <hr />
                        </div>
                        <div className='catalogcard-grid'>
                            {array && array.map((e) => (
                                <CatalogCard onGet={e} />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default VendorInformation
