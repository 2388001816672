import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./Registration2.css";
import axios from "axios";
import { BASE_URL } from "../../BASE_URL";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import pako from "pako";

function RegisterQualification() {
  let navigate = useNavigate();
  const token = localStorage.getItem("saptvidhiUserToken");
  const location = useLocation();
  const data = location.state;
  const [higherQualification, setHigherQualification] = useState([]);
  const [jobTitle, setJobtitle] = useState([])
  const [salaryRange, setSalaryRange] = useState([]);
  const [formData, setFormData] = useState({
    highest_qualification: localStorage.getItem("myQualification"),
    college: localStorage.getItem("myCollage"),
    job_title: localStorage.getItem("myJobTitle"),
    company_name: localStorage.getItem("myCompanyName"),
    salary: localStorage.getItem("mySelectSalary"),
  });


  useEffect(() => {
    fetchHighestQualification();
    fetchSalaryRange();
    fetchJobTitle();
  }, []);

  const fetchHighestQualification = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/api/highest_qualification`
      );

      if (Array.isArray(response.data.data)) {
        setHigherQualification(response.data.data);
      } else {
        setHigherQualification([response.data.data]);
      }
    } catch (error) {
      console.error("Error fetching Higher Qualification status:", error);
    }
  };

  const fetchSalaryRange = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/api/salary`
      );
      if (Array.isArray(response.data.data)) {
        setSalaryRange(response.data.data);
      } else {
        setSalaryRange([response.data.data]);
      }
    } catch (error) {
      console.error("Error fetching Salary Range:", error);
    }
  };

  const fetchJobTitle = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/api/job_title`
      );
      if (Array.isArray(response.data.data)) {
        setJobtitle(response.data.data);
      } else {
        setJobtitle([response.data.data]);
      }
    } catch (error) {
      console.error("Error fetching Salary Range:", error);
    }
  };

  const handleSubmit = async () => {
    try {
      if (
        !formData.highest_qualification &&
        !formData.college &&
        !formData.job_title &&
        !formData.company_name &&
        !formData.salary
      ) {
        toast.error('Please fill all fields', {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 1000,
        });
        return;
      }

      if (!formData.highest_qualification) {
        toast.error('Please fill highest qualification fields', {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 1000,
        });
        return;
      }
      if (!formData.college) {
        toast.error('Please fill college fields', {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 1000,
        });
        return;
      }
      if (!formData.job_title) {
        toast.error('Please Select Job Title', {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 1000,
        });
        return;
      }
      if (!formData.company_name) {
        toast.error('Please Enter Company Name', {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 1000,
        });
        return;
      }
      if (!formData.salary) {
        toast.error('Please Select Your Salary Range', {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 1000,
        });
        return;
      }

      const combinedFormData = new FormData();

      Object.entries(data).forEach(([key, value]) => {
        combinedFormData.append(key, value);
      });
      Object.entries(formData).forEach(([key, value]) => {
        combinedFormData.append(key, value);
      });

      const response = await axios.put(
        `${BASE_URL}/api/profile`,
        combinedFormData,
        {
          headers: {
            Authorization: token,
            "Content-Type": "multipart/form-data", // Set the content type to multipart/form-data
          },
        }
      );

      if (response.status === 200) {
        localStorage.setItem("myQualification", formData.highest_qualification);
        localStorage.setItem("myCollage", formData.college);
        localStorage.setItem("myJobTitle", formData.job_title);
        localStorage.setItem("myCompanyName", formData.company_name);
        localStorage.setItem("mySelectSalary", formData.salary);
        navigate("/uploadphotos");
        const currentDateAndTime = new Date().toLocaleDateString();
        localStorage.setItem("loginDateTime", currentDateAndTime);
        const newMessge = localStorage.getItem("returnPersonal")
        if(newMessge == "hyyy"){
          navigate("/profile")
          localStorage.removeItem("returnPersonal")
        } else {
          navigate("/uploadphotos")
        }

      }
    } catch (error) {
      console.error("Error storing or updating data:", error);
    }
  };

  const handleChange = (e) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [e.target.name]: e.target.value,
    }));
  };

  const [salaryData, setSalaryData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/api/salary`, {
          headers: {
            'Accept-Encoding': 'gzip',
          },
          responseType: "arraybuffer", // Set the response type to arraybuffer
        });

        // Decompress the gzip response
        const decodedData = pako.ungzip(new Uint8Array(response.data), { to: "string" });

        // Parse the decoded data as JSON
        const jsonData = JSON.parse(decodedData);

        setSalaryData(jsonData);
      } catch (error) {
        console.error("Error fetching salary data:", error);
      }
    };

    fetchData();
  }, []);



  return (
    <div>
      <div className="m_login__wrapepr">
        <div className="m_login m_login-padding">
          <h2 className="">Education/Career Details</h2>
          <div className="gender_state">
            <p className="mb-1" style={{ fontWeight: "500" }}>Enter Your Highest Qualification</p>
            <select
              className="gender rounded px-3 py-1"
              name="highest_qualification"
              value={formData.highest_qualification}
              onChange={handleChange}
              style={{ border: "1px solid #f97096" }}
              required
            >
              <option value="">Highest Qualification</option>
              {higherQualification &&
                higherQualification.map((item, index) => (
                  <option key={index} value={item.hq_name}>
                    {item.hq_name}
                  </option>
                ))}
            </select>
          </div>
          <div className="gender_state">
            <p className="mb-1" style={{ fontWeight: "500" }}>Enter Your College Name</p>
            <input
              placeholder="College Name"
              type="text"
              style={{ border: "1px solid #f97096", width: "100% ", color: "black" }}
              name="college"
              className=" rounded px-3 py-1"
              value={formData.college}
              onChange={handleChange}
              required
            />
          </div>
          <div className="gender_state">
            <p className="mb-1" style={{ fontWeight: "500" }}>Enter Your Job Title</p>
            <select
              className="gender rounded px-3 py-1"
              name="job_title"
              value={formData.job_title}
              onChange={handleChange}
              style={{ border: "1px solid #f97096" }}
              required
            >
              <option value="">Job Title</option>
              {jobTitle &&
                jobTitle.map((item, index) => (
                  <option key={index} value={item.jt_name}>
                    {item.jt_name}
                  </option>
                ))}
            </select>
            {/* <input
              placeholder="Job Title"
              type="text"
              className=" rounded px-3 py-1"
              name="job_title"
              style={{ border: "1px solid #f97096", width: "100%" }}
              value={formData.job_title}
              onChange={handleChange}
              required
            /> */}
          </div>
          <div className="gender_state">
            <p className="mb-1" style={{ fontWeight: "500" }}>Enter Your Company Name</p>
            <input
              placeholder="Company Name"
              type="text"
              className=" rounded px-3 py-1"
              name="company_name"
              style={{ border: "1px solid #f97096", width: "100%" }}
              value={formData.company_name}
              onChange={handleChange}
              required
            />
          </div>
          <div className="gender_state">
            <p className="mb-1" style={{ fontWeight: "500" }}>Enter Select you Salary</p>
            <select
              className="gender  rounded px-3 py-1"
              name="salary"
              style={{ border: "1px solid #f97096" }}
              value={formData.salary}
              onChange={handleChange}
              required
            >
              <option value="">Select Your Salary</option>
              {salaryRange &&
                salaryRange.map((item, index) => (
                  <option key={index} value={item.salary_value}>
                    {item.salary_value}
                  </option>
                ))}
            </select>
          </div>
          {/* <input
            placeholder="Designation"
            type="text"
            name="designation"
            style={{ border: "1px solid #f97096" }}
            value={formData.designation}
            onChange={handleChange}
            required
          /> */}

          {/* photo upload field */}
          {/* Add input field for profile photo */}
          <input
            type="file"
            name="profile_photo"
            id="profile_photo"
            style={{ border: "1px solid #f97096" }}
            onChange={handleChange}
          />
          {/* <input type="file" name="" id="" /> */}

          {/* <input type="file" name="profile_photo" id="profile_photo" /> */}

          <div id="recaptcha"></div>
          <button className="text-light" onClick={handleSubmit}>
            Next
          </button>
          <div></div>
        </div>
        <ToastContainer />
      </div>
    </div>
  );
}

export default RegisterQualification;
