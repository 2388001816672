import React from "react";
import "./WhyChooseUs.css";
import Carousel from "../Carousel/Carousel";

const WhyChooseUs = () => {
  return (
    <div className="whychooseus">
      <h2>Why Choose Us</h2>
      <p>
        Choose us for a unique matrimonial experience: free chat and voice features, plus biodata readings in your language. Connect authentically and effortlessly with your perfect match. Join today and discover the difference!
      </p>
      <Carousel />
    </div>
  );
};

export default WhyChooseUs;