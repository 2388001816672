import React from 'react';
import "./popularsearches.css"

const PopularSearches = () => {
    return (
        <>
            <div className="">
                <div className='popular-search-box'>
                    <div>
                        <img src="./popularsearches.png" alt="" className='img-fluid' />
                        <h5 className='ps-3'>Photographers in</h5>
                        <h5 className='ps-3'>Ahmedabad</h5>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PopularSearches
