import React, { useEffect, useState } from "react";
import ShortListProfile from "../../../Components/Profile2/MatchesRequestCard/MatchesRequestCard";
import "./ShortListProfile.css";
import img1 from "../../../Assets/profile2/img1.jpg";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import LanguageIcon from "@mui/icons-material/Language";
import PlaceIcon from "@mui/icons-material/Place";
import SchoolIcon from "@mui/icons-material/School";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import PersonIcon from "@mui/icons-material/Person";
import BoltIcon from "@mui/icons-material/Bolt";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import HeightIcon from "@mui/icons-material/Height";
import TempleHinduIcon from "@mui/icons-material/TempleHindu";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import ChatIcon from "@mui/icons-material/Chat";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import StarOutlinedIcon from '@mui/icons-material/StarOutlined';
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCrown } from '@fortawesome/free-solid-svg-icons';
import PhoneIcon from "@mui/icons-material/Phone";
import axios from "axios";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import UploadIcon from "@mui/icons-material/Upload";
import { BASE_URL } from "../../../BASE_URL";
import crown from "./crown.png"
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const MatchesRequests = ({ horizontal, setValue }) => {
  var declinedRequestsClasses =
    horizontal === true
      ? "profile2_declined_request declined_request_horizontal"
      : "profile2_declined_request declined_request_vertical";

  const [error, setError] = useState(null);
  const [receivedData, setReceivedData] = useState([]);

  console.log(receivedData);

  useEffect(() => {
    getAllShortListed();
  }, []);
  const [shortData, setShortData] = useState();
  const SendRequest = async (data) => {
    try {
      const response = await fetch(`${BASE_URL}/api/request`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("saptvidhiUserToken"),
        },
        body: JSON.stringify({
          receiver_id: data?.profiles?.[0]?._id,
          request_status: "pending",
          request_type: "request",
        }),
      });
      if (response.status == 200) {
        getAllShortListed();
        toast.success('Request Send Successfully!', {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 1000,
        });
      }
    } catch (err) {
      alert(err);
    }
  };

  const handleShortlistDelete = async (_id) => {
    try {
      await axios
        .delete(
          `${BASE_URL}/api/shortlist?shortlist_id=${_id}`,
          {
            headers: { Authorization: localStorage.getItem("saptvidhiUserToken") },
          }
        )
        .then((res) => {
          
          const user = receivedData.find((user) => user.profiles[0]._id === _id);
          console.log(user);

          if (res.data.code === 200) {
            getAllShortListed();
            toast.success(`${user.profiles[0].user_name} Unshortlisted Successfully!`, {
              position: toast.POSITION.BOTTOM_RIGHT,
              autoClose: 1000,
            });
          }
        })
        .catch((err) => alert(err));
    } catch (err) {
      alert(err);
    }
  };
  const getAllShortListed = async () => {
    try {
      await axios
        .get(`${BASE_URL}/api/shortlist`, {
          headers: { Authorization: localStorage.getItem("saptvidhiUserToken") },
        })
        .then((res) => {
          setReceivedData(res.data.data);
        })
        .catch((err) => alert(err));
    } catch (err) {
      alert(err);
    }
  };

  const handleShortlist = async (data) => {
    try {
      const response = await fetch(`${BASE_URL}/api/shortlist`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("saptvidhiUserToken"),
        },
        body: JSON.stringify({
          shortlist_id: data._id,
        }),
      });

      if (response.status == 200) {
        getAllShortListed();

        toast.success('Shortlist added Successfully!', {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 1000,
        });

      }
    } catch (err) {
      alert(err);
    }
  };
  const navigate = useNavigate();

  const member_type = localStorage.getItem("memberType");

  const handlePaidMember = () => {
    if (member_type === "paid") {
    } else (
      navigate("/selectplan")
    )
  }


  return (
    <div className="profile_declined_requests_wrapper">
      <div className="profile_declined_requests_wrapper_top">
        <h3 className="profile_requests_title">
          Short Listed <span>({receivedData.length})</span>
        </h3>
      </div>
      {receivedData?.length > 0 ? (
        <div className={declinedRequestsClasses} id="card">
          {receivedData.map((data, index) => (
            <div
              key={index}
              className="received_request_card mb-sm-0 mb-3"
              style={{ cursor: "pointer" }}
            >
              <div
                className="received_request_card_left" style={{ position: "relative" }}
                onClick={() => {
                  navigate(`/userprofile/${data?.profiles?.[0]?._id}`, {
                    state: { status: "sort", id: data?._id },
                  });
                }}
              >
                {data?.profiles?.[0]?.member_type === "paid" && (
                  <img
                    src={crown}
                    className="premium-user-crown"
                    alt=""
                  />
                )}
                <div className="left_intro_group">
                  <img src={data && data?.profiles?.[0]?.profile_photo} alt="" />
                  <div className="received_request_card_name">
                    <PersonIcon sx={{ height: "15px" }} />
                    {data && data?.profiles?.[0]?.user_name}
                  </div>
                </div>
              </div>
              <div className="received_request_card_right">
                <div
                  className="card_right_top"
                  onClick={() => {
                    navigate(`/userprofile/${data?.profiles?.[0]?._id}`, {
                      state: { status: "sort", id: data?._id },
                    });
                  }}
                >
                  <p>
                    <TrendingUpIcon sx={{ height: "15px" }} />
                    {data && data?.profiles?.[0]?.age}, {data && data?.profiles?.[0]?.height}
                  </p>
                  <p>
                    <LanguageIcon sx={{ height: "15px" }} />
                    {data && data?.profiles?.[0]?.mother_tongue}
                  </p>
                  <p>
                    <PlaceIcon sx={{ height: "15px" }} />
                    {data && data?.profiles?.[0]?.home_town}, {data && data?.profiles?.[0]?.state}
                  </p>
                  <p>
                    <TempleHinduIcon sx={{ height: "15px" }} />
                    {data && data?.profiles?.[0]?.religion} , {data && data?.profiles?.[0]?.community}
                  </p>
                  <p>
                    <WorkOutlineIcon sx={{ height: "15px" }} />
                    {data && data?.profiles?.[0]?.highest_qualification}
                  </p>
                </div>
                <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
                  {data.Requests.length > 0 ? (
                    <div style={{ marginLeft: "15px", textAlign: "center" }}>
                      <PhoneIcon
                        sx={{ height: "25px", cursor: "pointer" }}
                        onClick={handlePaidMember}
                      />
                      <p style={{ fontWeight: "400", fontSize: "10px" }}>Contact</p>
                    </div>
                  ) : (

                    <div style={{ textAlign: "center" }}>
                      <FavoriteBorderIcon
                        sx={{ height: "25px", cursor: "pointer" }}
                        onClick={() => SendRequest(data)}
                      />
                      <p style={{ fontWeight: "400", fontSize: "10px" }}>
                        Interested
                      </p>
                    </div>
                  )}

                  <div className="ms-sm-3 ms-0" style={{ textAlign: "center" }}>
                    <RemoveCircleOutlineIcon
                      sx={{ height: "25px", cursor: "pointer" }}
                      onClick={() => handleShortlistDelete(data?.profiles?.[0]?._id)}
                    />
                    <p
                      style={{ fontWeight: "400", fontSize: "10px" }}
                      onClick={() => handleShortlistDelete(data?._id)}
                    >
                      Unshortlist
                    </p>
                  </div>

                  {member_type === "free" && (
                    <div className="ms-sm-3 ms-0" style={{ textAlign: "center" }}>
                      <FontAwesomeIcon
                        icon={faCrown}
                        style={{ fontSize: "20px", cursor: "pointer" }}
                        onClick={() => {
                          navigate(`/selectplan`);
                        }}
                      />
                      <p
                        style={{ fontWeight: "400", fontSize: "10px", cursor: "pointer" }}
                        onClick={() => {
                          navigate(`/selectplan`);
                        }}
                      >
                        Upgrade
                      </p>
                    </div>
                  )}


                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="nothing-see">
          <p>You Didn't Sort Any User</p>
        </div>
      )}
      <ToastContainer />
    </div>
  );
};

export default MatchesRequests;
