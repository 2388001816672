import React from 'react';
import "./banquethallcard.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot, faCircle } from '@fortawesome/free-solid-svg-icons';

const BanquetHallCard = () => {
    return (
        <>
            <div className='banquet-card-border'>
                <div className='d-flex gap-3'>
                    <div>
                        <img src="./banquethall1.png" alt="" />
                    </div>
                    <div>
                        <h4>The Beginning</h4>
                        <h6>Banquet halls, Lawns / Farm House</h6>
                        <p><FontAwesomeIcon icon={faLocationDot} /> Vashi, Navi Mumbai</p>
                        <div className='banquet-hall-amineties'>
                            <span>100-2000 Pax</span>
                            <span>100-2000 Pax</span>
                            <span>100-2000 Pax</span>
                        </div>
                        <div className='banquet-price'>
                            <div className='banquet-price-first'>
                                <FontAwesomeIcon icon={faCircle} className='banquet-red-icon' />
                                <h5>₹2,000</h5>
                            </div>
                            <div className='banquet-price-second'>
                                <FontAwesomeIcon icon={faCircle} className='banquet-green-icon' />
                                <h5>₹2,000</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BanquetHallCard
