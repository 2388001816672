import React, { useState } from 'react';
import "./inquiries.css"
import CatalogCard from '../CatalogCrad/CatalogCrad';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import InquiryCard from '../InquiryCard/InquiryCard';

const VendorInquiries = () => {

    const array = [
        { name: "yuvraj" },
        { name: "yuvraj" },
        { name: "yuvraj" },
        { name: "yuvraj" },
        { name: "yuvraj" },
    ]

    const [showDropdown, setShowDropdown] = useState(false);

    const handleFilterClick = () => {
        setShowDropdown(!showDropdown);
    }

    return (
        <>
            <div className="container">
                <div>
                    <div className='vendor-packages-border'>
                        <div>
                            <div className='vendor-inquiries-title'>
                                <h5>Inquiries</h5>
                                <button onClick={handleFilterClick}><FontAwesomeIcon icon={faFilter} className='filter-icon-inquiry' /></button>
                                {showDropdown && (
                                    <div className="dropdown-inquiry">
                                        <button>All</button>
                                        <button>Pending</button>
                                        <button>Accepted</button>
                                        {/* Add more options as needed */}
                                    </div>
                                )}
                            </div>
                            <hr />
                        </div>
                        <div className='inquiriescard-grid'>
                            {array && array.map((e) => (
                                <InquiryCard onGet={e} />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default VendorInquiries
