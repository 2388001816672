import React, { useState } from 'react';
import "./wedding-cards.css"

const WeddingCards = () => {

    const data = [
        "why",
        "why",
        "why",
        "why",
        "why",
        "why",
        "why",
        "why"
    ]

    const [menu, setMenu] = useState("wedding")

    return (
        <>
            <div className='container'>
                <div className='mt-5'>
                    <div className='mb-5'>
                        <h3 className='fw-semibold mb-1'>Wedding Cards</h3>
                        <p>Showing <span style={{ color: "gray", opacity: "0.95", fontWeight: "600" }}> 23,148 results </span> as per your search criteria </p>
                    </div>
                    <div className='wedding-variety-menus mx-3 mb-4'>
                        <h5 onClick={() => setMenu("wedding")} className={menu === "wedding" ? "wedding-variety-menus-active" : ""}>Wedding Cards</h5>
                        <h5 className={menu === "video-cards" ? "wedding-variety-menus-active" : ""} onClick={() => setMenu("video-cards")}>Video Cards</h5>
                        <h5 className={menu === "save-date" ? "wedding-variety-menus-active" : ""} onClick={() => setMenu("save-date")}>Save The Date Cards</h5>
                    </div>

                    <div className='wedding-card-grid mb-5'>
                        {data.map((e) => (
                            <>

                                <div className='bridal-card'>
                                    <div>
                                        <img src="./wedding-card.png" alt="" className='img-fluid' />
                                    </div>
                                    <div className='wedding-card-title'>
                                        <h5>Bhasin Brothers</h5>
                                    </div>
                                </div>
                            </>
                        ))}
                    </div>
                </div>
            </div>
        </>
    )
}

export default WeddingCards
