import React, { useEffect, useRef, useState } from 'react';
import "./weddingpackages.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { faCalendar, faStar } from '@fortawesome/free-solid-svg-icons';

const WeddingPackages = () => {

    const bgRef = useRef(null);

    useEffect(() => {
        if (bgRef.current) {
            const height = bgRef.current.offsetHeight;
            bgRef.current.style.height = height + 'px';
        }
    }, []);

    const array = [
        "abc",
        "abc",
        "abc",
        "abc",
        "abc",
        "abc",
        "abc",
        "abc",
    ]

    const array1 = [
        "abc",
        "abc",
        "abc",
        "abc",
    ]

    const [activeTab, setActiveTab] = useState(0);

    const tabClicked = (index) => {
        setActiveTab(index);
    };

    const [activeTab1, setActiveTab1] = useState(0);

    const tabClicked1 = (index) => {
        setActiveTab1(index);
    };


    return (
        <div className='mb-5'>
            <div className="wedding-packages-bg mb-5" ref={bgRef}>
                <div className='wedding-packages-header'>
                    <h2>Wedsta: Family Makeup service by WedMeGood</h2>
                    <div className='wedding-package-eader-icon mt-4'>
                        <div className='d-flex gap-3 align-items-center'>
                            <img src="./wedding-package-i1.png" alt="" />
                            <p>Trained <br /> Artists</p>
                        </div>
                        <div className='d-flex gap-3 align-items-center'>
                            <img src="./wedding-package-i2.png" alt="" />
                            <p>Premium <br /> Products</p>
                        </div>
                        <div className='d-flex gap-3 align-items-center'>
                            <img src="./wedding-package-i3.png" alt="" />
                            <p>At Home <br /> Service</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className='container'>
                <div className='wedding-select-package-header'>
                    <h3 className='mb-0'>Select Package</h3>
                    <p>Discount available for bookings of 2 and more. No coupon required.</p>
                </div>
                <div className='wedding-package-grid'>
                    <div className='wedding-package-1'>
                        <div className='wedding-package-1-header'>
                            <p>Recommended For friends of bride</p>
                        </div>
                        <div className='wedding-package-1-price'>
                            <h5 className='mb-0'>₹4,500</h5>
                            <p>/person</p>
                        </div>
                        <div className='mb-3'>
                            <h5>Services :</h5>
                            <ul className="wedding-package-1-services-list">
                                <li><span className="yes-mark"><FontAwesomeIcon icon={faCircleCheck} /></span> MakeUp</li>
                                <li><span className="no-mark"><FontAwesomeIcon icon={faCircleXmark} /></span>Hair</li>
                                <li><span className="yes-mark"><FontAwesomeIcon icon={faCircleCheck} /></span> Droping</li>
                                <li><span className="no-mark"><FontAwesomeIcon icon={faCircleXmark} /></span>Lashes</li>
                            </ul>
                        </div>
                        <div className='mb-3'>
                            <h5 className='mb-1'>Artist Experience :</h5>
                            <p>2 yrs+</p>
                        </div>
                        <div className='mb-3'>
                            <h5 className='mb-1'>Products :</h5>
                            <p>Mac, Kryolan and similar brands</p>
                        </div>
                        <div className='mb-3'>
                            <h5 className='mb-1'>Gallery :</h5>
                            <div className='wedding-packgae-product-gallary'>
                                {array.map((e) => {
                                    return (
                                        <img src="./wedding-package-product-gallary.png" alt="Wedding Image" className="img-fluid" />
                                    )
                                })}
                            </div>
                        </div>
                        <div className='wedding-package-add-cart-btn'>
                            <button>Add to Cart</button>
                        </div>
                    </div>
                    <div className='wedding-package-1 wedding-package-2'>
                        <div className='wedding-package-1-header wedding-package-2-header'>
                            <p>Recommended For friends of bride</p>
                        </div>
                        <div className='wedding-package-1-price wedding-package-2-price'>
                            <h5 className='mb-0'>₹4,500</h5>
                            <p>/person</p>
                        </div>
                        <div className='mb-3'>
                            <h5>Services :</h5>
                            <ul className="wedding-package-1-services-list">
                                <li><span className="yes-mark"><FontAwesomeIcon icon={faCircleCheck} /></span> MakeUp</li>
                                <li><span className="no-mark"><FontAwesomeIcon icon={faCircleXmark} /></span>Hair</li>
                                <li><span className="yes-mark"><FontAwesomeIcon icon={faCircleCheck} /></span> Droping</li>
                                <li><span className="no-mark"><FontAwesomeIcon icon={faCircleXmark} /></span>Lashes</li>
                            </ul>
                        </div>
                        <div className='mb-3'>
                            <h5 className='mb-1'>Artist Experience :</h5>
                            <p>2 yrs+</p>
                        </div>
                        <div className='mb-3'>
                            <h5 className='mb-1'>Products :</h5>
                            <p>Mac, Kryolan and similar brands</p>
                        </div>
                        <div className='mb-3'>
                            <h5 className='mb-1'>Gallery :</h5>
                            <div className='wedding-packgae-product-gallary'>
                                {array.map((e) => {
                                    return (
                                        <img src="./wedding-package-product-gallary.png" alt="Wedding Image" className="img-fluid" />
                                    )
                                })}
                            </div>
                        </div>
                        <div className='wedding-package-add-cart-btn wedding-package-add-cart-btn-1'>
                            <button>Add to Cart</button>
                        </div>
                    </div>
                    <div className='wedding-package-1'>
                        <div className='wedding-package-1-header'>
                            <p>Recommended For friends of bride</p>
                        </div>
                        <div className='wedding-package-1-price'>
                            <h5 className='mb-0'>₹4,500</h5>
                            <p>/person</p>
                        </div>
                        <div className='mb-3'>
                            <h5>Services :</h5>
                            <ul className="wedding-package-1-services-list">
                                <li><span className="yes-mark"><FontAwesomeIcon icon={faCircleCheck} /></span> MakeUp</li>
                                <li><span className="no-mark"><FontAwesomeIcon icon={faCircleXmark} /></span>Hair</li>
                                <li><span className="yes-mark"><FontAwesomeIcon icon={faCircleCheck} /></span> Droping</li>
                                <li><span className="no-mark"><FontAwesomeIcon icon={faCircleXmark} /></span>Lashes</li>
                            </ul>
                        </div>
                        <div className='mb-3'>
                            <h5 className='mb-1'>Artist Experience :</h5>
                            <p>2 yrs+</p>
                        </div>
                        <div className='mb-3'>
                            <h5 className='mb-1'>Products :</h5>
                            <p>Mac, Kryolan and similar brands</p>
                        </div>
                        <div className='mb-3'>
                            <h5 className='mb-1'>Gallery :</h5>
                            <div className='wedding-packgae-product-gallary'>
                                {array.map((e) => {
                                    return (
                                        <img src="./wedding-package-product-gallary.png" alt="Wedding Image" className="img-fluid" />
                                    )
                                })}
                            </div>
                        </div>
                        <div className='wedding-package-add-cart-btn'>
                            <button>Add to Cart</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="packages-latest-work-bg">
                <div className="container">
                    <div className="packages-latest-work-box">
                        <h3>Our latest work</h3>
                        <div className="packages-latest-work-box-tabs-container mb-4">
                            <div className="tabs">
                                <div
                                    className={`packages-latest-work-box-tab ${activeTab === 0 ? 'packages-latest-work-box-tab-active' : ''}`}
                                    onClick={() => tabClicked(0)}
                                >
                                    All Albums (124)
                                </div>
                                <div
                                    className={`packages-latest-work-box-tab ${activeTab === 1 ? 'packages-latest-work-box-tab-active' : ''}`}
                                    onClick={() => tabClicked(1)}
                                >
                                    Silver (70)
                                </div>
                                <div
                                    className={`packages-latest-work-box-tab ${activeTab === 2 ? 'packages-latest-work-box-tab-active' : ''}`}
                                    onClick={() => tabClicked(2)}
                                >
                                    Gold (12)
                                </div>
                                <div
                                    className={`packages-latest-work-box-tab ${activeTab === 3 ? 'packages-latest-work-box-tab-active' : ''}`}
                                    onClick={() => tabClicked(3)}
                                >
                                    Diamond (24)
                                </div>
                            </div>
                        </div>
                        {/* Content based on active tab */}
                        {activeTab === 0 &&

                            <div>
                                <div className="packages-latest-work-box-first-tab-grid">
                                    {array.map((e) => (
                                        <div>
                                            <img src="./packages-latest-work-photo.png" alt="" />
                                            <h3 className='mb-0 mt-2 ms-2'>Lucknow ChicSplash</h3>
                                            <p className='mb-0 ms-2'>Silver package | Shot in Lucknow</p>
                                            <span className='ms-2'><FontAwesomeIcon icon={faCalendar} className='me-1' /> 02/02/2024</span>
                                        </div>
                                    ))}
                                </div>
                                <div className='packages-latest-work-box-first-tab-btn'>
                                    <button>View All</button>
                                </div>
                            </div>
                        }
                        {activeTab === 1 && <div className="">Content for Silver Albums</div>}
                        {activeTab === 2 && <div className="">Content for Gold Albums</div>}
                        {activeTab === 3 && <div className="">Content for Diamond Albums</div>}
                    </div>
                </div>
            </div>

            <div className="container my-5">
                <div className='wedding-let-the-speak-heading'>
                    <h3 className='mb-0'>Let them speak for us!</h3>
                    <p>Some impressions from our customers</p>
                </div>
                <div className="packages-latest-work-box-tabs mt-4 mb-5">
                    <div
                        className={`packages-latest-work-box-tab ${activeTab1 === 0 ? 'packages-latest-work-box-tab-active' : ''}`}
                        onClick={() => tabClicked1(0)}
                    >
                        All Albums
                    </div>
                    <div
                        className={`packages-latest-work-box-tab ${activeTab1 === 1 ? 'packages-latest-work-box-tab-active' : ''}`}
                        onClick={() => tabClicked1(1)}
                    >
                        Silver
                    </div>
                    <div
                        className={`packages-latest-work-box-tab ${activeTab1 === 2 ? 'packages-latest-work-box-tab-active' : ''}`}
                        onClick={() => tabClicked1(2)}
                    >
                        Gold
                    </div>
                    <div
                        className={`packages-latest-work-box-tab ${activeTab1 === 3 ? 'packages-latest-work-box-tab-active' : ''}`}
                        onClick={() => tabClicked1(3)}
                    >
                        Diamond
                    </div>
                    <div className='packages-latest-work-box-tabs-view-all'>
                        <p className='mb-0'>Via All</p>
                    </div>
                </div>
                {activeTab1 === 0 &&

                    <div>
                        <div className="packages-latest-work-box-first-speak-grid">
                            {array1.map((e) => (
                                <div className='packages-let-them-speak-boxes'>
                                    <div>
                                        <img src="./let-them-speak.png" alt="" />
                                    </div>
                                    <div className='d-flex flex-column justify-content-center me-3'>
                                        <div className='d-flex justify-content-between align-items-center mb-3'>
                                            <h4 className='mb-0'>Deepak keshri</h4>
                                            <h6 className='mb-0'><FontAwesomeIcon icon={faStar} style={{ color: "#FFA500" }} /> 4.9</h6>
                                        </div>
                                        <div className='d-flex justify-content-between align-items-center mb-2'>
                                            <p className='mb-0'>Reviewed Wedsta Diamond Package</p>
                                            <p className='mb-0'>May 8, 2020</p>
                                        </div>
                                        <p className='mb-0'>It was a great experience guys ..everyone praised your work..your planning skills and good coordination was the only reason behind this successful event in Marriot ..a big thanks from keshri family 😊</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                }
                {activeTab1 === 1 && <div className="">Content for Silver Albums</div>}
                {activeTab1 === 2 && <div className="">Content for Gold Albums</div>}
                {activeTab1 === 3 && <div className="">Content for Diamond Albums</div>}
            </div>

        </div>
    );
}

export default WeddingPackages;
