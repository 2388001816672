// import React from "react";
// import "./NewMatchesCard.css";
// import PlaceIcon from "@mui/icons-material/Place";
// import img1 from "../../Assets/profile/img1.png";

// const NewMatchesCard = () => {
//   return (
// <div className="newmatchescard">
//   <div className="newmatchescard_location">
//     <PlaceIcon />
//     <h4>Ahmedabad</h4>
//   </div>
//   <img src={img1} alt="" />
//   <div className="newmatchescard_intro">
//     <p>
//       Jaymin k, <span className="newmatchescard_height">5’5”</span>
//     </p>
//     <p>
//       30 year, <span className="newmatchescard_language">Gujarati</span>
//     </p>
//     <p>Assistant professor</p>
//   </div>
//   <button className="newmatchescard_connect_button">Connect now</button>
// </div>
//   );
// };

// export default NewMatchesCard;



import React, { useEffect } from "react";
import "./NewMatchesCard.css";
import PlaceIcon from "@mui/icons-material/Place";
import img1 from "../../Assets/profile/img1.png";
import { useState } from "react";
import axios from "axios";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../../BASE_URL";

const NewMatchesCard = ({ data }) => {
  const navigate = useNavigate();
  const [isRequestSent, setIsRequestSent] = useState(false);
  // sent request ids
  const [ids, setIds] = useState([]);
  
  const [userids, setUserIds] = useState([]);

  const [error, setError] = useState();
  const [matchesData, setMatchesData] = useState([]);
  const [matchedArray, setMatchedArray] = useState([]);

  useEffect(() => {
    FetchCardData();
    FetchDataSentRequest();
  }, []);

  const FetchCardData = async () => {
    try {
      const cardData = await axios.get(
        `${BASE_URL}/api/profile/recent_visitor`,
        {
          headers: {
            Authorization: localStorage.getItem("saptvidhiUserToken"),
          },
        }
      );


      const userData = cardData.data.data.new_matches;
      setMatchesData(userData);

      const user_ids = matchesData.map((item) => item._id);
      setUserIds([...user_ids]);

      Similiarity();
    } catch (error) {
    }
  };

  const Similiarity = () => {
    const matched = ids.map((item, index) => {
      return item === userids[index] ? "Match" : "Mismatch";
      setMatchedArray(matched);
    });
  };

  const FetchDataSentRequest = async () => {
    try {
      const requests = await fetch(`${BASE_URL}/api/request/pending`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("saptvidhiUserToken"), // Include the token in the Authorization header
        },
      });

      if (!requests.ok) {
        throw new Error("Request failed");
      }

      const responseData = await requests.json();
      const extractedIds = responseData.data[0].sent.map((item) => item._id);
      setIds([...extractedIds]);

      const result = ids.find(data._id);
    } catch (error) {
      setError(error.message);
    }
  };

  const SendRequest = async () => {
    const response = await fetch(`${BASE_URL}/api/request`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("saptvidhiUserToken"),
      },
      body: JSON.stringify({
        receiver_id: data._id,
        request_status: "pending",
        request_type: "request",
      }),
    });


    if (response.status == 200) {
      setIsRequestSent(true);

      toast.success('Request Sent Successfully', {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      });
      const connectBtn = document.getElementById("connect_btn");
      if (ids.includes(data._id)) {
        connectBtn.innerHTML = "Sent";
      }
    } else {
    }
  };

  const redirectUser = () => {
    const user_id = data._id;
    navigate(`/userprofile/${user_id}`);
  };

  return (
    <div className="newmatchescard">
      <div className="newmatchescard_location align-items-center">
        <PlaceIcon />
        <h4 className="mb-0">{data.home_town}</h4>
      </div>
      <img
        src={data.profile_photo}
        style={{ cursor: "pointer" }}
        alt=""
        onClick={() =>
          navigate(`/userprofile/${data._id}`, { state: { status: "pending" } })
        }
      />
      <div
        className="newmatchescard_intro"
        style={{ cursor: "pointer" }}
        onClick={() =>
          navigate(`/userprofile/${data._id}`, { state: { status: "pending" } })
        }
      >
        <p>
          {data.user_name}{" "}
          <span className="newmatchescard_height">{data.height}</span>
        </p>
        <p>
          {data.age} years,{" "}
          <span className="newmatchescard_language">{data.mother_tongue}</span>
        </p>
        <p>{data.job_title}</p>
      </div>

      <button className="newmatchescard_connect_button" onClick={SendRequest}>
        Connect now
        <ToastContainer />
      </button>
    </div>
  );
};

export default NewMatchesCard;
