import React from 'react';
import "./bridal.css"

const Lehenga = () => {

  const data = [
    "why",
    "why",
    "why",
    "why",
    "why",
    "why",
    "why",
    "why"
  ]

  return (
    <>
      <div className='container'>
        <div className='mt-5'>
          <div className='mb-5'>
            <h3 className='fw-semibold mb-1'>Bridal Lehenga</h3>
            <p>Showing <span style={{ color: "gray", opacity: "0.95", fontWeight: "600" }}> 23,148 results </span> as per your search criteria </p>
          </div>
          <div className='bridal-grid mb-5'>
            {data.map((e) => (
              <>

                <div className='bridal-card'>
                  <div>
                    <img src="./bridal-product.png" alt="" className='img-fluid' />
                  </div>
                  <div className='bridal-card-content'>
                    <p>Zarzodi Lehenga</p>
                    <h5>Bhasin Brothers</h5>
                    <span>Lucknow</span>
                    <h6>₹38,000</h6>
                  </div>
                </div>
              </>
            ))}
          </div>
        </div>
      </div>
    </>
  )
}

export default Lehenga
