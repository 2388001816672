import React, { useEffect, useState } from 'react';
import "./planform.css"
import { BASE_URL } from '../../BASE_URL';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Transaction } from 'firebase/firestore';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Plaform = () => {

    const url = useParams();

    const location = useLocation();

    const [planDetail, setPlanDetail] = useState("")

    useEffect(() => {
        setPlanDetail(location.state?.planDetail)
    }, [location.state]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(
                    `${BASE_URL}/api/membership_plan`,
                    {
                        method: "GET",
                        headers: {
                            "Content-type": "application/json",
                            Authorization: localStorage.getItem("saptvidhiUserToken"),
                        },
                    }
                );
                // if (!response.ok) {
                //     throw new error("Request Failed");
                // }

                const responseData = await response.json();

                const selectedPlan = responseData.data.find((e) => e._id === (url?.packageid || location?.state))

                setPlanDetail(selectedPlan);
            } catch (error) {
            }
        };
        fetchData();
    }, [location.state]);

    const [details, setDetails] = useState("")
    const [paymentDetails, setPaymentDetails] = useState("")
    console.log(paymentDetails)



    useEffect(() => {

        const userDetails = async () => {
            const token = localStorage.getItem("saptvidhiUserToken");
            const res = await fetch(
                `${BASE_URL}/api/payment/payment_status?merchantTransactionId=${url?.url}`,
                {
                    method: "GET",
                    headers: {
                        Authorization: token,
                        "Content-Type": "application/json",
                    },
                }
            );
            const data = await res.json();
            setPaymentDetails(data.data)
            if (data.data) {
                try {
                    const token = localStorage.getItem("saptvidhiUserToken")
                    const res = await fetch(`${BASE_URL}/api/booking/insert`, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: token,
                        },
                        body: JSON.stringify({
                            membership_plan_id: planDetail._id,
                            transaction_id: data?.data?.transactionId,
                            status: data?.data?.state,
                            amount: data?.data?.amount / 100,
                            payment: data?.data?.paymentInstrument?.type,
                        }),
                    });

                    if (res.status == 200) {
                        navigate("/profile")
                    }

                } catch (error) {

                }
            }
        };

        if (url?.url !== undefined) {
            userDetails();
        }
    }, [url?.url]);

    useEffect(() => {

        const userDetails = async () => {
            const token = localStorage.getItem("saptvidhiUserToken");
            const res = await fetch(
                `${BASE_URL}/api/profile/checkProfile`,
                {
                    method: "POST",
                    headers: {
                        Authorization: token,
                        "Content-Type": "application/json",
                    },
                }
            );
            const data = await res.json();
            setDetails(data?.data?.user?.[0]);
        };


        userDetails();
    }, []);

    const navigate = useNavigate();

    const handleNavigate = async () => {

        const _id = localStorage.getItem("userId");
        const number = localStorage.getItem("contact_no")?.slice(3, 13);
        const token = localStorage.getItem("saptvidhiUserToken");
        const res = await fetch(`${BASE_URL}/api/payment/pay?MERCHANT_USER_ID=${_id}&amount=${planDetail?.plan_selling_price}&mobileNumber=${number}&packageid=${planDetail?._id}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: token,
            },
        });

        const data = await res.json();

        setPaymentDetails(data?.data)

        window.location.href = data?.data?.url
    }


    return (
        <>
            <div className="container">
                <div className="payment-container">
                    <div className="payment-box">
                        <div>
                            <div className="row payment-detail-data mb-4">
                                <div className="col-12">
                                    {/* <button >profile</button> */}
                                </div>
                                <div className="col-12">
                                    <h4 className='mb-3'>Package Details</h4>
                                </div>
                                <div className="col-md-6 col-12">
                                    <div className='payment-data'>
                                        <span>Plan Name</span>
                                        <p>{planDetail?.plan_name}</p>
                                    </div>
                                </div>
                                <div className="col-md-6 col-12">
                                    <div className='payment-data'>
                                        <span>Plan Days</span>
                                        <p>{planDetail?.plan_days}</p>

                                    </div>
                                </div>
                                <div className="col-md-6 col-12">
                                    <div className='payment-data'>
                                        <span>Price</span>
                                        <p>{planDetail?.plan_selling_price} <del>{planDetail?.plan_original_price}</del></p>
                                    </div>
                                </div>
                                <div className="col-md-6 col-12">
                                    <div className='payment-data'>
                                        <span>Contact Limit</span>
                                        <p>{planDetail?.contact_limit}</p>
                                    </div>
                                </div>
                                <div className="col-md-6 col-12">
                                    <div className='payment-data'>
                                        <span>Features</span>
                                        <ul className='ps-0'>
                                            {planDetail?.membership_feature_name && planDetail?.membership_feature_name.map((e) => (
                                                <li>{e?.feature_name}</li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            {url.url && (

                                <div className="row payment-detail-data mb-4">
                                    <div className="col-12">
                                        <h4 className='mb-3'>Payment Details</h4>
                                    </div>
                                    <div className="col-md-6 col-12">
                                        <div className='payment-data'>
                                            <span>Amount</span>
                                            <p>{paymentDetails?.amount / 100}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12">
                                        <div className='payment-data'>
                                            <span>Payment Type</span>
                                            <p>{paymentDetails?.paymentInstrument?.type}</p>

                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12">
                                        <div className='payment-data'>
                                            <span>Transaction Id</span>
                                            <p>{paymentDetails?.transactionId}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12">
                                        <div className='payment-data'>
                                            <span>Status</span>
                                            <p>{paymentDetails?.state}</p>
                                        </div>
                                    </div>
                                </div>
                            )}

                            <div className="row payment-detail-data">
                                <div className="col-12">
                                    <h4 className='mb-3'>My Information</h4>
                                </div>
                                <div className="col-md-6 col-12">
                                    <div className='payment-data'>
                                        <span>FULL NAME</span>
                                        <p>{details?.user_name}</p>
                                    </div>
                                </div>
                                <div className="col-md-6 col-12">
                                    <div className='payment-data'>
                                        <span>MOBILE NUMBER</span>
                                        <p>{details?.contact_no}</p>

                                    </div>
                                </div>
                                <div className="col-md-6 col-12">
                                    <div className='payment-data'>
                                        <span>EMAIL</span>
                                        <p>{details?.email}</p>
                                    </div>
                                </div>
                                <div className="col-md-6 col-12">
                                    <div className='payment-data'>
                                        <span>CITY</span>
                                        <p>{details?.home_town}</p>
                                    </div>
                                </div>
                                <div className="col-md-6 col-12">
                                    <div className='payment-data'>
                                        <span>STATE</span>
                                        <p>{details?.state}</p>
                                    </div>
                                </div>
                                {!url.url && (
                                    <div className="col-12">
                                        <div className='d-flex justify-content-center'>
                                            <button onClick={handleNavigate} >Make Payment</button>
                                        </div>
                                    </div>
                                )}
                            </div>

                            <ToastContainer />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Plaform
