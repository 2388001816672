import React, { useState } from "react";
// import grp from "../../Assets/Download/grp.png";
import grp from "../../Assets/Download/grp1.png";
import android from "../../Assets/Download/android.png";
import ios from "../../Assets/Download/apple.png";
import phone2 from "../../Assets/Download/download-phone.png";
// import phone2 from "../../Assets/Download/phone.png";
import "./Download.css";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const Download = () => {

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
    // Automatically close modal after 10 seconds
    setTimeout(() => {
      setShow(false);
    }, 8000); // 10000 milliseconds = 10 seconds
  };

  const handleNavigate = () => {
    const url = 'https://play.google.com/store/apps/details?id=com.saptavidhi&hl=en_US'; // Replace with your actual Google Play Store URL
    window.open(url, '_blank'); // Opens the URL in a new tab
  }

  return (
    <div className="download">
      <div className="download__information">
        <h3>In search of life partner?</h3>
        <h2>
          Download <span>The</span>
          <br />
          Meet Up App
        </h2>

        <div className="download__buttons">
          <div className="download__button" onClick={handleNavigate}>
            <img src={android} className="download__logo" alt="" />
            <div className="download__button__content">
              <span>GET IT ON</span>
              <p>Google Play Store</p>
            </div>
          </div>
          <div className="download__button" onClick={handleShow}>
            <img src={ios} className="download__logo" alt="" />
            <div className="download__button__content">
              <span>GET IT ON</span>
              <p>App Store</p>
            </div>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose} centered style={{ padding: "0px" }}>

        <div className="app-store-modal">
          <h3 className="text-center">Coming Soon....</h3>
          <p className="d-flex justify-content-center py-1"><img src={ios} className="download__logo" alt="" /></p>
          <p className="text-center">Our iOS app is currently in development. Stay tuned for updates on its release. Meanwhile, you can download our Android app from the Play Store to start your matrimonial journey.</p>
        </div>
      </Modal>
      <div className="download__images">
        <img src={grp} className="grp" alt="" />
        <img src={phone2} className="phone1 ms-4 me-5" alt="" />
      </div>
    </div>
  );
};

export default Download;
