import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import "./changepassword.css";
import { BASE_URL } from '../../BASE_URL';

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ChangePassword = () => {
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [message, setMessage] = useState('');

    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);

    const handleChangePassword = async () => {
        if (!newPassword.trim() && !confirmNewPassword.trim() && !oldPassword.trim()) {
            setMessage('Please Fill All Fields');
            toast.error('Please Fill All Fields', {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 1000,
            });
            return;
        }
        if (!oldPassword.trim()) {
            setMessage('Please Enter Old Password');
            toast.error('Please Enter Old Password', {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 1000,
            });
            return;
        }
        if (!newPassword.trim()) {
            setMessage('Please Enter New Password');
            toast.error('Please Enter New Password', {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 1000,
            });
            return;
        }
        if (!confirmNewPassword.trim()) {
            setMessage('Please Enter Confirm Password');
            toast.error('Please Enter Confirm Password', {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 1000,
            });
            return;
        }
        if (newPassword.trim() !== confirmNewPassword.trim()) {
            setMessage('New passwords do not match');
            toast.error('New passwords do not match', {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 1000,
            });
            return;
        }

        const token = localStorage.getItem("saptvidhiUserToken");


        try {
            const response = await fetch(`${BASE_URL}/api/profile/changepassword`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: token,
                },
                body: JSON.stringify({
                    old_password: oldPassword,
                    new_password: newPassword,
                }),
            });

            const data = await response.json();
            if (response.ok) {
                setMessage('')
                setConfirmNewPassword('')
                setNewPassword('')
                setOldPassword('')
                toast.success('Password Changed Successfully', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 1000,
                });
            } else {
                setMessage(data.message || 'Error changing password');
                toast.error(data.message, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 1000,
                });
            }
        } catch (error) {
            console.log(error)
            setMessage('Error changing password');
            toast.error('Error changing password', {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 1000,
            });
        }
    };

    return (
        <div className="change-password-container">
            <div className="change-password-box">
                <h2>Change Password</h2>
                <div className="change-password-input-container">
                    <input
                        type={showOldPassword ? 'text' : 'password'}
                        placeholder="Enter old password"
                        value={oldPassword}
                        onChange={(e) => setOldPassword(e.target.value)}
                    />
                    <FontAwesomeIcon icon={showOldPassword ? faEyeSlash : faEye} onClick={() => setShowOldPassword(!showOldPassword)} className='change-password-icon' />
                </div>
                <div className="change-password-input-container">
                    <input
                        type={showNewPassword ? 'text' : 'password'}
                        placeholder="Enter new password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                    />
                    <FontAwesomeIcon icon={showNewPassword ? faEyeSlash : faEye} onClick={() => setShowNewPassword(!showNewPassword)} className='change-password-icon' />
                </div>
                <div className="change-password-input-container mb-5">
                    <input
                        type={showConfirmNewPassword ? 'text' : 'password'}
                        placeholder="Confirm new password"
                        value={confirmNewPassword}
                        onChange={(e) => setConfirmNewPassword(e.target.value)}
                    />
                    <FontAwesomeIcon icon={showConfirmNewPassword ? faEyeSlash : faEye} onClick={() => setShowConfirmNewPassword(!showConfirmNewPassword)} className='change-password-icon' />
                </div>
                <button onClick={handleChangePassword}>Change Password</button>
                {message && <p>{message}</p>}
            </div>
            <ToastContainer />
        </div>
    );
};

export default ChangePassword;
