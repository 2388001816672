import React from "react";
import LatestProfileCard from "../../../Components/LatestProfileCard/LatestProfileCard";
import img1 from "../../../Assets/latestProfiles/img1.png";
import img2 from "../../../Assets/latestProfiles/img2.png";
import img3 from "../../../Assets/latestProfiles/img3.png";
import img4 from "../../../Assets/latestProfiles/img4.png";
import img5 from "../../../Assets/latestProfiles/img5.jpg";
import img6 from "../../../Assets/latestProfiles/img6.jpg";
import img7 from "../../../Assets/latestProfiles/img7.jpg";
import img8 from "../../../Assets/latestProfiles/img8.jpg";
import "./LatestProfiles.css";
import { useNavigate } from "react-router-dom";

const LatestProfiles = () => {
  const token = localStorage.getItem('token')
  const navigate = useNavigate();

  const handleCheck = () => {
    if (token === null) {
      navigate('/login')
    } else {
      navigate('/profile')
    }
  }

  return (
    <div className="latestprofiles">
      <div className="profile__intro">
        <h2>Latest Added Profiles</h2>
        <p>
          Stay updated with our matrimonial app's latest additions, offering fresh opportunities for meaningful connections, ensuring your search for love remains dynamic and vibrant.{" "}
        </p>
        <button onClick={handleCheck}>Get Started</button>
      </div>
      <div className="profile__imgs">
        <div className="column__1">
          <LatestProfileCard
            image={img5}
            city='Ahmedabad'
            state="Gujarat"
            country='India'
            height='5"12"'
            name="Rajshree Sagar, 30 Years"
            message="“What I am is good enough for you”"
          />
          <LatestProfileCard
            image={img6}
            city='Kota'
            state="Rajasthan"
            country='India'
            height='5"12"'
            name="Priyal maheshwari, 24 Years"
            message="“What I am is good enough for you”"
          />
        </div>
        <div className="column__2">
          <LatestProfileCard
            image={img7}
            city='Ahmedabad'
            state="Gujarat"
            country='India'
            height='5"12"'
            name="Priya parmar, 25 Years"
            message="“What I am is good enough for you”"
          />
          <LatestProfileCard
            image={img8}
            city='Ahmedabad'
            state="Gujarat"
            country='India'
            height='5"12"'
            name="Deepraj Zala, 38 Years"
            message="“What I am is good enough for you”"
          />
        </div>
      </div>
    </div>
  );
};

export default LatestProfiles;
