import React from 'react'
import Download from '../../Components/Download/Download'
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./vendorotp.css";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BASE_URL } from "../../BASE_URL";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import OTPInput from 'react-otp-input';

const Vendorotp = () => {
    const [dell, setDell] = useState({
        contact_no: "",
        password: "",
    });
    const navigate = useNavigate();
    
    const [otp, setOtp] = useState('');

    const txt = (e) => {
        const { name, value } = e.target;
        setDell({ ...dell, [name]: value });
    };

    const handleSubmit = async () => {
        handleLogin();
    };

    const handleLogin = async () => {
        // const res = await fetch(`${BASE_URL}/api/profile/login`, {
        //     method: "POST",
        //     headers: {
        //         "Content-Type": "application/json",
        //     },
        //     body: JSON.stringify({
        //         contact_no: formattedContactNo,
        //         password,
        //     }),
        // });

        // const abc = await res.json();

        // if (abc.code === 200) {
        //     navigate("/profile");
        //     localStorage.setItem("user", JSON.stringify(abc.data));
        //     localStorage.setItem("token", abc.data.token);
        //     localStorage.setItem("preference-detail", "preference");
        //     localStorage.setItem("family-detail", "family");
        //     localStorage.setItem("fillup", "fill the data");
        //     localStorage.setItem("contact_no", formattedContactNo);
        //     localStorage.setItem("firebase_id", abc.data.Fields.firebase_id);
        //     const currentDateAndTime = new Date().toLocaleDateString();
        //     localStorage.setItem("loginDateTime", currentDateAndTime);
        // } else {
        //     toast.error('Please Enter Registered Mobile Number and Password!', {
        //         position: toast.POSITION.BOTTOM_RIGHT,
        //         autoClose: 1000,
        //     });
        // }
    };


    useEffect(() => {
        const storedToken = localStorage.getItem("saptvidhiUserToken");
        if (storedToken) {
            navigate("/profile");
        }
    }, [navigate]);
    return (
        <>
            <div className='d-flex justify-content-center'>
                <div className="vendor_forgot__wrapepr">
                    <div className='back-left-arrow' style={{ cursor: "pointer" }} onClick={() => navigate(-1)}>
                        <FontAwesomeIcon icon={faArrowLeft} />
                    </div>
                    <h2 className='text-center fw-semibold'>Enter OTP</h2>
                    <div className="vendor_login py-5">
                        <div className='d-flex justify-content-center mb-4'>
                            <OTPInput
                                value={otp}
                                onChange={setOtp}
                                numInputs={6}
                                renderSeparator={<span>-</span>}
                                renderInput={(props) => <input {...props} />}
                            />
                        </div>
                        <div className='otp-h5 mb-4'>
                            <h5>Didn't Receive Confirmation Code? <span>Resend OTP</span></h5>
                        </div>
                        <div id="recaptcha"></div>
                        <ToastContainer />
                        <div className='d-flex vendor-login-btn flex-column'>
                            <button onClick={() => navigate('/vendor-set-new-password')}>Verify</button>
                        </div>
                    </div>
                </div>
            </div>
            <Download />
        </>
    )
}

export default Vendorotp;
