import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import JobPositionCard from "../../Components/JobPositionCard/JobPositionCard";
import "./Career.css";
import { BASE_URL } from "../../BASE_URL";
import { useEffect } from "react";

const Career = () => {
  const [value, setValue] = useState("1");
  const [currentTabData, setCurrentTabData] = useState(null);

  const handleChange = async (event, newValue) => {
    setValue(newValue);
    await Call2(newValue);
  };



  const [title, seTitle] = useState("");

  const Call = async () => {
    const res = await fetch(`${BASE_URL}/api/career_category`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      },
    });
    const data = await res.json();
    seTitle(data.data);
    if (data.data.length > 0) {
      const firstTabId = data.data[0]._id;
      setValue(firstTabId);
      await Call2(firstTabId); // Call the function for the first tab data
    }
  };

  const Call2 = async (id) => {
    const res = await fetch(`${BASE_URL}/api/career?career_category_id=${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "aplication/json"
      },
    });
    const data = await res.json();
    setCurrentTabData(data.data);
  };

  useEffect(() => {
    Call();
    Call2();
  }, [])

  return (
    <div className="container__wrapper mb-sm-0 mb-5">
      <span>Home &gt; Career</span>
      <h2 className="open__positions">Career</h2>
      <Box sx={{ width: "100%", padding: 0 }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider", gap: 6 }}>
            <TabList
              onChange={handleChange}
              aria-label="lab API tabs example"
              TabIndicatorProps={{
                sx: { backgroundColor: "rgba(207, 22, 111, 0.5)" },
              }}
              sx={{
                "& button": { fontFamily: "poppins" },
                "& button:active": { color: "rgba(207, 22, 111, 0.5)" },
                "& button.Mui-selected": { color: "rgba(207, 22, 111, 0.5)" },
              }}
            >
              {title && title.map((item) => (
                <Tab label={item.career_cat_value} value={item._id} key={item._id} />
              ))}
            </TabList>
          </Box>
          {title && title.map((item) => (
            <TabPanel value={item._id} key={item._id} sx={{ padding: 0 }}>

              {currentTabData && currentTabData.map((job) => (
                <>
                  {job?.[0]?.career_title}
                  <Accordion
                    sx={{
                      padding: 0,
                      marginTop: "10px",
                      boxShadow: "none",
                    }}
                  >
                    <AccordionSummary
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      sx={{
                        padding: "0px 0px",
                        height: "100%",
                        width: "100%",
                        div: {
                          margin: 0,
                        },
                      }}
                    >
                      <div className="jobpositioncard">
                        <div>
                          <h3 className="job__title">{job.career_title}</h3>
                          {job.career_tag && (
                            <span className="job__details">
                              {job.career_tag.map((e, index) => (
                                <React.Fragment key={index}>
                                  {index > 0 && ' || '}
                                  {e}
                                </React.Fragment>
                              ))}
                            </span>
                          )}
                        </div>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails>
                      <AccordionDetails>
                        <div className="job_accordion_details" dangerouslySetInnerHTML={{ __html: job.career_desc }} /> 
                      </AccordionDetails>
                    </AccordionDetails>
                  </Accordion>
                </>
              ))}

            </TabPanel>
          ))}

        </TabContext>
      </Box>
    </div>
  );
};

export default Career;
